import React, { useState, MouseEvent } from 'react';
import axios from 'axios';
// import Popup from '../../Components/Popup/Popup';
import { Button } from '@progress/kendo-react-buttons';
import { clientConfig } from '../../api/clientConfig';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Popup from '../../Components/Popup/Popup';


const ForgotPassword = ({ onClose }: { onClose: Function }) => {

  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 입력 아이디 관리
  const [uLoginId, setUserId] = useState('');
  // console.log('uLoginId');
  // console.log(uLoginId);
  // 팝업창 닫기
  const closePopup = () => {
    setShowPopup(false);
  };
  // 정보창 닫기
  const closeModal = () => {
    // onclose();
  };
  // 현재파일명(확장자포함) 가져오기
  // const getFileName = new URL(import.meta.url).pathname.split('/').pop();

  // 이메일발송
  // 아이디 확인 및 이메일 발송
  const handleForgotPassword = async (event: MouseEvent) => {
    // const handleForgotPassword = async () => {
    event.preventDefault();

    try {
      // 아이디로 이메일 조회 ${clientConfig.URL}
      const responseEmail = await axios.post(`${clientConfig.URL}/getEmail`, {
        uLoginId: uLoginId,
      });
      // alert();
      const { success, uEmail } = responseEmail.data;
      console.log('responseEmail.data');
      console.log(responseEmail.data);
      if (success) {
        // 이메일 전송
        const response = await axios.post(`${clientConfig.URL}/sendemail`, {
          toEmail: uEmail, // 조회된 이메일로 전송
          subject: '임시 비밀번호 발급',
          text: '임시 비밀번호를 발급합니다.',
          uLoginId: uLoginId
        });

        if (response.data.success) {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일이 성공적으로 보내졌습니다.`);
        } else {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일 보내기 실패: ${response.data.message}`);
        }
      } else {
        // 팝업 메시지 설정
        setPopupMessage("이메일를 확인해주세요.");
      }

      // 팝업 열기
      setShowPopup(true);
    } catch (error) {
      // sendLog('Error handleForgotPassword /getEmail', `Error details : ${error}`, uLoginId, getFileName);
      console.error('이메일 조회 오류:', error);
      // 팝업 메시지 설정
      setPopupMessage("서버 오류 관리자에게 문의해 주세요.");
      // 팝업 열기
      setShowPopup(true);
    }
  };


  return (
    <div>
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}

      <Header />

      {/* <!-- Start Breadcrumb     ============================================= --> */}
      {/* <div className="breadcrumb-area shadow dark bg-fixed text-center text-light" style="background-image: url(assets/img/2440x1578.png);"> */}
      <div className="breadcrumb-area shadow dark bg-fixed text-center text-light" style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>Forgot PassWord</h1>
              <ul className="breadcrumb">
                <li><a href="/"><i className="fas fa-home"></i> Home</a></li>

                <li className="active">login</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Breadcrumb --> */}

      {/* Start Login Area */}
      <div className="login-area default-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              {/* <form action="#" id="login-form" className="white-popup-block"> */}
              <form id="login-form" className="white-popup-block" style={{ display: 'flex' }}>
                <div className="col-md-4 login-social">
                  <h4>비밀번호 찾기</h4>
                </div>
                {/* ForgotPassword-area 시작 */}
                <div className="col-md-8 login-custom">
                  <h4>아이디을 입력해주세요<br/>임시 비밀번호는 가입시 이메일로 전송됩니다.</h4>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group">
                        <input className="form-control"
                          placeholder="Email*"
                          type="email"
                          value={uLoginId}
                          onChange={(e) => setUserId(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group">
                        {/* <input className="form-control"
                          placeholder="Password*"
                          type="password"
                          value={auLoginPw}
                          onChange={(e) => setAuLoginPw(e.target.value)}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {/* <div className="row">
                      <label htmlFor="login-remember"><input type="checkbox" id="login-remember" />로그인 정보 기억하기</label>
                      <a onClick={openModal} title="Lost Password" href="/forget_password" className="lost-pass-link">비밀번호 찾기</a>
                    </div> */}
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <button type="submit" onClick={handleForgotPassword}>
                        임시 비밀번호 발급
                      </button>
                    </div>
                  </div>
                </div>
                {/* ForgotPassword-area 끝 */}
                {/* {showPopup && <Popup message={popupMessage} onClose={closePopup} />} 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
              </form>
            </div>
          </div>
          <div className="dive_to_sexyroom">
            <div className="dive_to_sexyroom_01">
              {/* <a href="admin_license.html" style="font-size: 14px; line-height: 10px; color: #adadad; padding-left: 20px; letter-spacing: -1px;">파트너사 관리자 페이지</a> */}
              <a href="admin_license" style={{ fontSize: '14px', lineHeight: '10px', color: '#adadad', paddingLeft: '20px', letterSpacing: '-1px' }}>파트너사 관리자 페이지</a>

              {/* <a href="user_license.html" style="font-size: 14px; line-height: 10px; color: #adadad; padding-left: 20px; letter-spacing: -1px;">파트너사 일반유저</a> */}
              <a href="user_license" style={{ fontSize: '14px', lineHeight: '10px', color: '#adadad', paddingLeft: '20px', letterSpacing: '-1px' }}>파트너사 일반유저</a>

            </div>


            <div className="dive_to_sexyroom_01">
              (정식서비스 시 안보이는 텍스트입니다)
            </div>

          </div>
        </div>
      </div>
      {/* End Login Area */}

      <Footer />

    </div>
  );
}

export default ForgotPassword;
