import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { getUserInfoFromStorage } from '../../utils/userInfoModule'
import { clientConfig } from '../../api/clientConfig'
import axios from 'axios'
import { Grid, GridColumn as Column, GridRowClickEvent, GridToolbar, GridEvent, GridHeaderCell, GridHeaderCellProps, GridFilterChangeEvent, GridNoRecords, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Link } from 'react-router-dom'
import { Master_Product_Management, Master_Product_Management_Add, Master_Product_Management_Resist } from './Master_Product_Management'

import MasterContractHeader from '../../Components/MasterContractHeader'
import { Master_Contract_Management } from './Master_Contract_Management'
import MasterPartnerHeader from '../../Components/MasterPartnerHeader'
import { Master_Partner_Management } from './Master_Partner_Management'
import MasterSolutionHeader from '../../Components/MasterSolutionHeader'
import { Master_Solution_Admin } from './Master_Solution_Admin'
import { SortDescriptor, orderBy } from '@progress/kendo-data-query'
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs'

// Qna 게시판 데이터 타입 정의
interface qnaDataType {
  sbqId: string,
  cId: string,
  sbqQLoginId: string,
  sbqQName: string,
  sbqQEmail: string,
  sbqQPhone: string,
  sbqQContent: string,
  sbqQDate: string,
  sbqQFile: string;
  cCode: string,
  cName: string,
  cNumber: string,
  cPhone: string,
  cPost: string,
  cAddress: string,
  cFilePath: string,
  cFileName: string,
  cDescription: string,
  sbqrId: string,
  sbqALoginId: string,
  sbqAName: string,
  sbqAEmail: string,
  sbqAPhone: string,
  sbqAContent: string,
  sbqADate: string,
  sbqACheckDate: string,
  sbqAFile: string,
  YesORNo: string,
}

// 해당 질문의 대한 답변 데이터 타입 정의
interface qnaAnswerData {
  sbqrId: string,
  sbqId: string,
  sbqALoginId: string,
  sbqAName: string,
  sbqAEmail: string,
  sbqAPhone: string,
  sbqAContent: string,
  sbqADate: string,
  sbqAFile: string,
  sbqACheckDate: string,
}

// 솔루션 데이터 타입 선언
interface MasterProductInfoProps {
  pdId: string,
  pdCode: string,
  pdName: string,
  pdVersion: string,
  pdFile: string,
  pdDescription: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdImage: string,
  pdUse: string,
  pdUpateDate: string,
  dsId: string,
  pdHtml: string,
  pdManual: string,
  pfId: string,
  pdVersionYear: string,
  dmId: string,
  dsName: string,
  dsValue: string,
  dsUse: string,
  dsDescription: string,
  dmCode: string,
  dmName: string,
  dmUse: string,
  dmDescription: string,
}


const Master_License_Management = () => {
  // 세선스토리지에서 정보가져오기
  const userInfoObject = getUserInfoFromStorage();
  // 현재 페이지 관리
  const [currentPage, setCurrentPage] = useState(1);
  // 메뉴 호출
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  const [openMenu3, setOpenMenu3] = useState(false);
  // console.log('현재페이지 : ', currentPage);
  // console.log('메뉴열기 : ', openMenu);
  // 수정할 제품 상태 관리
  const [selectedOnProductEdit, setSelectedOnProductEdit] = useState<Array<MasterProductInfoProps>>([]);


  // 페이지 호출
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  // 메뉴 호출
  // const handleOpenMenu = (isOpen: boolean) => {
  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };
  const [companyInfo, setCompanyInfo] = useState<any>(null);
  // console.log('companyInfo : ', companyInfo);

  // 메뉴 호출
  // const handleOpenMenu = (isOpen: boolean) => {
  const handleOpenMenu2 = () => {
    setOpenMenu2(!openMenu2);
  };
  const [companyInfo2, setCompanyInfo2] = useState<any>(null);
  // console.log('companyInfo22 : ', companyInfo2);

  // 메뉴 호출
  // const handleOpenMenu = (isOpen: boolean) => {
  const handleOpenMenu3 = () => {
    setOpenMenu3(!openMenu3);
  };
  const [companyInfo3, setCompanyInfo3] = useState<any>(null);
  // console.log('companyInfo33 : ', companyInfo3);


  // 자식 컴포넌트에서 보낸 정보를 받는 콜백 함수
  const handleReceiveCompanyInfo = (info: any) => {
    setCompanyInfo(info);
  };

  // 자식 컴포넌트에서 보낸 정보를 받는 콜백 함수
  const handleReceiveCompanyInfo2 = (info2: any) => {
    setCompanyInfo2(info2);
  };
  // 자식 컴포넌트에서 보낸 정보를 받는 콜백 함수
  const handleReceiveCompanyInfo3 = (info3: any) => {
    setCompanyInfo3(info3);
  };


  // 수정할 제품 정보 넘기기
  const onProductEdit = (product: MasterProductInfoProps) => {
    setSelectedOnProductEdit([product])
  }


  // DB Qna 정보 관리
  const [qnaData, setQnaDataData] = useState<Array<qnaDataType>>([]);
  // 선택된 질문의 데이터 저장
  const [selectedRow, setSelectedRow] = useState<qnaDataType | null>(null);
  // 선택된 질문의 대답 데이터
  const [answerContent, setAnswerContent] = useState<Array<qnaAnswerData>>([]);
  // 답변별 페이지 구분
  const [qnaIndex, setQnaIndex] = useState<number>(0);

  // DB 게시판 조회
  useEffect(() => {

    const fetchData = async () => {
      try {
        // 서버로 cId와 uId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getQna`, {
        });
        // console.log(response);
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setQnaDataData(response.data);
          // console.log(response.data);
        }
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    fetchData();
  }, []);


  // 해당 문의에 대한 답변하기
  const handleQnaAnswer = async (event: GridRowClickEvent) => {
    // 문의 내용 출력전 상태 초기화
    setAnswerContent([])
    console.log('QnaPage qnaDataqnaData : ', qnaData);
    console.log('QnaPage event.dataItem : ', event.dataItem);
    console.log('QnaPage event.dataItem.sbqId : ', event.dataItem.sbqId);
    // 선택 정보 입력
    setSelectedRow(event.dataItem)
    // 선택한 질문에 대한 답변 연결
    try {
      console.log('/시작 완료');
      // 질문번호로 값가져오기
      const response = await axios.post(`${clientConfig.URL}/getQnaAnswer`, {
        sbqId: event.dataItem.sbqId
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setAnswerContent(response.data);
        console.log('/getQnaAnswer 완료 : ', response.data);
      }

    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
    // 답변페이지로 이동
    handlePageChange(2)
  }



  // 문의 목록 제목
  const QnaTitle = () => {
    return <h1>INNOSERVICE GENERAL MANAGEMENT</h1>;
  };
  // 제품 관리 제목
  const ProductManagementTitle = () => {
    return <h1>INNOSERVICE PRODUCT MANAGEMENT</h1>;
  };
  // 계약 관리 제목
  const ConTractManagementTitle = () => {
    return <h1>INNOSERVICE CONTRACT MANAGEMENT</h1>;
  };
  // 솔루션 관리자 제목
  const SolutionManagementTitle = () => {
    return <h1>INNOSERVICE SOLUTION MANAGEMENT</h1>;
  };




  // 문의 목록 페이지
  const QnaPage = () => {
    // 정렬 상태 관리
    const [sort, setSort] = React.useState(Array<SortDescriptor>);
    // 검색어 상태관리
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    // 검색어 입력란 변경이벤트 처리
    const handleSearchChange = (event: InputChangeEvent) => {
      setSearchKeyword(event.target.value as string);
    };

    //  검색어에 일치하는 데이터 필터링
    const filteredQnAData = qnaData.filter((row) => {
      // const filteredData = masterContractData.filter((row) => {
      return Object.values(row).some((value) => {
        return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
      });
    });


    return <div className="single-item item">
      <div className="info">
        <div className="contact-form">
          <h2>문의 확인 및 답변</h2>
          <div className="superadmin_license_list">
            <div className="superadmin_license_list_contents">
              {/* <Grid data={qnaData} onRowClick={(event) => console.log(event)} */}
              <Grid
                className='master_contact'
                // data={qnaData}
                data={orderBy(filteredQnAData, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  setSort(e.sort);
                }}
                onRowClick={(event) => handleQnaAnswer(event)}
                resizable={true}
                style={{ height: '40vh' }}
              >
                <GridToolbar>
                  검색 :
                  <Input
                    placeholder='검색할 내용을 입력해 주세요.'
                    style={{ width: '50%' }}
                    onChange={handleSearchChange}
                  />
                </GridToolbar>
                {/* 데이터없을때 나오는 문구 */}
                <GridNoRecords>
                  <div style={{ height: '100vh' }}>
                    등록된 문의내역이 없습니다.
                  </div>
                </GridNoRecords>
                {/* <Grid data={data} > */}
                {/* <  
                      sbqId: Number,
                      cId: Number,
                      sbqQLoginId: string,
                      sbqQName: string,
                      sbqQEmail: string,
                      sbqQPhone: string,
                      sbqQContent: string,
                      sbqQDate: string,
                      sbqQFile: string;
                      cName: string, /> */}
                {/* <Column field="sbqQContent" title='문의 글' /> .substring(6); */}
                <div hidden>
                  <Column field="id" cell={(props) => {
                    const index = props.dataIndex
                    return (
                      <td style={{ textAlign: 'center' }} >
                        {index}
                      </td>
                    )
                  }} />
                </div>
                <Column field="sbqQContent" title='문의 글'
                  cell={(props) => {
                    const sbqQContent = props.dataItem.sbqQContent
                    return (
                      <td style={{ textAlign: 'center' }}>
                        {/* {sbqQContent?.substring(0, 7)} */}
                        {sbqQContent}
                      </td>
                    )
                  }}
                />
                <Column field="cName" title='소속사' />
                <Column field="sbqQName" title='문의자'
                  cell={(props) => {
                    const sbqQName = props.dataItem.sbqQName
                    return (
                      <td style={{ textAlign: 'center' }}>
                        {sbqQName}
                      </td>
                    )
                  }}
                />
                <Column title='문의날짜' field='sbqQDate'
                  cell={(props) => {
                    const sbqQDate = props.dataItem.sbqQDate
                    return (
                      <td style={{ textAlign: 'center' }}>
                        {/* 날짜 형식 변환 */}
                        {/* {new Date(sbqQDate).toLocaleDateString()} */}
                        {sbqQDate?.substring(0, 10)}
                      </td>
                    )
                  }}
                />
                {/* <Column field='YesORNo' title='답변 여부' /> */}
                <Column field='YesORNo' title='답변 여부'
                  cell={(props) => {
                    const YesORNo = props.dataItem.YesORNo
                    return (
                      <td style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: YesORNo === '답변 완료' ? '#7d92a7' : '#c3c3c3',
                      }}>
                        {YesORNo}
                      </td>
                    )
                  }} />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  // 문의 답변 내용
  const Qna_AnswerPage = () => {
    // 선택한 질문에 대한 답변 정보받기
    // console.log(answerContent[0]?.sbqAContent);

    // 답변이 있나요?
    // console.log('답변이 있나요? : ' + answerContent);
    // console.log(selectedRow?.sbqId);
    // const [seletedQnaAnswerData] = useState(answerContent)
    // 답변 내용 상태
    const [answerQna, setAnswerQna] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    // 답변 수정 버튼 클릭 시 호출되는 함수
    const handleEditAnswer = () => {
      setAnswerQna(answerContent[0]?.sbqAContent);
      setIsEditing(true);
      console.log('수정동작');
    };

    // 답변 수정후 저장 버튼 클릭 시 호출되는 함수
    const handleSaveEditedAnswer = async () => {
      setIsEditing(false);
      console.log('수정동작');
      try {
        const response = await axios.post(`${clientConfig.URL}/editQnaAnswer`, {
          // 선택한 질문의 고유값
          sbqId: selectedRow?.sbqId,
          // sbqALoginId: userInfoObject.uLoginId,
          // sbqAName: userInfoObject.uName,
          // sbqAEmail: userInfoObject.uEmail,
          // sbqAPhone: userInfoObject.uPhone,
          sbqALoginId: userInfoObject.caLoginId,
          sbqAName: userInfoObject.caName,
          sbqAEmail: userInfoObject.caEmail,
          sbqAPhone: userInfoObject.caPhone,
          // 답변 내용 추가
          sbqAContent: answerQna,
          sbqAFile: null,
        });

        // 서버 응답에 따른 처리
        console.log(response);
        if (response.data.length > 0) {
          // 처리 로직 추가
        }
      } catch (error) {
        console.error('Error editing Qna answer:', error);
      }
      // 등록한 답변 내용 보기
      reLoadQnaAnswerData();
    };


    // 등록한 답변 내용 보기
    const reLoadQnaAnswerData = async () => {
      // 응답 데이터 초기화
      setAnswerContent([])
      try {
        console.log('/시작 완료');
        // 질문번호로 값가져오기
        const response = await axios.post(`${clientConfig.URL}/getQnaAnswer`, {
          sbqId: selectedRow?.sbqId
        });
        console.log('----------------');
        console.log(selectedRow?.sbqId);
        console.log('----------------');

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setAnswerContent(response.data);
          console.log('/getQnaAnswer 완료 : ', response.data);
        }

      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    }

    // 문의 목록 정보 업데이트
    const reLoadQnaGridData = async () => {
      try {
        // 서버로 cId와 uId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getQna`, {
        });
        // console.log(response);
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setQnaDataData(response.data);
          // console.log(response.data);
        }
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    // 답변 등록 함수
    const uploadQnaAnswer = async () => {
      // console.log('uploadQnaAnswer');
      // console.log(selectedRow);
      try {
        const response = await axios.post(`${clientConfig.URL}/sendQnaAnswer`, {
          // 선택한 질문의 고유값
          sbqId: selectedRow?.sbqId,
          // sbqALoginId: userInfoObject.uLoginId,
          // sbqAName: userInfoObject.uName,
          // sbqAEmail: userInfoObject.uEmail,
          // sbqAPhone: userInfoObject.uPhone,
          sbqALoginId: userInfoObject.caLoginId,
          sbqAName: userInfoObject.caName,
          sbqAEmail: userInfoObject.caEmail,
          sbqAPhone: userInfoObject.caPhone,
          // 답변 내용 추가
          sbqAContent: answerQna,
          sbqAFile: null,
        });
        // 서버 응답에 따른 처리
        console.log(response);
        if (response.data.length > 0) {
          // 성공적으로 등록되었을 때의 처리
          console.log('답변이 성공적으로 등록되었습니다.');
        }
      } catch (error) {
        console.error('답변 등록 중 오류 발생:', error);
      }
      // 등록한 답변 내용 보기
      reLoadQnaAnswerData();
      // 문의 목록 정보 업데이트
      reLoadQnaGridData();
    };


    // 등록한 답변 삭제
    const deleteQnaAnswer = async () => {
      try {
        console.log('답변 삭제sbqrId : ' + answerContent[0]?.sbqrId);
        // 질문번호로 값가져오기
        const response = await axios.post(`${clientConfig.URL}/deleteQnaAnswer`, {
          sbqrId: answerContent[0]?.sbqrId
        });
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          console.log('/삭제 완료 : ', response.data);
        }
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
      // 등록한 답변 내용 보기
      reLoadQnaAnswerData();
      // 문의 목록 정보 업데이트
      reLoadQnaGridData();
    }

    // 이전 문의 버튼
    const handlePrevQna = async () => {
      console.log('이전');
      if (qnaIndex > 0) {
        setQnaIndex(qnaIndex - 1);
        setSelectedRow(qnaData[qnaIndex - 1]);
        // 응답 데이터 초기화
        setAnswerContent([])
        try {
          console.log('/시작 완료');
          // 질문번호로 값가져오기
          const response = await axios.post(`${clientConfig.URL}/getQnaAnswer`, {
            sbqId: qnaData[qnaIndex - 1]?.sbqId
          });
          if (response.data.length > 0) {
            // 서버에서 받아온 데이터를 상태로 설정
            setAnswerContent(response.data);
            console.log('/getQnaAnswer 완료 : ', response.data);
          }

        } catch (error) {
          console.error('Error user_license fetchData:', error);
        }
      }
    };

    // 다음 문의 버튼
    const handleNextQna = async () => {
      console.log('다음');
      if (qnaIndex < qnaData.length - 1) {
        setQnaIndex(qnaIndex + 1);
        setSelectedRow(qnaData[qnaIndex + 1]);
        // 응답 데이터 초기화
        setAnswerContent([])
        try {
          console.log('/시작 완료');
          // 질문번호로 값가져오기
          const response = await axios.post(`${clientConfig.URL}/getQnaAnswer`, {
            sbqId: qnaData[qnaIndex + 1]?.sbqId
          });
          if (response.data.length > 0) {
            // 서버에서 받아온 데이터를 상태로 설정
            setAnswerContent(response.data);
            console.log('/getQnaAnswer 완료 : ', response.data);
          }

        } catch (error) {
          console.error('Error user_license fetchData:', error);
        }
      }
    };


    return <div className="single-item item">
      <div className="info">
        <div className="info_pack">
          <div className="contact-form">
            <div className="superadmin_license_list">
              <h2>문의 확인 및 답변</h2>
              <div className="superadmin_list_bt_pack">
                <div className="superadmin_list_bt_margin">
                  <a
                    // href="#"
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                    onClick={handlePrevQna}
                  >
                    이전 문의
                  </a>
                </div>
                <div className="superadmin_list_bt_margin">
                  <a
                    // href="#"
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                    onClick={handleNextQna}
                  >
                    다음 문의
                  </a>
                </div>
                <div className="superadmin_list_bt_margin">
                  <a
                    // href="master_license_management_main.html"
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                    onClick={() => handlePageChange(1)}
                  >
                    문의 리스트
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="superadmin_license_list_contents">
            {/* <img src="assets/img/temp_contactshot_cc.jpg" /> */}
            <div className='superadmin_license_list_contents_detail_data'>

              <div className='superadmin_license_list_contents_detail_data_status_pack'>

                <div className='superadmin_license_list_contents_detail_data_status_target'>

                  <div className='superadmin_license_list_contents_detail_data_status_target_title'>
                    문의 글 : {selectedRow?.sbqQContent}
                  </div>
                </div>


                {/* 답변 여부 메시지 */}
                <div className='superadmin_license_list_contents_detail_data_status_info'>
                  {selectedRow?.YesORNo}
                </div>

              </div>

              <div className='superadmin_license_list_contents_detail_data_status_pack_se'>

                <div className='superadmin_license_list_contents_detail_data_status_target_custom'>


                  <div className='superadmin_license_list_contents_detail_data_status_target_custom_qname'>

                    <div className='superadmin_license_list_contents_detail_data_status_target_custom_qname_title'>
                      문의자
                    </div>

                    <div className='superadmin_license_list_contents_detail_data_status_target_custom_qname_cc'>
                      {selectedRow?.sbqQName}
                    </div>

                  </div>



                  <div className='superadmin_license_list_contents_detail_data_status_target_custom_cname'>

                    <div className='superadmin_license_list_contents_detail_data_status_target_custom_cname_title'>
                      소속사
                    </div>

                    <div className='superadmin_license_list_contents_detail_data_status_target_custom_cname_cc'>
                      {selectedRow?.cName}
                    </div>

                  </div>

                  <div className='superadmin_license_list_contents_detail_data_status_target_custom_date'>

                    <div className='superadmin_license_list_contents_detail_data_status_target_custom_date_title'>
                      날짜
                    </div>

                    <div className='superadmin_license_list_contents_detail_data_status_target_custom_date_cc'>
                      {selectedRow?.sbqQDate?.substring(0, 10)}
                    </div>

                  </div>

                </div>
              </div>


            </div>

            <br />

            {/* <br /> */}
            {selectedRow?.sbqQContent}


          </div>
          {selectedRow?.sbqAContent}
          <div className="meta">
            <div className="admin_answer_content_action">
              {answerContent[0]?.sbqADate && (
                <div>
                  <h3>등록된 답변</h3>
                  {/* 답변일있으면 보임 */}
                  <div style={{ textAlign: 'right' }}>답변날짜 : {answerContent[0]?.sbqADate?.substring(0, 10)}</div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group comments">
                        <div className="admin_answer_content_action_finish">
                          {isEditing ? (
                            <textarea
                              className="admin_answer_content"
                              id="comments"
                              name="comments"
                              placeholder="답변할 내용을 입력해주세요*"
                              value={answerQna}
                              onChange={(e) => setAnswerQna(e.target.value)}
                            />
                          ) : (
                            // 답변내용
                            answerContent.map((answerData, index) => (
                              <div key={index}>
                                {answerData.sbqAContent}
                              </div>
                            ))
                          )}

                          {/* {answerContent[0]?.sbqAContent} */}
                          {/* {seletedQnaAnswerData[0]?.sbqAContent} */}
                          {/* <div className="admin_answer_content_action_finish_att">
                      첨부파일 :{" "}
                      <span>
                        <a href="#" target="_blank">
                          {qnaAnswerContent[0]?.sbqAFile}
                        </a>
                      </span>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {(answerContent[0]?.sbqAContent) && ( */}
              {!(answerContent[0]?.sbqACheckDate) && (answerContent[0]?.sbqAContent) && (
                <div className="admin_answer_content_bt_align">
                  <div className="superadmin_list_bt_margin">
                    {/* 답변 수정 버튼 */}
                    <a
                      className="btn btn-theme border btn-sm"
                      data-animation="animated slideInUp"
                      onClick={isEditing ? handleSaveEditedAnswer : handleEditAnswer}
                    >
                      {isEditing ? '답변 저장' : '답변 수정'}
                    </a>
                    {/* <a
                    // href="#"
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                  >
                    답변 수정
                  </a> */}
                  </div>
                  <div className="superadmin_list_bt_margin">
                    <a
                      // href="#"
                      className="btn btn-theme border btn-sm"
                      data-animation="animated slideInUp"
                      onClick={deleteQnaAnswer}
                    >
                      답변 삭제
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* 답변이있을때 안보임 */}
          {!(answerContent[0]?.sbqAContent) && (
            // <div className="meta">
            <div className="admin_answer_content_action">
              <h3>답변 하기</h3>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group comments">
                    <textarea
                      className="admin_answer_content"
                      id="comments"
                      name="comments"
                      placeholder="답변할 내용을 입력해주세요*"
                      // defaultValue={""}
                      value={answerQna}
                      onChange={(e) => setAnswerQna(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="admin_answer_content_bt_align_att_bt">
                <div className="admin_answer_content_bt_align_att_area">
                  {/* <div className="file-upload">
                  <input
                    type="text"
                    className="upload-name"
                    defaultValue="첨부파일을 선택해주세요"
                    disabled
                  />
                  <label
                    htmlFor="ex_filename"
                    className="bulk_upload_BT"
                  >
                    파일 선택
                  </label>
                  <input
                    type="file"
                    id="ex_filename"
                    className="upload-hidden"
                  />
                </div> */}
                </div>
                <a
                  // href="#"
                  className="btn btn-theme border btn-sm btn-align-center"
                  data-animation="animated slideInUp"
                  onClick={uploadQnaAnswer}
                >
                  답변 등록
                </a>
              </div>
            </div>
            // </div>
          )}
        </div>
        {/* 페이지 네비게이션 */}
        {/* <div className="row">
        <div className="col-md-12 pagi-area">
          <nav aria-label="navigation" className="navigation_center">
            <ul className="pagination">
              <li>
                <a href="#">
                  <i className="fas fa-angle-double-left" />
                </a>
              </li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">4</a>
              </li>
              <li>
                <a href="#">5</a>
              </li>
              <li>
                <a href="#">6</a>
              </li>
              <li>
                <a href="#">7</a>
              </li>
              <li>
                <a href="#">8</a>
              </li>
              <li>
                <a href="#">9</a>
              </li>
              <li>
                <a href="#">10</a>
              </li>
              <li>
                <a href="#">
                  <i className="fas fa-angle-double-right" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div> */}

      </div>
    </div>
  }




  return (
    <div>
      {currentPage !== 6 && currentPage !== 7 && currentPage !== 8 && <Header />}
      {currentPage === 6 && <MasterContractHeader openMenu3={openMenu3} handleMenu3={handleOpenMenu3} companyInfo3={companyInfo3} />}
      {currentPage === 7 && <MasterPartnerHeader openMenu={openMenu} handleMenu={handleOpenMenu} companyInfo={companyInfo} />}
      {currentPage === 8 && <MasterSolutionHeader openMenu2={openMenu2} handleMenu2={handleOpenMenu2} companyInfo2={companyInfo2} />}
      {/* Start Breadcrumb 
    ============================================= */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-center text-light"
        style={{ backgroundImage: "url(assets/img/master_bg.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {currentPage === 1 && <QnaTitle />}
              {currentPage === 2 && <QnaTitle />}
              {currentPage === 3 && <ProductManagementTitle />}
              {currentPage === 4 && <ProductManagementTitle />}
              {currentPage === 5 && <ProductManagementTitle />}
              {currentPage === 6 && <ConTractManagementTitle />}
              {currentPage === 7 && <ConTractManagementTitle />}
              {currentPage === 8 && <SolutionManagementTitle />}
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start License Management
    ============================================= */}
      <div className="blog-area full-blog left-sidebar full-blog default-padding">
        <div className="container">
          <div className="row">
            <div className="blog-items">
              <div className="blog-content col-md-9">
                {/* Single Item */}
                {currentPage === 1 && <QnaPage />}
                {currentPage === 2 && <Qna_AnswerPage />}
                {currentPage === 3 && <Master_Product_Management handlePageChange={handlePageChange} onProductEdit={onProductEdit} />}
                {currentPage === 4 && <Master_Product_Management_Resist handlePageChange={handlePageChange} selectedOnProductEdit={selectedOnProductEdit} />}
                {currentPage === 5 && <Master_Product_Management_Add handlePageChange={handlePageChange} selectedOnProductEdit={selectedOnProductEdit} />}
                {currentPage === 6 && <Master_Contract_Management handleMenu3={handleOpenMenu3} onSendMasterContract={handleReceiveCompanyInfo3} />}
                {currentPage === 7 && <Master_Partner_Management handleMenu={handleOpenMenu} onSendCompanyInfo={handleReceiveCompanyInfo} />}
                {currentPage === 8 && <Master_Solution_Admin handleMenu2={handleOpenMenu2} onSendSolutionAdmin={handleReceiveCompanyInfo2} />}
                {/* End Single Item */}
                {/* Pagination */}
                {/* <div class="row">
                      <div class="col-md-12 pagi-area">
                          <nav aria-label="navigation">
                              <ul class="pagination">
                                  <li><a href="#"><i class="fas fa-angle-double-left"></i></a></li>
                                  <li class="active"><a href="#">1</a></li>
                                  <li><a href="#">2</a></li>
                                  <li><a href="#">3</a></li>
                                  <li><a href="#"><i class="fas fa-angle-double-right"></i></a></li>
                              </ul>
                          </nav>
                      </div>
                  </div> */}
              </div>
              {/* Start Sidebar */}
              <div className="sidebar col-md-3 sidebar_mg_top">
                <aside>
                  {/* <div className="sidebar-item search">
                    <div className="title">
                      <h4>검색</h4>
                    </div>
                    <div className="sidebar-info">
                      <form>
                        <input type="text" className="form-control" />
                        <input type="submit" defaultValue="search" />
                      </form>
                    </div>
                  </div> */}
                  <div className="sidebar-item category">
                    <div className="title">
                      <h4>이노서비스 고객 관리</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(1)}>
                            문의 확인 및 답변<span></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="sidebar-item category">
                    <div className="title">
                      <h4>계약 및 라이선스 관리</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        {/* <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(1)}>
                            문의 확인 및 답변<span></span>
                          </a>
                        </li> */}
                        <li>
                          {/* <a href="master_license_management_product_management.html"> */}
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(3)}>
                            제품 관리
                            <span />
                          </a>
                        </li>
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(7)} >
                            파트너사 관리
                            <span />
                          </a>
                        </li>
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(6)}>
                            계약 관리
                            <span />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="sidebar-item category">
                    <div className="title">
                      <h4>파트너사 관리</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(7)} >
                            파트너사 관리
                            <span />
                          </a>
                        </li>
                        <li>
                          <a style={{ cursor: 'pointer' }}>
                            파트너사 관리자 설정
                            <span />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="sidebar-item category">
                    <div className="title">
                      <h4>InnoService 솔루션 관리</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(8)}>
                            솔루션 관리자 설정
                            <span />
                          </a>
                        </li>
                        {/* <li>
                          <a style={{ cursor: 'pointer' }}>
                            메인페이지 레이아웃
                            <span />
                          </a>
                        </li>
                        <li className="innoactive_side_bar_sub_area">
                          <a style={{ cursor: 'pointer' }}>상단 메뉴<span></span></a>
                        </li>
                        <li className="innoactive_side_bar_sub_area">
                          <a style={{ cursor: 'pointer' }}>커버<span></span></a>
                        </li>
                        <li className="innoactive_side_bar_sub_area">
                          <a style={{ cursor: 'pointer' }}>소개<span></span></a>
                        </li>
                        <li className="innoactive_side_bar_sub_area">
                          <a style={{ cursor: 'pointer' }}>제품<span></span></a>
                        </li>
                        <li className="innoactive_side_bar_sub_area">
                          <a style={{ cursor: 'pointer' }}>하단 메뉴<span></span></a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
              {/* End Start Sidebar */}
            </div>
          </div>
        </div>
      </div>
      {/* End License Management */}
      <Footer />
    </div>

  )
}

export default Master_License_Management