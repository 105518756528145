import React, { useState, useEffect } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { clientConfig } from '../../api/clientConfig'
import axios from 'axios'

// 제품 데이터 타입 정의
interface Product {
  expiredate: string,
  pdId: string,
  pdCode: string,
  pdName: string;
  pdVersion: string,
  pdFile: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdDescription: string,
  pdImage: string;
  pdManual: string;
  pdUse: string;
}

const Main = () => {
  // 제품 데이터 상태 관리
  const [data, setData] = useState<Array<Product>>([]);

  // 제품 데이터 DB 조회
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getProductInfo`, {

        })

        if (response.data.length > 0) {
          setData(response.data); // 서버에서 받아온 데이터를 상태로 설정

        }

      } catch (error) {
        console.error('Error Main fetchData:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div>




      <Header />


      {/* <!-- Start Banner   ============================================= --> */}
      <div className="banner-area top-pad-60 inc-fixed-thumb">
        {/* <!-- Fixed Thumb --> */}
        {/* <div className="fixed-thumb bg-cover" style="background-image: url(assets/img/2440x1578.png);"></div> */}
        <div className="fixed-thumb bg-cover" style={{ backgroundImage: 'url(assets/img/2440x1578.png)' }}></div>
        {/* <!-- End Fixed Thumb  --> */}
        <div id="bootcarousel" className="carousel carousel-indicator-inc inc-top-heading slide carousel-fade animate_text" data-ride="carousel">

          {/* <!-- Indicators --> */}
          <ol className="carousel-indicators">
            {/* <li data-target="#bootcarousel" data-slide-to="0" className="active"></li> */}
            <li data-target="#bootcarousel" data-slide-to={0} className="active"></li>
            <li data-target="#bootcarousel" data-slide-to={1}></li>
          </ol>

          {/* <!-- Wrapper for slides --> */}
          <div className="carousel-inner carousel-zoom">
            <div className="item active">
              <div className="box-table">
                <div className="box-cell">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="content">
                          <h3 data-animation="animated slideInUp">Innovation of Creative & Convergence</h3>
                          <h1 data-animation="animated slideInDown" style={{ fontSize: '38px' }}>InnoService <span>Managment</span></h1>
                          <h1 data-animation="animated slideInDown">이노액티브 <span>라이선스 관리</span></h1>


                          {/* <!-- <a data-animation="animated slideInUp" className="btn btn-theme border btn-md" href="#">Learn more</a> --> */}
                          {sessionStorage.getItem('isMasterLoggedIn') === 'true' || sessionStorage.getItem('isLoggedIn') === 'true' ? (
                            <></>
                          ) : (
                            <a data-animation="animated slideInUp" className="btn btn-theme effect btn-md" href="login">Login</a>
                          )}
                          {/* <a data-animation="animated slideInUp" className="btn btn-theme effect btn-md" href="login">Login</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="box-table">
                <div className="box-cell">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="content">
                          <h3 data-animation="animated slideInLeft">라이선스의 유효기간 관리도 손쉽게</h3>
                          <h1 data-animation="animated slideInRight" style={{ fontSize: '42px' }}>솔루션 사용 그 다음은 <span>관리</span>입니다</h1>

                          {sessionStorage.getItem('isMasterLoggedIn') === 'true' || sessionStorage.getItem('isLoggedIn') === 'true' ? (
                            <></>
                          ) : (
                            <a data-animation="animated slideInUp" className="btn btn-theme effect btn-md" href="login">Login</a>
                          )}
                          {/* <a data-animation="animated slideInUp" className="btn btn-theme effect btn-md" href="login">Login</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Wrapper for slides --> */}

        </div>
      </div>
      {/* <!-- End Banner --> */}

      {/* <!-- Start Features Area ============================================= -->  */}
      <div className="features-area bg-gray default-padding bottom-less">
        <div className="container">
          <div className="row">

            {/* <!-- Start Our Features --> */}
            <div className="features-items">
              {/* <!-- Single Item --> */}
              <div className="col-md-3 col-sm-6 single-item">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-fit"></i>
                    <span>01</span>
                  </div>
                  <div className="info">
                    <h4>편리한 관리</h4>
                    <p>
                      사용자는 한눈에 파악 할 수 있는 솔루션과 라이선스를 손쉽 관리 할 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="col-md-3 col-sm-6 single-item">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-target"></i>
                    <span>02</span>
                  </div>
                  <div className="info">
                    <h4>정확한 현황 파악</h4>
                    <p>
                      조직에서의 사용자는 라이선스의 수량과 기간을 확인하여 유효 기간 갱신 및 상담을 통해 최적의 기대 효과를 얻을 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Single Item --> */}
              {/* <!-- End Single Item --> */}
              <div className="col-md-3 col-sm-6 single-item">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-chart"></i>
                    <span>03</span>
                  </div>
                  <div className="info">
                    <h4>컨설팅을 통한 최적화</h4>
                    <p>
                      업계 최고의 경험으로 솔루션을 도입 및 검토를 파트너의 입장에서 최적의 결과를 보여줍니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="col-md-3 col-sm-6 single-item">
                <div className="item">
                  <div className="icon">
                    <i className="flaticon-customer-service"></i>
                    <span>04</span>
                  </div>
                  <div className="info">
                    <h4>친절한 상담</h4>
                    <p>
                      솔루션에 대한 문의 사항과 편리한 상담을 통하여 만족 할 수 있는 상담을 드립니다.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Features Area --> */}

      {/* <!-- Start Blog Area ============================================= --> */}
      <div className="blog-area default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="site-heading text-center">
                <h2>InnoActive Product</h2>
                <p>
                  이노액티브는 고객의 한계를 확장하는 독창적인 솔루션을 제공합니다.<br />
                  축적된 경험과 기술로 업무 프로세스를 분석 및 보완하여 생산성을 향상시켜드립니다.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <!-- Single Item --> */}
            {/* {data.map((item: Product) => ( */}
            {data.slice(0, 3).map((item: Product) => (

              <div className="col-md-4 single-item">
                <div className="item">
                  <div className="thumb">
                    {/* <a href="#"><img src="assets/img/800x600.png" alt="Thumb" /></a> */}
                    {/* src={`${SERVER_URL}:4103/image/$` */}
                    {/* <img src={`${clientConfig.URL}/image/800x600.png`} alt="Thumb" /> */}
                    <img src={`${clientConfig.URL}/image/${item.pdImage}`} alt="Thumb" />

                    {/* <!-- <span className="post-formats"><i className="fas fa-image"></i></span> --> */}
                  </div>
                  <div className="info">
                    {/* <div className="cats">
                    <a href="#">3D CAD 모델 변환</a>
                    <a href="#">ISO 속성 정보 보완</a>
                  </div> */}
                    <h4>
                      {/* <a href="#">INNOVA ISO</a> */}
                      {/* <a>{item.pdName}</a> {item.pdUse === 'N' && <a style={{color: 'red'}}>(서비스지원중지)</a>} */}
                      <a>{item.pdName}</a>
                    </h4>
                    <p>
                      {/* 3D CAD 모델 변환(Data Convergence)기술로 ISO 속성 정보를 보완하여 ISOMETRIC 및 기타 도면들을 생성합니다 */}
                      {item.pdDescription}
                    </p>
                    {/* <div className="meta">
                      <ul>
                        <li><i className="fas fa-calendar-alt"></i> 2023년 12월 16일</li>
                      </ul>
                      <a href="#">제품보기</a>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
            {/* <!-- End Single Item --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Blog Area --> */}

      <Footer />

    </div>

  )
}

export default Main