// (솔루션관리자, 파트너사 관리자)세션스토리지에서 유저정보 가져오기

export const getUserInfoFromStorage = () => {
    // 세선스토리지에서 정보가져오기
    const storedMasterInfo = sessionStorage.getItem('MasterInfo');
    const storedUserInfo = sessionStorage.getItem('userInfo');
    // 세선스토리지에 없으면 null
    // return storedUserInfo ? JSON.parse(storedUserInfo) : null;

    
    //return storedUserInfo !== null ? JSON.parse(storedUserInfo) : JSON.parse(storedMasterInfo!);
    return storedMasterInfo !== null ? JSON.parse(storedMasterInfo!) : JSON.parse(storedUserInfo!);
  };

// 일반 유저사용
export const getNomalUserInfoFromStorage = () => {
    // 세선스토리지에서 정보가져오기
    const storedUserInfo = sessionStorage.getItem('userInfo');
    // 세선스토리지에 없으면 null
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  };