/**
 * 클라이언트 용 설정 파일입니다.
 */

interface TypeClientConfig {
  URL: string;
}

const PORT: string = '5214'

export const clientConfig: TypeClientConfig = {
  URL: `https://service.innolm.com:${PORT}`
}