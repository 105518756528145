// Login.tsx

import React, { useState, MouseEvent } from 'react';
import axios from 'axios';
import { clientConfig } from '../../api/clientConfig';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import Popup from '../../Components/Popup/Popup';
// import { useNavigate } from 'react-router-dom';

interface Master_LoginProps {
  onMaster_Login: (user: string) => void;
}


const Master_Login: React.FC<Master_LoginProps> = ({ onMaster_Login: onMaster_Login }) => {
  // 로그인 ID, PW 관리
  const [caLoginId, setCaLoginId] = useState<string>('');
  const [caLoginPw, setCaLoginPw] = useState<string>('');
  // const navigate = useNavigate();
  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState<boolean>(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState<string>('');
  // 팝업창 닫기
  const closePopup = () => {
    setShowPopup(false);
  };


  // 로그인
  const handleMaster_Login = (event: MouseEvent<HTMLButtonElement>) => {
    // 로그인 버튼의 폼 제출을 막음
    event.preventDefault();
    // axios를 사용하여 서버로 POST 요청 보내기
    axios.post(`${clientConfig.URL}/api/master_login`, {
      UserName: caLoginId,
      PassWord: caLoginPw,
    })
      .then(response => {
        // 서버로부터 받은 응답 처리
        const { success, master } = response.data;
        if (success) {
          console.log('로그인 success 받아옴!');
          // 여기에 로그인 성공 후의 동작 추가
          onMaster_Login(master);
          // 페이지 이동
          // navigate('/main')
            // window.location.href = '/master_license_management'
        } else {
          // 여기에 로그인 실패 후의 동작 추가
          console.log('로그인 실패!');
          setPopupMessage("ID 또는 PW 를 확인해주세요.")
          setShowPopup(true);
        }
      })
      .catch(error => {
        // 오류 처리
        if (error.response && error.response.status === 401) {
          // 로그인 실패(정보불일치) 시 401 상태 코드일 때
          console.log(error.response.status);
          setPopupMessage("ID 또는 PW 를 확인해주세요.")
          setShowPopup(true);
        } else {
          // 그 외의 서버 오류 처리
          console.error('에러 발생:', error);
          setPopupMessage("서버 오류")
          setShowPopup(true);
        }
      });
  };


  return (
    <div>
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}

      <Header />

      {/* <!-- Start Breadcrumb     ============================================= --> */}
      {/* <div className="breadcrumb-area shadow dark bg-fixed text-center text-light" style="background-image: url(assets/img/2440x1578.png);"> */}
      <div className="breadcrumb-area shadow dark bg-fixed text-center text-light" style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1>InnoService Master_Login</h1>
              <ul className="breadcrumb">
                <li><a href="/"><i className="fas fa-home"></i> Home</a></li>

                <li className="active">Master_Login</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Breadcrumb --> */}

      {/* Start Login Area */}
      <div className="login-area default-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              {/* <form action="#" id="login-form" className="white-popup-block"> */}
              <form id="login-form" className="white-popup-block">
                <div className="col-md-4 login-social">
                  <h4>Master_Login</h4>
                </div>
                <div className="col-md-8 login-custom">
                  <h4>이노서비스로 라이선스 관리를 편리하게 이용하세요.</h4>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group">
                        <input className="form-control"
                          placeholder="Email*"
                          type="email"
                          value={caLoginId}
                          onChange={(e) => setCaLoginId(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group">
                        <input className="form-control"
                          placeholder="Password*"
                          type="password"
                          value={caLoginPw}
                          onChange={(e) => setCaLoginPw(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <button type="submit" onClick={handleMaster_Login}>
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="dive_to_sexyroom">
            <div className="dive_to_sexyroom_01">
              {/* <a href="admin_license.html" style="font-size: 14px; line-height: 10px; color: #adadad; padding-left: 20px; letter-spacing: -1px;">파트너사 관리자 페이지</a> */}
              <a href="admin_license" style={{ fontSize: '14px', lineHeight: '10px', color: '#adadad', paddingLeft: '20px', letterSpacing: '-1px' }}>파트너사 관리자 페이지</a>

              {/* <a href="user_license.html" style="font-size: 14px; line-height: 10px; color: #adadad; padding-left: 20px; letter-spacing: -1px;">파트너사 일반유저</a> */}
              <a href="user_license" style={{ fontSize: '14px', lineHeight: '10px', color: '#adadad', paddingLeft: '20px', letterSpacing: '-1px' }}>파트너사 일반유저</a>

            </div>


            <div className="dive_to_sexyroom_01">
              (정식서비스 시 안보이는 텍스트입니다)
            </div>

          </div>
        </div>
      </div>
      {/* End Login Area */}

      <Footer />

    </div>
  );
};

export default Master_Login;
