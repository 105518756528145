import React, { useEffect, useState, useRef, ForwardedRef, forwardRef, ChangeEvent } from 'react'
import { clientConfig } from '../api/clientConfig'
import axios from 'axios';
import { getUserInfoFromStorage } from '../utils/userInfoModule';
import Popup from './Popup/Popup';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import './header.css'

interface Menu {
  mId: string;
  pdId: string;
  mName: string;
  mUrl: string;
  mType: string;
  mImageUrl: string;
  msId: string;
  msName: string;
  msUrl: string;
  msTarget: string;
}

// 유저 데이터 타입 정의
interface UserInfoData {
  uLoginId: string,
  uName: string,
  uEmail: string,
  uPhone: string,
  uAgree: string,
  uDept: string,
  uRank: string,
  uAdmin: string,
  uActive: string,
  uId: string,
  cId: string,
}


// 제품 라이선스 유형 타입 정의
interface ProductLicenseTypeProps {
  dmId: string,
  dsDescription: string,
  dsId: string,
  dsName: string,
  dsUse: string,
  dsValue: string,
}

// 제품 데이터 타입 정의
interface ProductProps {
  pdId: string,
  pdCode: string,
  pdName: string,
  pdVersion: string,
  pdFile: string,
  pdDescription: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdImage: string,
  pdUse: string,
  pdUpateDate: string,
  dsId: string,
  pdHtml: string,
  pdManual: string,
}

// 회사 정보 데이터 타입 정의
interface CompanyProps {
  cId: string,
  cCode: string,
  cName: string,
  cNumber: string,
  cPhone: string,
  cPost: string,
  cAddress: string,
  cFilePath: string,
  cFileName: string,
  cDescription: string,
}

interface MasterContractHeaderProps {
  openMenu3: boolean;
  handleMenu3: () => void;
  companyInfo3: any; // 받아올 회사 정보를 담을 props

}

// const MasterContractHeader = ({ }) => {
const MasterContractHeader: React.FC<MasterContractHeaderProps> = ({ openMenu3, handleMenu3, companyInfo3 }) => {
  // 위젯창 보여주기 상태 관리
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);
  // console.log('MasterContractHeader : ', openMenu3);
  // console.log('MasterContractHeader : ', companyInfo3);

  useEffect(() => {
    setSideMenuVisible(openMenu3);
  }, [openMenu3]);
  // 위젯창의 X버튼 눌러서 위젯창 닫기
  const closeSideMenu = () => {
    setSideMenuVisible(false);
    handleMenu3()
    console.log('toggleSideMenu 닫기 동작!');
  };
  // 세선스토리지에서 정보가져오기
  const userInfoObject = getUserInfoFromStorage();

  const [menuData, setMenuData] = useState<Array<Menu>>([]);
  // 고객센터 매뉴 상태 관리
  const [menuData2, setMenuData2] = useState<Array<Menu>>([]);
  // 검색창 보여주기 상태 관리
  const [isSearchVisible, setSearchVisible] = useState(false);
  // DB 유저정보 관리
  const [userData, setUserData] = useState<Array<UserInfoData>>([]);
  const [userData2, setUserData2] = useState<Array<UserInfoData>>([]);
  // console.log('userData : ', userData);

  // 계약일 상태 관리
  const [startContractDate, setStartContractDate] = useState<Date>(new Date());
  // console.log('계약일 : ', startContractDate);
  const [startLicenseDate, setStartLicenseDate] = useState<Date>(new Date());
  // console.log('시작일 : ', startLicenseDate);
  const [endLicense, setEndLicenseDate] = useState<Date>(new Date());
  // console.log('만료일 : ', endLicense);
  // 기간 관리
  const [period, setPeriod] = useState('');
  // console.log('기간 : ', period);
  // 매입처 관리
  const [placePurchase, setPlacePurchase] = useState('');
  // console.log('매입처 : ', placePurchase);
  // 회사명
  const [companyName, setCompanyName] = useState('');
  // console.log('선택한 고객사 : ', companyName);

  // 회사 관리자
  const [administrator, setAdministrator] = useState('');
  const [selectedUserLoginId, setSelectedUserLoginId] = useState('');
  // console.log('회사 관리자 값 : ', administrator);
  // console.log('회사 관리자 uLoginId값 : ', selectedUserLoginId);

  // 상품 유형 관리
  const [productTypeName, setProductTypeName] = useState('');
  // console.log('상품 cId 값 : ', productTypeName);
  // 라이선스 유형 관리
  const [licenseTypeName, setLicenseTypeName] = useState('');
  // console.log('라이선스유형 cId 값 : ', licenseTypeName);
  // 시리얼키 관리
  const [serialCode, setSerialCode] = useState('');
  // console.log('시리얼키 값 : ', serialCode);
  // 수량 관리
  const [licenseCount, setLicenseCount] = useState('');
  // console.log('수량 값 : ', licenseCount);
  // 단가기준 (월, 일) 관리
  const [dayMonth, setDayMonth] = useState('');
  // console.log('단가기준 월,일 값 : ', dayMonth);
  // 매입단가 관리
  const [purchasePrice, setPurchasePrice] = useState('');
  // console.log('매입단가 : ', purchasePrice);
  // 매출단가 관리
  const [salesUnitPrice, setSalesUnitPrice] = useState('');
  // console.log('매출단가 : ', salesUnitPrice);
  // console.log('----------------------------------------------------------------------------------------------------------------------------------------------');
  // console.log('----------------------------------------------------------------------------------------------------------------------------------------------');

  // #region 수정 상태 관리


  // const ctDateDateString = companyInfo3?.ctDate
  // const dateObject = new Date(ctDateDateString); // 문자열을 Date 객체로 변환
  // const ctPeriodFromDateString = companyInfo3?.ctPeriodFrom
  // const ctPeriodFromDateObject = new Date(ctPeriodFromDateString); // 문자열을 Date 객체로 변환
  // const ctPeriodToDateString = companyInfo3?.ctPeriodTo
  // const ctPeriodDateObject = new Date(ctPeriodToDateString); // 문자열을 Date 객체로 변환

  // const formattedDate = dateObject.toLocaleString('en-US', {
  //   weekday: 'short', // 요일 (짧은 형식)
  //   year: 'numeric', // 연도
  //   month: 'short', // 월 (짧은 형식)
  //   day: '2-digit', // 일 (2자리 숫자)
  //   hour: 'numeric', // 시 (숫자)
  //   minute: 'numeric', // 분 (숫자)
  //   second: 'numeric', // 초 (숫자)
  //   timeZoneName: 'short' // 시간대 이름 (짧은 형식)
  // });
  // const DateformattedDate = new Date(formattedDate)

  // 계약일 상태 관리
  const [startContractDateEdit, setStartContractDateEdit] = useState<Date>(new Date(companyInfo3?.ctDate));
  // console.log('수정_계약일_원본 : ', companyInfo3?.ctDate);
  // console.log('수정_계약일_날짜 : ', new Date(companyInfo3?.ctDate));
  // console.log('수정_계약일 : ', startContractDateEdit);

  const [startLicenseDateEdit, setStartLicenseDateEdit] = useState<Date>(new Date(companyInfo3?.ctPeriodFrom));
  // console.log('수정_시작일 : ', startLicenseDateEdit);
  const [endLicenseEdit, setEndLicenseDateEdit] = useState<Date>(new Date(companyInfo3?.ctPeriodTo));
  // console.log('수정_만료일 : ', endLicenseEdit);
  // 기간 관리
  const [periodEdit, setPeriodEdit] = useState(companyInfo3?.ctTerm);
  // console.log('수정_기간 : ', periodEdit);
  // 매입처 관리
  const [placePurchaseEdit, setPlacePurchaseEdit] = useState(companyInfo3?.ctVendor);
  // console.log('수정_매입처 : ', placePurchaseEdit);
  // 회사명
  const [companyNameEdit, setCompanyNameEdit] = useState(companyInfo3?.cId);
  // console.log('수정_선택한 고객사 : ', companyNameEdit);

  // 회사 관리자
  const [administratorEdit, setAdministratorEdit] = useState(companyInfo3?.uId);
  const [selectedUserLoginIdEdit, setSelectedUserLoginIdEdit] = useState(companyInfo3?.uLoginId);
  // console.log('수정_회사 관리자 값 : ', administratorEdit);
  // console.log('수정_회사 관리자 uLoginId값 : ', selectedUserLoginIdEdit);

  // 상품 유형 관리
  const [productTypeNameEdit, setProductTypeNameEdit] = useState(companyInfo3?.pdCode);
  // console.log('수정_상품 cId 값 : ', productTypeNameEdit);
  // 라이선스 유형 관리
  const [licenseTypeNameEdit, setLicenseTypeNameEdit] = useState(companyInfo3?.act_dsId);
  // console.log('수정_라이선스유형 cId 값 : ', licenseTypeNameEdit);
  // 시리얼키 관리
  const [serialCodeEdit, setSerialCodeEdit] = useState(companyInfo3?.ctSerialKey);
  // console.log('수정_시리얼키 값 : ', serialCodeEdit);
  // 수량 관리
  const [licenseCountEdit, setLicenseCountEdit] = useState(companyInfo3?.ctLicense);
  // console.log('수정_수량 값 : ', licenseCountEdit);
  // 단가기준 (월, 일) 관리
  const [dayMonthEdit, setDayMonthEdit] = useState(companyInfo3?.ctPriceStandard);
  // console.log('수정_단가기준 월,일 값 : ', dayMonthEdit);
  // 매입단가 관리
  const [purchasePriceEdit, setPurchasePriceEdit] = useState(companyInfo3?.ctPurchasePrice);
  // console.log('수정_매입단가 : ', purchasePriceEdit);
  // 매출단가 관리
  const [salesUnitPriceEdit, setSalesUnitPriceEdit] = useState(companyInfo3?.ctSalesPrice);
  // console.log('수정_매출단가 : ', salesUnitPriceEdit);

  //#endregion 

  // 제품 라이센스 타입 상태 관리
  const [productType, setProductType] = useState<Array<ProductLicenseTypeProps>>([])
  // 제품 정보 상태관리
  const [productData, setProductData] = useState<Array<ProductProps>>([]);
  // 회사 정보 상태 관리
  const [companyInfo, setCompanyInfo] = useState<Array<CompanyProps>>([])
  // 회사 정보 상태 관리
  const [callCtCode, setCallCtCode] = useState('')
  // 회사 정보 상태 관리
  const [makeCtCode, setMakeCtCode] = useState('')
  // 계약 수주자 상태 관리
  const [ctManager, setCtManager] = useState('')
console.log('ctManager : ', ctManager)

  // 선택값 변경시 업데이트
  useEffect(() => {
    // 등록값들 초기화
    setStartContractDate(new Date())
    setStartLicenseDate(new Date())
    setEndLicenseDate(new Date())
    setPeriod('')
    setPlacePurchase('')
    setCompanyName('')
    setAdministrator('')
    setSelectedUserLoginId('')
    setProductTypeName('')
    setLicenseTypeName('')
    setSerialCode('')
    setLicenseCount('')
    setDayMonth('')
    setPurchasePrice('')
    setSalesUnitPrice('')
    // 수정값들 초기화
    setStartContractDateEdit(new Date(companyInfo3?.ctDate))
    setStartLicenseDateEdit(new Date(companyInfo3?.ctPeriodFrom))
    setEndLicenseDateEdit(new Date(companyInfo3?.ctPeriodTo))
    setPeriodEdit(companyInfo3?.ctTerm)
    setPlacePurchaseEdit(companyInfo3?.ctVendor)
    setCompanyNameEdit(companyInfo3?.cId)
    setAdministratorEdit(companyInfo3?.uId)
    setSelectedUserLoginIdEdit(companyInfo3?.uLoginId)
    setProductTypeNameEdit(companyInfo3?.pdCode)
    setLicenseTypeNameEdit(companyInfo3?.act_dsId)
    setSerialCodeEdit(companyInfo3?.ctSerialKey)
    setLicenseCountEdit(companyInfo3?.ctLicense)
    setDayMonthEdit(companyInfo3?.ctPriceStandard)
    setPurchasePriceEdit(companyInfo3?.ctPurchasePrice)
    setSalesUnitPriceEdit(companyInfo3?.ctSalesPrice)
  }, [companyInfo3, openMenu3]);


  // 로그아웃 버튼 클릭 시 sessionStorage에서 isLoggedIn 정보 삭제
  const handleLogout = () => {
    //@ts-ignore
    sessionStorage.clear();
    window.location.reload();
    console.log('로그아웃');
  };


  // 검색창 열고 닫기
  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
    console.log('toggleSearch 동작!');
  };
  // 위젯창 열고 닫기
  const toggleSideMenu = () => {
    setSideMenuVisible(true);
    handleMenu3()
  }

  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };
  // PRODUCT 메뉴 DB조회
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu`, {
        })
        if (response.data.length > 0) {
          setMenuData(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_PRODUCT fetchData:', error);
      }
    };

    fetchData();
  }, []);

  // 고객센터 메뉴 DB조회
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu2`, {
        })
        if (response.data.length > 0) {
          setMenuData2(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_고객센터 fetchData:', error);
      }
    };

    fetchData2();
  }, []);

  // DB 유저 정보조회
  useEffect(() => {

    const fetchData = async () => {
      try {

        // 서버로 cId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getAdminToUserInfoData`, {
          cId: companyName || companyNameEdit,
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          // setUserData(response.data);
          console.log(response.data);
          // 조회용 아이디 안나오게 필터링 
          const DeletedViewData = response.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
          setUserData(DeletedViewData);
          console.log('getAdminToUserInfoData masterContractHeader :', DeletedViewData);
        } else {
          setUserData([])
        }

      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    fetchData();
    console.log('실행');
  }, [companyName, companyInfo3, companyNameEdit]);

  // 유저 정보값불러오기
  const callUserInfo = async () => {
    try {

      // 서버로 cId를 보내기
      const response = await axios.post(`${clientConfig.URL}/getAdminToUserInfoData`, {
        cId: companyName || companyNameEdit,
      });

      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        // setUserData(response.data);
        console.log(response.data);
        // 조회용 아이디 안나오게 필터링 
        const DeletedViewData = response.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
        setUserData(DeletedViewData);
        console.log('getAdminToUserInfoData masterContractHeader :', DeletedViewData);
      } else {
        setUserData([])
      }

    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };
  // 회사 선택할때 마다 실행
  useEffect(() => {
    callUserInfo()
  }, [companyName, companyInfo3, companyNameEdit])


  // 수주자 정보값불러오기
  const callUserInfo2 = async () => {
    try {

      // 서버로 cId를 보내기
      const response = await axios.post(`${clientConfig.URL}/getAdminToUserInfoData`, {
        cId: 1,
      });

      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        // setUserData(response.data);
        console.log('callUserInfo2 : ', response.data);
        // 조회용 아이디 안나오게 필터링 
        const DeletedViewData = response.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
        setUserData2(DeletedViewData);
      } else {
        setUserData2([])
      }

    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };
  // 회사 선택할때 마다 실행
  useEffect(() => {
    callUserInfo2()
  }, [companyName, companyInfo3, companyNameEdit])

  // 제품 라이센스 타입 불러오기
  const getProductLicenseType = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getProductLicenseType`, {
      });

      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        console.log(response.data);
        setProductType(response.data)
      } else {
        console.log('getProductLicenseType오류 : ', response)
      }

    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getProductLicenseType()
  }, [])

  // 제품 정보 불러오기
  const getProductInfo = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getProductInfo`, {

      })

      if (response.data.length > 0) {
        setProductData(response.data); // 서버에서 받아온 데이터를 상태로 설정

      }

    } catch (error) {
      console.error('Error Main productInfo:', error);
    }
  };
  useEffect(() => {
    getProductInfo();
  }, []);

  // 회사 정보 불러오기
  const getcompanyInfo = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getCompany`, {
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setCompanyInfo(response.data);
      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getcompanyInfo();
  }, []);



  // 계약등록 하기
  const insertContractData = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/insertMasterContractInfo`, {
        uId: administrator,
        uLoginId: selectedUserLoginId,
        cId: companyName,
        ctDate: startContractDate.toISOString().split('T')[0],
        ctPeriodFrom: startLicenseDate.toISOString().split('T')[0],
        ctPeriodTo: endLicense.toISOString().split('T')[0],
        ctTerm: period,
        ctLicense: licenseCount,
        pdCode: productTypeName,
        ctLicenseProject: licenseCount,
        ctLicenseTag: licenseCount,
        dsId: licenseTypeName,
        ctSerialKey: serialCode,
        ctPriceStandard: dayMonth,
        ctPurchasePrice: purchasePrice,
        ctSalesPrice: salesUnitPrice,
        ctVendor: placePurchase,
        // ctManager: ctManager
      })
      if (response.status === 200) {
        // 파일등록할 컬럼정보가져오기
        const setWhereCtCode = response.data.codeNumber
        setCallCtCode(response.data.codeNumber)
        try {
          // 파일등록구문
          const formData = new FormData();
          formData.append('ctCode', setWhereCtCode);
          if (selectedFile2) {
            formData.append('file', selectedFile2); // 파일을 FormData에 추가
            formData.append('ctFileName', selectedFile2.name);
          }
          const response = await axios.post(`${clientConfig.URL}/insertMasterContractInfoFile`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data' // 파일 업로드를 위한 헤더 설정
            }
          });
          if (response.status === 200) {
            setPopupMessage("등록 되었습니다.");
            setShowPopup(true);
          } else {
            setPopupMessage("등록 되었습니다.");
            setShowPopup(true);
          }

        } catch (error) {
          // console.log(error);
          setPopupMessage("등록 되었습니다.");
          setShowPopup(true);
        }
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        setPopupMessage(error.response.data.message);
        setShowPopup(true);
      }
      console.log('insertMasterContractInfo / error :', error);
      console.error('insertMasterContractInfo / error :', error);
      // setPopupMessage('서버오류');
      // setShowPopup(true);
    }
    // }
  }

  // 계약 수정 하기
  const editContractData = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/editMasterContractInfo`, {
        uId: administratorEdit,
        uLoginId: selectedUserLoginIdEdit,
        cId: companyNameEdit,
        ctDate: startContractDateEdit.toISOString().split('T')[0],
        ctPeriodFrom: startLicenseDateEdit.toISOString().split('T')[0],
        ctPeriodTo: endLicenseEdit.toISOString().split('T')[0],
        ctTerm: periodEdit,
        ctLicense: licenseCountEdit,
        pdCode: productTypeNameEdit,
        ctLicenseProject: licenseCountEdit,
        ctLicenseTag: licenseCountEdit,
        dsId: licenseTypeNameEdit,
        ctSerialKey: serialCodeEdit,
        ctPriceStandard: dayMonthEdit,
        ctPurchasePrice: purchasePriceEdit,
        ctSalesPrice: salesUnitPriceEdit,
        ctVendor: placePurchaseEdit,
        ctCode: companyInfo3?.ctCode,
      })
      if (response.status === 200) {
        // 성공적으로 업데이트되면 팝업 메시지 설정
        setPopupMessage("수정 되었습니다.");
        setShowPopup(true);
        closeSideMenu();
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        setPopupMessage(error.response.data.message);
        setShowPopup(true);
      }
      console.log('editMasterContractInfo / error :', error);
      console.error('editMasterContractInfo / error :', error);
    }

    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('ctId', companyInfo3?.ctId);
        formData.append('ctCode', companyInfo3?.ctCode);
        formData.append('ctFilePath', companyInfo3?.ctFilePath);
        if (selectedFile) {
          formData.append('file', selectedFile);
          formData.append('ctFileName', selectedFile.name);
        }
        const response = await axios.post(`${clientConfig.URL}/insertMasterContractInfoFile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data' // 파일 업로드를 위한 헤더 설정
          }
        })
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          setPopupMessage("수정 되었습니다.");
          setShowPopup(true);
          handleMenu3()
          closeSideMenu();
        }
      } catch (error) {
        console.log('insertMasterContractInfoFile / error :', error);
        console.error('insertMasterContractInfoFile / error :', error);
        setPopupMessage("수정 중 서버오류가 발생했습니다.");
        setShowPopup(true);
      }
    }
  }

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // 파일 업로드 관리
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file || null);
  };
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);
  // 파일 업로드 관리
  const handleFileChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile2(file || null);
  };


  // 첨부파일 다운로드
  const downloadAttachedContractFile = async (target: string, fileName: string) => {
    console.log({ target, fileName });
    try {
      const response = await axios.post(`${clientConfig.URL}/downloadAttachedContractFile`, {
        ctCode: target
      }, {
        responseType: 'blob' // 파일 다운로드를 위해 responseType을 'blob'으로 설정
      });

      // 응답으로부터 파일 다운로드
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // 다운로드 후 URL 객체 및 링크 제거
      window.URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('파일 다운로드 오류:', error);
      // 오류 처리
    }
  };


  // 계약고유번호 생성
  useEffect(() => {
    axios.post(`${clientConfig.URL}/makeCtCode`)
      .then(response => {
        // console.log('makeCtCode : ', response);
        setMakeCtCode(response.data.makeCtCode)
      })
      .catch(error => {
        console.log('Error while making CtCode : ', error)
        console.error('Error while making CtCode:', error);
      });

  }, [handleMenu3])


  return (
    <div>
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <header id="home">
        {/* <!-- Start Navigation --> */}
        <nav className="navbar navbar-default navbar-fixed dark bootsnav">
          {/* <!-- Start Top Search --> */}
          <div className="container_box"></div>
          <div className="container">
            <div className="row">
              {/* <div className={`top-search ${isSearchVisible ? 'visible' : 'hidden'}`}> */}
              <div className="top-search" style={{ display: isSearchVisible ? 'block' : 'none' }}>
                <div className="input-group">
                  <form action="#">
                    <input type="text" name="text" className="form-control" placeholder="서비스 및 라이선스 검색" />
                    <button type="submit">
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Top Search --> */}

          <div className="container">

            {/* <!-- Start Atribute Navigation --> */}
            <div className="attr-nav">
              <ul>
                {/* <li className="search"><a style={{ cursor: 'pointer' }} onClick={handleClick} ><i className="fa fa-search"></i></a></li> */}
                {/* 검색창 */}
                <li className="search"><a style={{ cursor: 'pointer' }} onClick={toggleSearch} ><i className="fa fa-search"></i></a></li>
                {/* 위젯창 */}
                <li className="side-menu"><a style={{ cursor: 'pointer' }} onClick={toggleSideMenu}><i className="fa fa-bars"></i></a></li>
              </ul>
            </div>
            {/* <!-- End Atribute Navigation --> */}

            {/* <!-- Start Header Navigation --> */}
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                <i className="fa fa-bars"></i>
              </button>
              <a className="navbar-brand" href="/main">
                {/* <img src="assets/img/logo.png" className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" /> */}
                <img src={`${clientConfig.URL}/image/logo.png`} className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" />
              </a>
            </div>
            {/* <!-- End Header Navigation --> */}

            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >Product</a>
                  <ul className="dropdown-menu">
                    {/* <Button>Browse</Button> */}
                    {menuData.map(item => <li><a href={item.msUrl} target={item.msTarget} key={item.pdId}>{item.msName}</a></li>)}
                  </ul>
                </li>

                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >고객센터</a>
                  <ul className="dropdown-menu">
                    {menuData2.map(item => <li><a href={item.msUrl} key={item.pdId}>{item.msName}</a></li>)}

                  </ul>
                </li>

                <li>

                  {/* @ts-ignore */}
                  {/* {sessionStorage.getItem('isLoggedIn') === 'true' ? ( */}
                  {sessionStorage.getItem('isMasterLoggedIn') === 'true' || sessionStorage.getItem('isLoggedIn') === 'true' ? (
                    <a href="login" onClick={handleLogout}>Logout</a>
                  ) : (
                    <a href="login">Login</a>
                  )}
                </li>
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown">
                    내 메뉴
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="user_license">마이 페이지</a>
                    </li>
                    {/* <li>
                  <a href="admin_license_user_update">내 정보 수정</a>
                </li> */}
                    <li>
                      <a href="master_license_management">
                        솔루션 관리자
                      </a>
                    </li>
                    <li>
                      <a href="admin_license">파트너 관리자</a>
                    </li>
                    {/* <li>
                  <a href="user_license">파트너 일반사용자</a>
                </li> */}
                    {/* <li>
                  <a href="index">로그아웃</a>
                </li> */}
                  </ul>
                </li>
              </ul>
            </div>
            {/* <!-- /.navbar-collapse --> */}
          </div>
          <div className={`side ${isSideMenuVisible ? 'on' : ''}`} >
            {/* 위젯창 닫기 버튼 */}
            <a style={{ cursor: 'pointer' }} className="close-side" onClick={closeSideMenu}><i className="fa fa-times"></i></a>
            {!companyInfo3 ? (
              <div className="widget">
                <h4 className="title_widget">계약 등록 하기</h4>
                <div className="widget_superadmin_contract_area">
                  <div className="widget_superadmin_contract_left">
                    <div className="widget_superadmin_contract_left_label">수주자</div>
                    <div className="widget_superadmin_contract_left_label">계약번호</div>
                    <div className="widget_superadmin_contract_left_label">계약일</div>
                    <div className="widget_superadmin_contract_left_label">시작일</div>
                    <div className="widget_superadmin_contract_left_label">만료일</div>
                    <div className="widget_superadmin_contract_left_label">기간</div>
                    <div className="widget_superadmin_contract_left_label">매입처</div>
                    <div className="widget_superadmin_contract_left_label">고객사</div>
                    <div className="widget_superadmin_contract_left_label">담당자</div>
                    <div className="widget_superadmin_contract_left_label">상품</div>
                    <div className="widget_superadmin_contract_left_label">자격유형</div>
                    <div className="widget_superadmin_contract_left_label">시리얼 키</div>
                    <div className="widget_superadmin_contract_left_label">수량</div>
                    <div className="widget_superadmin_contract_left_label">단가기준</div>
                    <div className="widget_superadmin_contract_left_label">매입단가</div>
                    <div className="widget_superadmin_contract_left_label">매출단가</div>
                  </div>
                  <div className="widget_superadmin_contract_right">
                    <div className="widget_superadmin_contract_right_label">
                    <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px', fontSize: 'inherit' }}
                        value={ctManager}
                        onChange={(e) => setCtManager(e.target.value)}
                        // onChange={(e) => console.log('e', e)}
                      >
                        <option value="" disabled hidden>계약수주자</option>
                        {userData2
                          // .filter(user => user.cId === '1')
                          .map(user => (
                            <option key={user.uId} value={user.uId}>{user.uName}</option>
                          ))
                        }
                      </select>
                      {/* {userInfoObject.caName} */}
                      {/* <input
                        type="text"
                        className="widget_superadmin_contract_display"
                        value={ctManager}
                        onChange={(event)=>{setCtManager(event.target.value)}}
                      /> */}
                      {/* </div> */}
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <div className="widget_superadmin_contract_display"> */}
                      <input
                        type="text"
                        className="widget_superadmin_contract_display"
                        value={makeCtCode}
                        readOnly
                      />
                      {/* </div> */}
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="시작일) 2024.02.19"
                      className="widget_superadmin_contract_input"
                    /> */}
                      {/* ./header.css 선언되어있습니다 */}
                      <DatePicker defaultValue={new Date()} className='contractDate'
                        // onChange={(event)=>setStartContractEvent(event)}
                        onChange={(event: DatePickerChangeEvent) => {
                          const selectedDate = event.target.value;
                          if (selectedDate) {
                            setStartContractDate(selectedDate);
                          }
                        }}
                        format="yyyy.MM.dd"
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="시작일) 2024.02.19"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <DatePicker defaultValue={new Date()} className='contractDate'
                        onChange={(event: DatePickerChangeEvent) => {
                          const selectedDate = event.target.value;
                          if (selectedDate) {
                            setStartLicenseDate(selectedDate);
                          }
                        }}

                        format="yyyy.MM.dd"
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="만료일) 2025.02.19"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <DatePicker defaultValue={new Date()} className='contractDate'
                        onChange={(event: DatePickerChangeEvent) => {
                          const selectedDate = event.target.value;
                          if (selectedDate) {
                            setEndLicenseDate(selectedDate);
                          }
                        }}
                        format="yyyy.MM.dd"
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="text"
                        placeholder="기간을 입력해주세요"
                        className="widget_superadmin_contract_input"
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="text"
                        placeholder="매입처 입력해주세요"
                        className="widget_superadmin_contract_input"
                        value={placePurchase}
                        onChange={(e) => setPlacePurchase(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={companyName}
                        onChange={(e) => { setCompanyName(e.target.value); setAdministrator(''); setSelectedUserLoginId(''); }}
                      >
                        <option value="" disabled hidden>회사명</option>
                        {companyInfo.map(item => (
                          <option key={item.cId} value={item.cId}>{item.cName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        // value={administrator}
                        // onChange={(e) => setAdministrator(e.target.value)}
                        onChange={(e) => {
                          // console.log(e);
                          const selectedUser = e.target.value.split(',');
                          setAdministrator(selectedUser[0]); // 첫 번째 요소가 uId
                          setSelectedUserLoginId(selectedUser[1]); // 두 번째 요소가 uLoginId                       
                        }}
                      >
                        <option value="" hidden>담당자 선택</option>
                        {userData.map(user => (
                          // <option key={user.uId} value={[user.uId, user.uLoginId]}>{user.uName}</option>
                          <option key={user.uId} value={[user.uId, user.uLoginId]}>{user.uName}</option>
                        ))
                        }
                      </select>
                    </div>

                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="상품명을 입력해주세요"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={productTypeName}
                        onChange={(e) => setProductTypeName(e.target.value)}
                      >
                        <option value="" disabled hidden>상품명</option>
                        {productData.map(item => (
                          <option key={item.pdId} value={item.pdCode}>{item.pdName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="라이선스유형을 선택해주세요"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px', fontSize: 'inherit' }}
                        value={licenseTypeName}
                        onChange={(e) => setLicenseTypeName(e.target.value)}
                      >
                        <option value="" disabled hidden>자격유형</option>
                        {productType.map(item =>
                          <option key={item.dsId} value={item.dsId}>{item.dsName}</option>
                        )}
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="text"
                        placeholder="시리얼키를 입력해주세요"
                        className="widget_superadmin_contract_input"
                        value={serialCode}
                        onChange={(e) => setSerialCode(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="number"
                        placeholder="수량을 입력해주세요"
                        className="widget_superadmin_contract_input"
                        style={{ textAlign: 'right' }}
                        value={licenseCount}
                        onChange={(e) => setLicenseCount(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="단가기준(일,월)을 선택해주세요"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={dayMonth}
                        onChange={(e) => setDayMonth(e.target.value)}
                      >
                        <option value="" disabled hidden>단가기준(일,월)</option>
                        <option value='일'>일</option>
                        <option value='월'>월</option>
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="number"
                        placeholder="매입단가를 입력해주세요"
                        className="widget_superadmin_contract_input"
                        style={{ textAlign: 'right' }}
                        value={purchasePrice}
                        onChange={(e) => setPurchasePrice(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="number"
                        placeholder="매출단가를 입력해주세요"
                        className="widget_superadmin_contract_input"
                        style={{ textAlign: 'right' }}
                        value={salesUnitPrice}
                        onChange={(e) => setSalesUnitPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="widget">
                  <h4 className="title_upload">파일 첨부</h4>
                  <div className="widget_user_resist_area">
                    <div className="file-upload fillwidth">
                      <input
                        type="text"
                        className="upload-name"
                        value={selectedFile2 ? selectedFile2.name : "파일을 첨부해주세요"}
                        disabled
                      />
                      <label htmlFor="ex_filename2" className="bulk_upload_BT">
                        파일 선택
                      </label>
                      <input
                        type="file"
                        id="ex_filename2"
                        className="upload-hidden"
                        onChange={handleFileChange2}
                      />
                    </div>
                  </div>
                </div>
                <div className="widget_user_resist_area_bt">
                  <a
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                    onClick={insertContractData}
                  >
                    계약 등록
                  </a>
                </div>
              </div>
            ) : (
              <div className="widget">
                <h4 className="title_widget">계약 수정 하기</h4>
                <div className="widget_superadmin_contract_area">
                  <div className="widget_superadmin_contract_left">
                    <div className="widget_superadmin_contract_left_label">계약번호</div>
                    <div className="widget_superadmin_contract_left_label">계약일</div>
                    <div className="widget_superadmin_contract_left_label">시작일</div>
                    <div className="widget_superadmin_contract_left_label">만료일</div>
                    <div className="widget_superadmin_contract_left_label">기간</div>
                    <div className="widget_superadmin_contract_left_label">매입처</div>
                    <div className="widget_superadmin_contract_left_label">고객사</div>
                    <div className="widget_superadmin_contract_left_label">담당자</div>
                    <div className="widget_superadmin_contract_left_label">상품</div>
                    <div className="widget_superadmin_contract_left_label">자격유형</div>
                    <div className="widget_superadmin_contract_left_label">시리얼 키</div>
                    <div className="widget_superadmin_contract_left_label">수량</div>
                    <div className="widget_superadmin_contract_left_label">단가기준</div>
                    <div className="widget_superadmin_contract_left_label">매입단가</div>
                    <div className="widget_superadmin_contract_left_label">매출단가</div>
                  </div>
                  <div className="widget_superadmin_contract_right">
                    <div className="widget_superadmin_contract_right_label">
                      {/* <div className="widget_superadmin_contract_display"> */}
                      <input
                        type="text"
                        className="widget_superadmin_contract_display"
                        value={companyInfo3?.ctCode}
                        readOnly
                      />
                      {/* </div> */}
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="시작일) 2024.02.19"
                      className="widget_superadmin_contract_input"
                    /> */}
                      {/* ./header.css 선언되어있습니다 */}
                      <DatePicker className='contractDate' value={startContractDateEdit}
                        // onChange={(event)=>setStartContractEvent(event)}
                        onChange={(event: DatePickerChangeEvent) => {
                          const selectedDate = event.target.value;
                          if (selectedDate) {
                            setStartContractDateEdit(selectedDate);
                          }
                        }}
                        format="yyyy.MM.dd"
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="시작일) 2024.02.19"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <DatePicker className='contractDate' value={startLicenseDateEdit}
                        onChange={(event: DatePickerChangeEvent) => {
                          const selectedDate = event.target.value;
                          if (selectedDate) {
                            setStartLicenseDateEdit(selectedDate);
                          }
                        }}

                        format="yyyy.MM.dd"
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="만료일) 2025.02.19"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <DatePicker className='contractDate' value={endLicenseEdit}
                        onChange={(event: DatePickerChangeEvent) => {
                          const selectedDate = event.target.value;
                          if (selectedDate) {
                            setEndLicenseDateEdit(selectedDate);
                          }
                        }}
                        format="yyyy.MM.dd"
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="text"
                        placeholder="기간을 입력해주세요"
                        className="widget_superadmin_contract_input"
                        value={periodEdit}
                        onChange={(e) => setPeriodEdit(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="text"
                        placeholder="매입처 입력해주세요"
                        className="widget_superadmin_contract_input"
                        value={placePurchaseEdit}
                        onChange={(e) => setPlacePurchaseEdit(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={companyNameEdit}
                        onChange={(e) => { setCompanyNameEdit(e.target.value); setAdministratorEdit(''); setSelectedUserLoginIdEdit(''); }}
                      >
                        <option value="" disabled hidden>회사명</option>
                        {companyInfo.map(item => (
                          <option key={item.cId} value={item.cId}>{item.cName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        // value={administratorEdit}
                        // onChange={(e) => setAdministrator(e.target.value)}
                        onChange={(e) => {
                          // console.log(e);
                          const selectedUser = e.target.value.split(',');
                          setAdministratorEdit(selectedUser[0]); // 첫 번째 요소가 uId
                          setSelectedUserLoginIdEdit(selectedUser[1]); // 두 번째 요소가 uLoginId                       
                        }}
                      >
                        <option value="" hidden>담당자 선택</option>
                        {userData.map(user => (
                          // <option key={user.uId} value={[user.uId, user.uLoginId]}>{user.uName}</option>
                          <option key={user.uId} value={[user.uId, user.uLoginId]}>{user.uName}</option>
                        ))
                        }
                      </select>
                    </div>

                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="상품명을 입력해주세요"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={productTypeNameEdit}
                        onChange={(e) => setProductTypeNameEdit(e.target.value)}
                      >
                        <option value="" disabled hidden>상품명</option>
                        {productData.map(item => (
                          <option key={item.pdId} value={item.pdCode}>{item.pdName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="라이선스유형을 선택해주세요"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={licenseTypeNameEdit}
                        onChange={(e) => setLicenseTypeNameEdit(e.target.value)}
                      >
                        <option value="" disabled hidden>자격유형</option>
                        {productType.map(item =>
                          <option key={item.dsId} value={item.dsId}>{item.dsName}</option>
                        )}
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="text"
                        placeholder="시리얼키를 입력해주세요"
                        className="widget_superadmin_contract_input"
                        value={serialCodeEdit}
                        onChange={(e) => setSerialCodeEdit(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="number"
                        placeholder="수량을 입력해주세요"
                        className="widget_superadmin_contract_input"
                        style={{ textAlign: 'right' }}
                        value={licenseCountEdit}
                        onChange={(e) => setLicenseCountEdit(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      {/* <input
                      type="text"
                      placeholder="단가기준(일,월)을 선택해주세요"
                      className="widget_superadmin_contract_input"
                    /> */}
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={dayMonthEdit}
                        onChange={(e) => setDayMonthEdit(e.target.value)}
                      >
                        <option value="" disabled hidden>단가기준(일,월)</option>
                        <option value='일'>일</option>
                        <option value='월'>월</option>
                      </select>
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="number"
                        placeholder="매입단가를 입력해주세요"
                        className="widget_superadmin_contract_input"
                        style={{ textAlign: 'right' }}
                        value={purchasePriceEdit}
                        onChange={(e) => setPurchasePriceEdit(e.target.value)}
                      />
                    </div>
                    <div className="widget_superadmin_contract_right_label">
                      <input
                        type="number"
                        placeholder="매출단가를 입력해주세요"
                        className="widget_superadmin_contract_input"
                        style={{ textAlign: 'right' }}
                        value={salesUnitPriceEdit}
                        onChange={(e) => setSalesUnitPriceEdit(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="widget">
                  <h4 className="title_upload">파일 첨부
                    {/* 첨부파일이있으면 보여주기 */}
                    {companyInfo3?.ctFileName && (
                      <a
                        className="btn btn-theme border btn-xs"
                        data-animation="animated slideInUp"
                        onClick={() => downloadAttachedContractFile(companyInfo3?.ctCode, companyInfo3?.ctFileName)}
                      >
                        첨부파일 다운로드
                      </a>
                    )}
                  </h4>
                  <div className="widget_user_resist_area">
                    <div className="file-upload fillwidth">
                      <input
                        type="text"
                        className="upload-name"
                        value={selectedFile ? selectedFile.name : (companyInfo3?.ctFileName || "파일을 첨부해주세요")}
                        disabled
                      />
                      <label htmlFor="ex_filename" className="bulk_upload_BT">
                        파일 선택
                      </label>
                      <input
                        type="file"
                        id="ex_filename"
                        className="upload-hidden"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="widget_user_resist_area_bt">
                  <a
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                    onClick={editContractData}
                  >
                    계약 수정
                  </a>
                </div>
              </div>
            )}
          </div>

        </nav>

      </header>
    </div>
  )
}

export default MasterContractHeader