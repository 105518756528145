import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { clientConfig } from '../../api/clientConfig'
import axios from 'axios';
import { getNomalUserInfoFromStorage } from '../../utils/userInfoModule';
import Popup from '../../Components/Popup/Popup';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, GridColumn as Column, GridRowClickEvent, GridToolbar, GridEvent, GridHeaderCell, GridHeaderCellProps, GridFilterChangeEvent, GridNoRecords, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';

// 제품 데이터 타입 정의
interface Product {
  expiredate: string,
  pdId: string,
  pdCode: string,
  pdName: string;
  pdVersion: string,
  pdFile: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdDescription: string,
  pdImage: string,
  dsName: string;
  pdManual: string;
  pdUse: string;
}

// 입력 데이터 타입 정의
interface FormData {
  name: string;
  email: string;
  phone: string;
  comments: string;
}

// 솔루션 데이터 adm_product_file 타입 선언
interface MasterProductYearInfoProps {
  pfId: string,
  pdId: string,
  pdFile: string,
  pdDescription: string,
  pdVersionYear: string,
  pdUse: string,
  pdUpateDate: string,
}

// Qna 게시판 데이터 타입 정의
interface qnaDataType {
  sbqId: string,
  cId: string,
  sbqQLoginId: string,
  sbqQName: string,
  sbqQEmail: string,
  sbqQPhone: string,
  sbqQContent: string,
  sbqQDate: string,
  sbqQFile: string;
  cCode: string,
  cName: string,
  cNumber: string,
  cPhone: string,
  cPost: string,
  cAddress: string,
  cFilePath: string,
  cFileName: string,
  cDescription: string,
  sbqrId: string,
  sbqALoginId: string,
  sbqAName: string,
  sbqAEmail: string,
  sbqAPhone: string,
  sbqAContent: string,
  sbqADate: string,
  sbqACheckDate: string,
  sbqAFile: string,
  YesORNo: string,
}

// 해당 질문의 대한 답변 데이터 타입 정의
interface qnaAnswerData {
  sbqrId: string,
  sbqId: string,
  sbqALoginId: string,
  sbqAName: string,
  sbqAEmail: string,
  sbqAPhone: string,
  sbqAContent: string,
  sbqADate: string,
  sbqAFile: string,
  sbqACheckDate: string,
}

const User_License = () => {
  const [data, setData] = useState<Array<Product>>([]);
  // 솔루션 제품년도 정보
  // const [masterProductYearInfo, setMasterProductYearInfo] = useState([])
  const [masterProductYearInfo, setMasterProductYearInfo] = useState<Array<MasterProductYearInfoProps>>([])
  // 다운로드 받을 제품년도 상태 관리
  const [productYearSelected, setProductYearSelected] = useState<string[]>(Array(data.length).fill(''));
  console.log('masterProductYearInfo : ', masterProductYearInfo);
  console.log('productYearSelected : ', productYearSelected);
  console.log('User_License : ', data);
  // 세선스토리지에서 정보가져오기
  // const storedUserInfo = sessionStorage.getItem('userInfo');
  // 세선스토리지에 없으면 null
  // const userInfoObject = storedUserInfo ? JSON.parse(storedUserInfo) : null;
  // 세선스토리지에서 정보가져오기
  const nomalUserInfoObject = getNomalUserInfoFromStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // URL에서 pageNumber 값을 읽어와서 현재 페이지로 설정
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('userPage') || '1'));
  // // 현재 페이지 관리
  // const [currentPage, setCurrentPage] = useState(1);
  // 페이지 호출
  const handlePageChange = (pageNumber: React.SetStateAction<number>) => {
    setCurrentPage(pageNumber);
    // 페이지 변경 시 URL의 쿼리 파라미터를 업데이트
    navigate(`/user_license?userPage=${pageNumber}`);
  };

  // URL의 쿼리 파라미터가 변경되었을 때 currentPage 업데이트
  useEffect(() => {
    setCurrentPage(parseInt(queryParams.get('userPage') || '1'));
  }, [location.search]);


  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };

  // 현재 날짜를 가져오는 함수
  const getCurrentDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [todayDate, setTodayDate] = useState<string>(getCurrentDate());

  const calculateRemainingDays = (expiredate: string): number => {
    const today = new Date(todayDate);
    const expire = new Date(expiredate);
    const timeDiff = expire.getTime() - today.getTime();
    // milliseconds to days
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };


  // 제품 데이터 DB 조회
  useEffect(() => {

    const fetchData = async () => {
      try {

        // 서버로 cId와 uId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getUserProductInfo`, {
          uId: nomalUserInfoObject.uId,
          cId: nomalUserInfoObject.cId,
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setData(response.data);
          // console.log(data);
        }

      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    fetchData();
  }, []);


  // 제품년도 불러오기
  const getMasterProductYearInfo = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getProductYear`, {

      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        console.log('getProductYear : ', response.data);
        setMasterProductYearInfo(response.data);
      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getMasterProductYearInfo();
  }, []);


  // 라이선스 다운로드 제목
  const LicenseDownloadTitle = () => {
    return <h1>INNOSERVICE LICENSE DOWNLOAD</h1>;
  };
  // 개인 정보 수정 제목
  const EditMyInfoTitle = () => {
    return <h1>INNOSERVICE EDIT MY PROFILE</h1>;
  };
  // 개인 정보 수정 제목
  const UserContactTitle = () => {
    return <h1>INNOSERVICE CONTACT</h1>;
  };

  // 라이선스 다운로드 부제목
  const LicenseDownloadTitleSub = () => {
    return <>LICENSE DOWNLOAD</>;
  };
  // 개인 정보 수정 부제목
  const EditMyInfoTitleSub = () => {
    return <>MY PROFILE</>;
  };
  // 개인 정보 수정 부제목
  const UserContactTitleSub = () => {
    return <>CONTACT</>;
  };

  // 해당년도 제품다운로드받기
  const onHandleLinkORDownload = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pdName: string,
    pdFile: string,
    pdCode: string,
    pdId: string,
    index: number
  ) => {
    e.preventDefault();
    console.log(pdFile, productYearSelected[index], pdCode);
    console.log(e);
    if (!productYearSelected[index]) {
      setPopupMessage('버전을 선택해주세요')
      setShowPopup(true)
      return false;
    }

    try {
      const response = await axios.post(`${clientConfig.URL}/Masterdownload`, {
        // pdFile: pdFile,
        pdVersionYear: productYearSelected[index],
        pdCode: pdCode,
        pdId: pdId
      }, {
        responseType: 'blob'
      });

      if (response.status === 200) {
        console.log(response);
        console.log(response.data);
        const blob = response.data;
        // const fileName = response.data.pdFile;
        // console.log('fileName : ', fileName)

        // 파일 다운로드를 위해 <a> 엘리먼트를 생성하고 클릭하는 방법
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pdName); // 다운로드할 파일 이름 설정
        document.body.appendChild(link);
        link.click();
        // 다운로드 후 URL 객체 및 링크 제거
        window.URL.revokeObjectURL(url);
        link.parentNode?.removeChild(link);
        // // 파일 이름을 JSON 형식에서 추출
        // const fileName = JSON.parse(await response.data.text()).fileName;
        // console.log(fileName);

        // // 파일 다운로드 성공 시 브라우저가 새 창에서 파일을 엽니다.
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const a = document.createElement('a');

        // a.href = url;
        // a.download = fileName.trim(); // 파일 이름 설정
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
      } else {
        // 파일이 존재하지 않을 경우 404 에러 메시지를 출력합니다.
        console.error('Error while downloading file:', response.data.message);
      }
    } catch (error) {
      console.error('Error while downloading file:', error);
    }
  };

  // 제품 매뉴얼 다운로드받기
  const onHandleMaunalDownload = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pdManual: string,
    pdCode: string,
    pdId: string,
    index: number
  ) => {
    e.preventDefault();
    // console.log(pdManual, productYearSelected[index], pdCode);
    // console.log(e);
    // if (!productYearSelected[index]) {
    //   setPopupMessage('버전을 선택해주세요')
    //   setShowPopup(true)
    //   return false;
    // }

    try {
      const response = await axios.post(`${clientConfig.URL}/MasterManualdownload`, {
        pdManual: pdManual,
        // pdVersionYear: productYearSelected[index],
        pdCode: pdCode,
        pdId: pdId
      }, {
        responseType: 'blob'
      });

      if (response.status === 200) {
        console.log('Product_MasterManualdownload : ', response);
        // 응답으로부터 파일 다운로드
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pdManual); // 빈 문자열로 설정하여 파일명을 서버에서 지정한 이름 그대로 사용
        document.body.appendChild(link);
        link.click();

        // 다운로드 후 URL 객체 및 링크 제거
        window.URL.revokeObjectURL(url);
        link.parentNode?.removeChild(link);
        // // 파일 이름을 JSON 형식에서 추출
        // const fileName = JSON.parse(await response.data.text()).fileName;
        // console.log(fileName);

        // // 파일 다운로드 성공 시 브라우저가 새 창에서 파일을 엽니다.
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const a = document.createElement('a');

        // a.href = url;
        // a.download = fileName.trim(); // 파일 이름 설정
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
      } else {
        // 파일이 존재하지 않을 경우 404 에러 메시지를 출력합니다.
        console.error('Error while downloading file:', response.data.message);
      }
    } catch (error) {
      console.error('Error while downloading file:', error);
    }
  };

  // 라이선스 다운로드 페이지 내용
  const LicenseDownloadPage = () => {
    return <div className="single-item item">
      <h3>
        <a>My License</a>
      </h3>
      <div className="innosolution_pack">
        <div className="innosolution_pack_title">이노액티브 전용 솔루션</div>
        {data.map((item: Product, index) => (
          <div className="solution_info" key={index}>
            <div className="solution_pack">
              <div className="solution_cover_pack">
                <div className="solution_cover">
                  {/* <img src="assets/img/800x600_r3d.png" /> */}
                  {/* <img src={`${clientConfig.URL}/image/${item.pdImage}`} /> */}
                  {/* 제품이미지가 없으면 지정된 기본 이미지출력*/}
                  <img src={item.pdIcon ? `${clientConfig.URL}/product/${item.pdCode}/${item.pdIcon}` : `${clientConfig.URL}/image/default_product_image.jpg`} />
                </div>
              </div>

              <div className="solution_contents">
                <h3>
                  {/* <a >{item.pdName}</a> {item.pdUse === 'N' && <a style={{color: 'red'}}>(서비스지원중지)</a>} */}
                  <div className='innoservice_user_license_title_view'>
                    <a >{item.pdName}</a> {item.pdUse === 'N' && <a className='innoservice_user_license_stop' >(서비스 지원 중지)</a>}
                  </div>
                </h3>
                <div className="solution_head_tag"></div>
                {/* <!-- 라이선스 카드 내용 시작 --> */}
                <div>
                  <div className="solution_bg_pack">
                    <div className="solution_description">
                      {/* <p>VR과 공간 정보, 설비 정보의 결합을 통해 현장에 가까운 경험과 편의성을 제공합니다.</p> */}
                      <p>{item.pdDescription}</p>
                    </div>
                  </div>
                </div>
                {/* <!-- 라이선스 카드 내용 끝--> */}
              </div>
            </div>
            {item.pdType != 'D' ? (
              <div className="solution_meta">
                <ul>
                  {/* <!-- 라이선스 만료 날짜를 표기 하기 위한 단순 오늘 날짜 뷰 --> */}
                  <li><i className="fas fa-calendar-alt"></i>만료일자 : {item.expiredate}</li>
                </ul>
                {item.pdManual && (
                  <a
                    className="btn btn-theme border btn-sm btn-mgb" data-animation="animated slideInUp"
                    onClick={(e) => onHandleMaunalDownload(e, item.pdManual, item.pdCode, item.pdId, index)}
                    style={{ cursor: 'pointer' }}
                    key={index}>
                    Maunal Download
                  </a>
                )}
                {/* Download 버튼 클릭 시 처리 http 으로 시작시 페이지 이동 */}
                {/* <a href={item.pdFile} target="_blank" rel="noopener noreferrer" className="btn btn-theme border btn-sm" data-animation="animated slideInUp"> */}
                <a href={item.pdFile.startsWith('http') ? item.pdFile : `http://${item.pdFile}`} target="_blank" rel="noopener noreferrer" className="btn btn-theme border btn-sm btn-mgb" data-animation="animated slideInUp">
                  Link
                </a>
              </div>
            ) : (
              <div className="solution_meta">
                <ul>
                  <li><i className="fas fa-calendar-alt"></i>만료일자 : {item.expiredate}</li>
                </ul>
                {item.pdManual && (
                  <a
                    className="btn btn-theme border btn-sm" data-animation="animated slideInUp"
                    onClick={(e) => onHandleMaunalDownload(e, item.pdManual, item.pdCode, item.pdId, index)}
                    style={{ cursor: 'pointer' }}
                    key={index}>
                    Maunal Download
                  </a>
                )}

                <div className='innoservice_solution_version_type_years'>
                  <DropDownList style={{ borderRadius: '8px', height: '46px', maxHeight: '46px', textAlignLast: 'center', width: '150px' }}
                    // 일치하는 제품 다운로드
                    // defaultValue={'버전을 선택하세요'}
                    data={masterProductYearInfo
                      .filter(yearItem => yearItem.pdId === item.pdId)
                      .map(yearItem => yearItem.pdVersionYear)}
                    value={productYearSelected}
                    onChange={(event) => {
                      // productYearSelected를 복제하여 새 배열을 생성하고, 해당 index의 값을 변경합니다.
                      const newSelected = [...productYearSelected];
                      newSelected[index] = event.value; // 현재 index에 선택된 값 설정
                      setProductYearSelected(newSelected); // 변경된 배열을 상태로 설정합니다.
                    }}
                  />
                </div>

                {/* <a href={`${clientConfig.URL}/download/${item.pdFile}`}
                className="btn btn-theme border btn-sm" data-animation="animated slideInUp"
                download={item.pdFile}> */}
                <a
                  className="btn btn-theme border btn-sm" data-animation="animated slideInUp"
                  onClick={(e) => onHandleLinkORDownload(e, item.pdName, item.pdFile, item.pdCode, item.pdId, index)}
                  style={{ cursor: 'pointer' }}
                  key={index}>
                  Download
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>;

  }


  // 개인 정보 수정 내용
  const EditMyInfoPage = () => {
    // 개인 정보 수정 관리
    const [editMyInfo, setEditMyInfo] = useState({
      name: nomalUserInfoObject.uName,
      email: nomalUserInfoObject.uEmail,
      phone: nomalUserInfoObject.uPhone,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    console.log(editMyInfo);
    // 팝업창 상태 관리
    const [showPopup, setShowPopup] = useState(false);
    // 팝업창 메세지 관리
    const [popupMessage, setPopupMessage] = useState('');
    // 팝업창 닫기 관리
    const closePopup = () => {
      setShowPopup(false);
    };
    // 입력된 정보로 업데이트
    const handleEditMyInfo = async () => {
      if (!editMyInfo.currentPassword) {
        setPopupMessage("현재 암호를 입력해주세요")
        setShowPopup(true)
        return false;
      }
      if (!editMyInfo.newPassword) {
        setPopupMessage("바꿀 암호를 입력해주세요")
        setShowPopup(true)
        return false;
      }
      if (editMyInfo.newPassword !== editMyInfo.confirmPassword) {
        setPopupMessage("암호 확인이 서로 다릅니다.")
        setShowPopup(true)
        return false;
      }
      if (!/^[ㄱ-ㅎ가-힣a-zA-Z]*$/.test(editMyInfo.name)) {
        setPopupMessage("이름은 한글과 영어만 가능합니다.");
        setShowPopup(true);
        return false;
      }
      if (editMyInfo.name == '') {
        setPopupMessage("이름은 한글과 영어만 가능합니다.");
        setShowPopup(true);
        return false;
      }
      else {
        console.log('암호 같음');
        try {
          const response = await axios.post(`${clientConfig.URL}/editMyInfo`, {
            uId: nomalUserInfoObject.uId ?? null,
            cId: nomalUserInfoObject.cId,
            uName: editMyInfo.name,
            uEmail: editMyInfo.email,
            uPhone: editMyInfo.phone,
            uLoginPw: editMyInfo.currentPassword,
            newPassword: editMyInfo.newPassword,
            confirmPassword: editMyInfo.confirmPassword,
          })
          console.log(response);
          if (response.status === 200) {
            setPopupMessage("수정 되었습니다.");
            setShowPopup(true);
            setTimeout(() => {
              sessionStorage.clear();
              window.location.reload();
            }, 1000 );
          }          
        } catch (error: any) {
          console.error("비밀번호 확인 오류:", error);
          if (error.response.status === 401) {
            setPopupMessage("현재 비밀번호를 확인해주세요.");
            setShowPopup(true);
          }
          else if (error.response.status === 400) {
            setPopupMessage("이메일이 중복입니다.");
            setShowPopup(true);
          }
          else {
            setPopupMessage("서버 오류 관리자에게 문의해 주세요.");
            setShowPopup(true);
          }
        }
      }
    }

    return <div className="single-item item">
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <div className="info">
        <div className="user_info_area">
          <div className="user_informaiton_cover_image">
            <img src="assets/img/user_info_cover.jpg" />
            {/* <div style="background-image:url('assets/img/user_info_cover.jpg')"></div> */}
          </div>
          <div className="user_information_contents_pack">
            <div className="contact-form">
              <h2>개인 정보 수정</h2>
              <p>이름, E-mail, 암호 및 전화번호를 수정합니다</p>
              <form
                action="영교님이하시면되요"
                method="POST"
                className="contact-form"
              >
                <div className="col-md-12">
                  <div className="row">

                    <div className="form-group">
                      로그인 계정 : {nomalUserInfoObject?.uLoginId}
                    </div>

                    <div className="form-group">
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder={nomalUserInfoObject?.uName ?? ''}
                        type="text"
                        autoComplete='off'
                        value={editMyInfo?.name}
                        // onChange={(e) => setEditMyInfo({ ...editMyInfo, name: e.target.value })}
                        onChange={(e) => {
                          const value = e.target.value;
                          // 한글과 영어만 허용하는 정규식
                          const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]*$/;
                          // 입력된 값에서 한글과 영어 이외의 문자를 제거
                          if (regex.test(value)) {
                            setEditMyInfo({ ...editMyInfo, name: value });
                          }
                        }}                      
                      />
                      <span className="alert-error" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="email"
                        name="email"
                        autoComplete='off'
                        value={editMyInfo?.email}
                        placeholder={`E-mail : ` + nomalUserInfoObject?.uEmail ?? ''}
                        type="email"
                        onChange={(e) => setEditMyInfo({ ...editMyInfo, email: e.target.value })}
                      />
                      <span className="alert-error" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="phone"
                        name="phone"
                        autoComplete='off'
                        placeholder={nomalUserInfoObject?.uPhone ?? ''}
                        type="text"
                        onChange={(e) => setEditMyInfo({ ...editMyInfo, phone: e.target.value })}
                      />
                      <span className="alert-error" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="password"
                        name="password"
                        autoComplete='off'
                        placeholder="현재 암호"
                        type="password"
                        onChange={(e) => setEditMyInfo({ ...editMyInfo, currentPassword: e.target.value })}
                      />
                      <span className="alert-error" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="password"
                        name="password"
                        autoComplete='off'
                        placeholder="바꿀 암호"
                        type="password"
                        onChange={(e) => setEditMyInfo({ ...editMyInfo, newPassword: e.target.value })}
                      />
                      <span className="alert-error" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="password"
                        name="password"
                        autoComplete='off'
                        placeholder="암호 확인"
                        type="password"
                        onChange={(e) => setEditMyInfo({ ...editMyInfo, confirmPassword: e.target.value })}
                      />
                      <span className="alert-error" />
                    </div>
                  </div>
                </div>
                {/* Alert Message */}
                <div className="col-md-12 alert-notification">
                  <div id="message" className="alert-msg" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="meta">
          <div className='user_information_contents_pack_arr'>
            <div className='user_information_contents_pack_arr_left'>
              <ul>
                {/* 라이선스 만료 날짜를 표기 하기 위한 단순 오늘 날짜 뷰 */}
                <li>
                  <i className="fas fa-calendar-alt" />
                  현재 날짜 : {getCurrentDate()}
                </li>
              </ul>
            </div>

            <div className='user_information_contents_pack_arr_right'>
              <a
                className="btn btn-theme border btn-sm"
                data-animation="animated slideInUp"
                onClick={() => { handleEditMyInfo() }}
              >
                수정 완료
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>;
  };

  // 문의 하기 내용
  const UserContactPage = () => {
    console.log('UserContactPage 작동중');
    // 입력정보 관리
    const [formData, setFormData] = useState<FormData>({
      name: nomalUserInfoObject.uName,
      email: nomalUserInfoObject.uEmail,
      phone: nomalUserInfoObject.uPhone,
      comments: '',
    });
    // DB Qna 정보 관리
    const [qnaUserData, setQnaUserDataData] = useState<Array<qnaDataType>>([]);
    // 답변 내용 상태
    const [answerQna, setAnswerQna] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    // 답변별 페이지 구분
    const [qnaUserIndex, setQnaUserIndex] = useState<number>(0);
    // 선택된 질문의 데이터 저장
    const [selectedRow, setSelectedRow] = useState<qnaDataType | null>(null);
    // 선택된 질문의 대답 데이터
    const [answerContent, setAnswerContent] = useState<Array<qnaAnswerData>>([]);
    // 문의하기 이동 상태 관리
    const [showQnaUser, setShowQnaUser] = useState(false);
    // 해당 답변보기 이동 상태 관리
    const [showQnaUserAnswer, setShowQnaUserAnswer] = useState(false);
    // 답변일 오름차순 관리
    const initialSort: Array<SortDescriptor> = [
      { field: "sbqQDate", dir: "asc" },
    ];
    // 정렬 상태 관리
    // const [sort, setSort] = React.useState(Array<SortDescriptor>);
    const [sort, setSort] = React.useState(initialSort);
    // 검색어 상태관리
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    // 검색어 입력란 변경이벤트 처리
    const handleSearchChange = (event: InputChangeEvent) => {
      setSearchKeyword(event.target.value as string);
    };

    //  검색어에 일치하는 데이터 필터링
    const filteredQnAData = qnaUserData.filter((row) => {
      // const filteredData = masterContractData.filter((row) => {
      return Object.values(row).some((value) => {
        return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
      });
    });
    // 팝업창 보여주기 상태 관리
    const [showPopup, setShowPopup] = useState(false);
    // 팝업창 메세지 상태관리
    const [popupMessage, setPopupMessage] = useState('');
    // 팝업창 닫기
    const closePopup = () => {
      setShowPopup(false);
    };



    // 입력감지
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // 문의 하기
    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();

      try {
        // userInfoObject가 null인지 확인하고 구조 분해를 진행합니다.
        if (nomalUserInfoObject) {
          const { uLoginId, cId } = nomalUserInfoObject;
          if (!formData.comments) {
            setPopupMessage('문의 내용을 입력해주세요.')
            setShowPopup(true)
            // 팝업닫기
            setTimeout(() => {
              setShowPopup(false);
            }, 1000);
            return false
          }

          // console.log('uLoginId', uLoginId);
          // console.log('userInfoObject', userInfoObject);

          // cId 값을 추가하여 서버로 전송합니다.
          const response = await axios.post(`${clientConfig.URL}/inputContact`, {
            sbqQName: formData.name,
            sbqQEmail: formData.email,
            sbqQPhone: formData.phone,
            sbqQContent: formData.comments,
            uLoginId: uLoginId,
            cId: cId,
          });

          // 서버로부터의 응답 처리
          console.log('Server Response:', response.data);
          setShowQnaUser(false)
          setPopupMessage("문의가 접수되었습니다.");
          setShowPopup(true);
          // 문의 데이터 랜더링
          reLoadQnaGridData()
          // 팝업닫기
          setTimeout(() => {
            setShowPopup(false);
          }, 1000);
          // 기타 로직 추가 가능
        } else {
          console.error('User information not available.');
        }

      } catch (error) {
        console.error('Error while handling submit:', error);
      }
    };


    // 해당 문의에 대한 답변하기
    const handleQnaAnswer = async (event: GridRowClickEvent) => {
      // 문의 내용 출력전 상태 초기화
      setAnswerContent([])
      console.log('QnaPage qnaUserData : ', qnaUserData);
      console.log('QnaPage event.dataItem : ', event.dataItem);
      console.log('QnaPage event.dataItem.sbqId : ', event.dataItem.sbqId);
      // 선택 정보 입력
      setSelectedRow(event.dataItem)
      // 실행 답변 확인시 실행
      axios.post(`${clientConfig.URL}/updateCheckDate`, {
        sbqId: event.dataItem.sbqId
      });
      // 선택한 질문에 대한 답변 연결
      try {
        console.log('/시작 완료');
        // 질문번호로 값가져오기
        const response = await axios.post(`${clientConfig.URL}/getQnaAnswer`, {
          sbqId: event.dataItem.sbqId
        });
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setAnswerContent(response.data);
          console.log('/getQnaAnswer 완료 : ', response.data);
        }
        // 답변내용 화면 활성화
        setShowQnaUserAnswer(true)
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
      // 유저 답변보여주기?

    }

    // 문의 목록 정보 업데이트
    const reLoadQnaGridData = async () => {
      try {
        // 서버로 cId와 uId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getQnaUser`, {
          sbqQLoginId: nomalUserInfoObject?.uLoginId,
          cId: nomalUserInfoObject?.cId,
        });
        // console.log(response);
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setQnaUserDataData(response.data);
          // console.log(response.data);
        }
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };
    // 문의 접수하기 하면 업데이트
    useEffect(() => {
      reLoadQnaGridData()
    }, [])

    // 답변 목록 정보 업데이트
    const getUserQnaAnswer = async () => {
      try {
        // 서버로 cId와 uId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getUserQnaAnswer`, {
          sbqQLoginId: nomalUserInfoObject?.uLoginId,
        });
        // console.log(response);
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setQnaUserDataData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };
    // 문의 접수하기 하면 업데이트
    useEffect(() => {
      getUserQnaAnswer()
    }, [])




    // 이전 문의 버튼
    const handlePrevQna = async () => {
      if (qnaUserIndex > 0) {
        setQnaUserIndex(qnaUserIndex - 1);
        setSelectedRow(qnaUserData[qnaUserIndex - 1]);
        // 응답 데이터 초기화
        setAnswerContent([])
        axios.post(`${clientConfig.URL}/updateCheckDate`, {
          sbqId: qnaUserData[qnaUserIndex - 1]?.sbqId
        });
        try {
          console.log('/시작 완료');
          // 질문번호로 값가져오기
          const response = await axios.post(`${clientConfig.URL}/getQnaAnswer`, {
            sbqId: qnaUserData[qnaUserIndex - 1]?.sbqId
          });
          if (response.data.length > 0) {
            // 서버에서 받아온 데이터를 상태로 설정
            setAnswerContent(response.data);
            console.log('이전', response.data);

          }

        } catch (error) {
          console.error('Error user_license fetchData:', error);
        }
      }
    };

    // 다음 문의 버튼
    const handleNextQna = async () => {
      if (qnaUserIndex < qnaUserData.length - 1) {
        setQnaUserIndex(qnaUserIndex + 1);
        setSelectedRow(qnaUserData[qnaUserIndex + 1]);
        // 응답 데이터 초기화
        setAnswerContent([])
        axios.post(`${clientConfig.URL}/updateCheckDate`, {
          sbqId: qnaUserData[qnaUserIndex + 1]?.sbqId
        });
        try {
          console.log('/시작 완료');
          // 질문번호로 값가져오기
          const response = await axios.post(`${clientConfig.URL}/getQnaAnswer`, {
            sbqId: qnaUserData[qnaUserIndex + 1]?.sbqId
          });
          if (response.data.length > 0) {
            // 서버에서 받아온 데이터를 상태로 설정
            setAnswerContent(response.data);
            console.log('다음', response.data);
          }

        } catch (error) {
          console.error('Error user_license fetchData:', error);
        }
      }
    };

    // 답변 수정 버튼 클릭 시 호출되는 함수
    const handleEditAnswer = () => {
      // setAnswerQna(answerContent[0]?.sbqAContent);
      setAnswerQna(answerContent[0]?.sbqAContent);
      setIsEditing(true);
      console.log('수정동작');
    };

    // 답변 수정후 저장 버튼 클릭 시 호출되는 함수
    const handleSaveEditedAnswer = async () => {
      setIsEditing(false);
      console.log('저장동작');
      try {
        const response = await axios.post(`${clientConfig.URL}/editUserQnaAnswer`, {
          // 선택한 질문의 고유값
          sbqId: selectedRow?.sbqId,
          // sbqALoginId: userInfoObject.uLoginId,
          // sbqAName: userInfoObject.uName,
          // sbqAEmail: userInfoObject.uEmail,
          // sbqAPhone: userInfoObject.uPhone,
          sbqQLoginId: nomalUserInfoObject.uLoginId,
          sbqQName: nomalUserInfoObject.uName,
          sbqQEmail: nomalUserInfoObject.uEmail,
          sbqQPhone: nomalUserInfoObject.uPhone,
          // 답변 내용 추가
          sbqQContent: answerQna,
          sbqQFile: null,
        });

        // 서버 응답에 따른 처리
        console.log('editUserQnaAnswer : ', response);
        if (response.status === 200) {
          // 등록한 답변 내용 보기
          console.log('selectedRow?.sbqId', selectedRow?.sbqId);
          // 팝업닫기

          setPopupMessage("수정 되었습니다")
          setShowPopup(true)
          setTimeout(() => {
            handlePageChange(2)
          }, 1000);
        }
      } catch (error) {
        console.error('Error editing Qna answer:', error);
      }

    };


    // 등록한 답변 삭제
    const deleteQnaAnswer = async () => {
      console.log('답변 삭제 sbqId : ' + selectedRow?.sbqId);
      // console.log('답변 삭제 sbqId : ' + answerContent[0]?.sbqId);
      try {
        // 질문번호로 값가져오기
        const response = await axios.post(`${clientConfig.URL}/deleteUserQnaAnswer`, {
          sbqId: selectedRow?.sbqId
        });
        if (response.status === 200) {
          // 서버에서 받아온 데이터를 상태로 설정
          console.log('/삭제 완료 : ', response.data);
          setPopupMessage("삭제 되었습니다")
          setShowPopup(true)
          setTimeout(() => {
            handlePageChange(2)
          }, 1000);
        }
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
      // 등록한 답변 내용 보기
      // reLoadQnaAnswerData();
      // 문의 목록 정보 업데이트
      // reLoadQnaGridData();
    }



    return <div className="single-item item">
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <h3>
        <a>InnoService 문의하기</a>
      </h3>
      <div className="info">
        <div className='info_pack'>
          {/* 문의하기 목록먼저보여주기 */}
          {!showQnaUserAnswer && !showQnaUser ? (
            <div className="contact-form">
              <Grid
                // data={qnaUserData}
                data={orderBy(filteredQnAData, sort)}
                onRowClick={(event) => handleQnaAnswer(event)}
                resizable={true}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  setSort(e.sort);
                }}
                style={{ height: '40vh' }}
              >
                <GridToolbar>
                  검색 :
                  <Input
                    placeholder='검색할 내용을 입력해 주세요.'
                    style={{ width: '50%' }}
                    onChange={handleSearchChange}
                  />
                </GridToolbar>
                {/* 데이터없을때 나오는 문구 */}
                <GridNoRecords>
                  <div style={{ height: '100vh' }}>
                    요청한 문의가 없습니다.
                  </div>
                </GridNoRecords>
                <div hidden>
                  <Column field="id" cell={(props) => {
                    const index = props.dataIndex
                    return (
                      <td style={{ textAlign: 'center' }} >
                        {index}
                      </td>
                    )
                  }} />
                </div>
                {/* <Column field="sbqQContent" title='문의 글' /> */}
                <Column field="sbqQContent" title='문의 글'
                  cell={(props) => {
                    const sbqQContent = props.dataItem.sbqQContent
                    return (
                      <td style={{ textAlign: 'center' }} >
                        {/* {sbqQContent?.substring(0, 7)} */}
                        {sbqQContent}
                      </td>
                    )
                  }}
                />
                {/* <Column field="cName" title='소속사' /> */}
                {/* <Column field="cName" title='소속사' cell={(props) => {
                const cName = props.dataItem.cName
                return (
                  <td style={{ textAlign: 'center' }}>
                    {cName}
                  </td>
                )
              }}
              /> */}
                {/* <Column field="sbqQName" title='문의자' cell={(props) => {
                const sbqQName = props.dataItem.sbqQName
                return (
                  <td style={{ textAlign: 'center' }}>
                    {sbqQName}
                  </td>
                )
              }}
              /> */}
                <Column field="sbqQDate" title='문의날짜' width={85} cell={(props) => {
                  const sbqQDate = props.dataItem.sbqQDate
                  return (
                    <td style={{ textAlign: 'center' }}>
                      {/* 날짜 형식 변환 */}
                      {/* {new Date(sbqQDate)?.toLocaleDateString()} */}
                      {sbqQDate?.substring(0, 10)}
                    </td>
                  )
                }}
                />
                {/* <Column field='YesORNo' title='답변 여부' /> */}
                <Column field='YesORNo' title='답변 여부' width={85} cell={(props) => {
                  const YesORNo = props.dataItem.YesORNo
                  return (
                    <td style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: YesORNo === '답변 완료' ? '#7d92a7' : '#c3c3c3',
                    }}>
                      {YesORNo}
                    </td>
                  )
                }} />
              </Grid>
              <div className="meta">
                <ul>
                  {/* 라이선스 만료 날짜를 표기 하기 위한 단순 오늘 날짜 뷰 */}
                  <li>
                    <i className="fas fa-calendar-alt" />
                    {/* 현재 날짜 : 2024년 3월 19일 */}
                    현재 날짜 : {getCurrentDate()}
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  onClick={() => setShowQnaUser(true)}
                >
                  문의 이동하기 <i className="fa fa-paper-plane" />
                </button>
              </div>
            </div>
          ) : showQnaUser ? (
            <div>
              <div className="contact-form">
                <h2>문의 하기</h2>
                <p>
                  이노서비스를 제공하는 이노액티브에 라이선스 관련 문의를 하는
                  페이지입니다.
                  <br />
                  친절한 상담을 통해 귀하의 업무에 힘이 되겠습니다.
                </p>
                <form onSubmit={handleSubmit}
                  className="contact-form"
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="이름"
                          type="text"
                          onChange={handleChange}
                          value={formData.name}
                          maxLength={50}
                        />
                        <span className="alert-error" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="E-mail*"
                          type="email"
                          onChange={handleChange}
                          value={formData.email}
                          maxLength={500}
                        />
                        <span className="alert-error" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="전화번호"
                          type="text"
                          onChange={handleChange}
                          value={formData.phone}
                          maxLength={50}
                        />
                        <span className="alert-error" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group comments">
                        <textarea
                          className="form-control_content"
                          id="comments"
                          name="comments"
                          placeholder="문의내용을 입력해주세요*(최대 2000자)"
                          onChange={handleChange}
                          value={formData.comments}
                          defaultValue={""}
                          maxLength={2000}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Alert Message */}
                  <div className="col-md-12 alert-notification">
                    <div id="message" className="alert-msg" />
                  </div>
                </form>
              </div>
              <div className="meta">
                <ul>
                  {/* 라이선스 만료 날짜를 표기 하기 위한 단순 오늘 날짜 뷰 */}
                  <li>
                    <i className="fas fa-calendar-alt" />
                    {/* 현재 날짜 : 2024년 3월 19일 */}
                    현재 날짜 : {getCurrentDate()}
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  onClick={handleSubmit}
                >
                  문의 접수하기 <i className="fa fa-paper-plane" />
                </button>
                {/* <a href="admin_license_management.html"></a> */}
              </div>

            </div>

          ) : null}
          {showQnaUserAnswer && (

            <div>
              <div className="contact-form">
                <div className="superadmin_license_list">
                  <h2>문의 확인 및 답변</h2>
                  <div className="superadmin_list_bt_pack">
                    <div className="superadmin_list_bt_margin">
                      <a
                        // href="#"
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        onClick={handlePrevQna}
                      >
                        이전 문의
                      </a>
                    </div>
                    <div className="superadmin_list_bt_margin">
                      <a
                        // href="#"
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        onClick={handleNextQna}
                      >
                        다음 문의
                      </a>
                    </div>
                    <div className="superadmin_list_bt_margin">
                      <a
                        // href="master_license_management_main.html"
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        onClick={() => handlePageChange(2)}
                      >
                        문의 리스트
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="superadmin_license_list_contents">
                {/* <img src="assets/img/temp_contactshot_cc.jpg" /> */}
                {/* <div style={{ textAlign: 'center' }}>문의 글 : {selectedRow?.sbqQContent?.substring(0, 7)} | 소속사 : {selectedRow?.cName} | 문의자 : {selectedRow?.sbqQName} | 날짜 : {selectedRow?.sbqQDate?.substring(0, 10)} | {selectedRow?.YesORNo}</div> */}
                <div className='superadmin_license_list_contents_detail_data'>

                  <div className='superadmin_license_list_contents_detail_data_status_pack'>

                    <div className='superadmin_license_list_contents_detail_data_status_target'>

                      <div className='superadmin_license_list_contents_detail_data_status_target_title'>
                        문의 글 : {selectedRow?.sbqQContent}
                      </div>
                    </div>


                    {/* 답변 여부 메시지 */}
                    <div className='superadmin_license_list_contents_detail_data_status_info'>
                      {selectedRow?.YesORNo}
                    </div>

                  </div>

                  <div className='superadmin_license_list_contents_detail_data_status_pack_se'>

                    <div className='superadmin_license_list_contents_detail_data_status_target_custom'>


                      <div className='superadmin_license_list_contents_detail_data_status_target_custom_qname'>

                        <div className='superadmin_license_list_contents_detail_data_status_target_custom_qname_title'>
                          문의자
                        </div>

                        <div className='superadmin_license_list_contents_detail_data_status_target_custom_qname_cc'>
                          {selectedRow?.sbqQName}
                        </div>

                      </div>



                      <div className='superadmin_license_list_contents_detail_data_status_target_custom_cname'>

                        <div className='superadmin_license_list_contents_detail_data_status_target_custom_cname_title'>
                          소속사
                        </div>

                        <div className='superadmin_license_list_contents_detail_data_status_target_custom_cname_cc'>
                          {selectedRow?.cName}
                        </div>

                      </div>

                      <div className='superadmin_license_list_contents_detail_data_status_target_custom_date'>

                        <div className='superadmin_license_list_contents_detail_data_status_target_custom_date_title'>
                          날짜
                        </div>

                        <div className='superadmin_license_list_contents_detail_data_status_target_custom_date_cc'>
                          {selectedRow?.sbqQDate?.substring(0, 10)}
                        </div>

                      </div>

                    </div>
                  </div>


                </div>
                <br />
                {/* <br /> */}
                {/* {selectedRow?.sbqQContent} */}
              </div>
              {isEditing ? (
                <textarea
                  className="admin_answer_content"
                  id="comments"
                  name="comments"
                  placeholder="답변할 내용을 입력해주세요*"
                  defaultValue={selectedRow?.sbqQContent}
                  onChange={(e) => setAnswerQna(e.target.value)}
                />
              ) : (
                // 답변내용
                // qnaUserData.map((qnaUserData, index) => (
                //   <div key={index}>
                //     {qnaUserData.sbqQContent}
                //   </div>
                // ))
                selectedRow?.sbqQContent
              )}
              {/* {selectedRow?.sbqAContent} */}

              <div className="superadmin_license_list_contents">
                <br />
                {/* 답변이없을때 안보임 */}
                {!(answerContent[0]?.sbqAContent) && (
                  <div className="admin_answer_content_bt_align">
                    <div className="superadmin_list_bt_margin">
                      {/* 답변 수정 버튼 */}
                      <a
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        onClick={isEditing ? handleSaveEditedAnswer : handleEditAnswer}
                      >
                        {isEditing ? '문의글 저장' : '문의글 수정'}
                      </a>
                      {/* <a
                    // href="#"
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                  >
                    답변 수정
                  </a> */}
                    </div>
                    <div className="superadmin_list_bt_margin">
                      <a
                        // href="#"
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        onClick={deleteQnaAnswer}
                      >
                        문의글 삭제
                      </a>
                    </div>
                  </div>
                )}

              </div>
              {/* 답변일있으면 보임 */}
              {answerContent[0]?.sbqADate && (
                <div className="meta">
                  <div className="admin_answer_content_action">
                    <h3>등록된 답변</h3>
                    <div style={{ textAlign: 'right' }}>답변날짜 : {answerContent[0]?.sbqADate?.substring(0, 10)}</div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group comments">
                          <div className="admin_answer_content_action_finish">
                            {answerContent.map((answerData, index) => (
                              <div key={index}>
                                {answerData.sbqAContent}
                              </div>
                            ))
                            }

                            {/* {answerContent[0]?.sbqAContent} */}
                            {/* {seletedQnaAnswerData[0]?.sbqAContent} */}
                            {/* <div className="admin_answer_content_action_finish_att">
                      첨부파일 :{" "}
                      <span>
                        <a href="#" target="_blank">
                          {qnaAnswerContent[0]?.sbqAFile}
                        </a>
                      </span>
                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="meta">
                <ul>
                  {/* 라이선스 만료 날짜를 표기 하기 위한 단순 오늘 날짜 뷰 */}
                  <li>
                    <i className="fas fa-calendar-alt" />
                    {/* 현재 날짜 : 2024년 3월 19일 */}
                    현재 날짜 : {getCurrentDate()}
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  onClick={() => { setShowQnaUser(true); setShowQnaUserAnswer(false); }}
                >
                  문의접수 하기 <i className="fa fa-paper-plane" />
                </button>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  }



  return (
    <div>
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <Header />

      {/* <!-- Start Breadcrumb  */}
      {/* ============================================= --> */}
      {/* <div className="breadcrumb-area shadow dark bg-fixed text-center text-light" style="background-image: url(assets/img/2440x1578.png);"> */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-center text-light"
        style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {currentPage === 1 && <LicenseDownloadTitle />}
              {currentPage === 2 && <UserContactTitle />}
              {currentPage === 3 && <EditMyInfoTitle />}
              <ul className="breadcrumb">
                <li>
                  <a >
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">
                  {currentPage === 1 && <LicenseDownloadTitleSub />}
                  {currentPage === 2 && <UserContactTitleSub />}
                  {currentPage === 3 && <EditMyInfoTitleSub />}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Breadcrumb --> */}


      {/* <!-- Start License Management */}
      {/* ============================================= --> */}
      <div className="blog-area full-blog left-sidebar full-blog default-padding">
        <div className="container">
          <div className="row">
            <div className="blog-items">
              <div className="blog-content col-md-9">
                {/* <!-- Single Item --> */}
                {currentPage === 1 && <LicenseDownloadPage />}
                {currentPage === 2 && <UserContactPage />}
                {currentPage === 3 && <EditMyInfoPage />}
                {/* <!-- End Single Item --> */}
              </div >

              {/* <!-- Start Sidebar --> */}
              < div className="sidebar col-md-3 sidebar_mg_top" >
                <aside>
                  {/* <!-- 검색 시작 --> */}
                  {/* <!-- 검색 끝 --> */}
                  <div className="sidebar-item category">
                    <div className="title">
                      <h4>InnoService</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        {/* <li>
                          <a >공지 사항<span></span></a>
                        </li> */}
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(1)}>
                            라이선스 다운로드</a>
                        </li>
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(2)}>
                            문의 하기</a>
                        </li>
                        <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(3)}>
                            개인 정보 수정
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="sidebar-item recent-post">
                    <div className="title">
                      <h4>라이선스 임박 리스트</h4>
                    </div>
                    <ul>
                      {data.map((item: Product) => (
                        <li>
                          <div className="thumb">
                            <a >
                              {/* <img src="assets/img/800x600_r3d.png" alt="Thumb" /> */}
                              {/* <img src={`${clientConfig.URL}/image/${item.pdImage}`} /> */}
                              {/* 제품이미지가 없으면 지정된 기본 이미지출력*/}
                              <img src={item.pdIcon ? `${clientConfig.URL}/product/${item.pdCode}/${item.pdIcon}` : `${clientConfig.URL}/image/default_product_image.jpg`} />
                              {/* <img src={item.pdImage ? `${clientConfig.URL}/image/${item.pdImage}` : `${clientConfig.URL}/image/default_product_image.jpg`} /> */}
                            </a>
                          </div>
                          <div className="info">
                            <a >{item.pdName}</a>{item.pdUse === 'N' && <a style={{ color: 'red' }}>(서비스 지원 중지)</a>}
                            {/* <a >라이선스 유형 : 동시접속</a> */}
                            <a >자격유형 :<br />{item.dsName}</a>
                            <div className="meta-title">
                              {/* <span className="post-date">{item.expiredate}</span>&nbsp;<span className="post-date">(19일 남음)</span> */}
                              <span className="post-date">{item.expiredate}</span>&nbsp;<span className="post-date">({calculateRemainingDays(item.expiredate)}일남음)</span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                </aside >
              </div >
              {/* <!-- End Start Sidebar --> */}

            </div >
          </div >
        </div >
      </div >
      {/* <!-- End License Management --> */}




      <Footer />

    </div >
  )
}

export default User_License