import React, { useEffect, useState, } from 'react'
import {
    Grid, GridColumn as Column,
    GridNoRecords, GridSortChangeEvent
} from '@progress/kendo-react-grid';
import axios from 'axios';
import { clientConfig } from '../../api/clientConfig';
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import useFetchLicenseData from './useFetchLicenseData';
import { getNomalUserInfoFromStorage } from '../../utils/userInfoModule';

const initialSort: Array<SortDescriptor> = [
    { field: "pdName", dir: "asc" },
    { field: "ctPeriodFrom", dir: "asc" },
    { field: "ctPeriodTo", dir: "asc" },
];
const nomalUserInfoObject = getNomalUserInfoFromStorage();

const Test = () => {
    const [sort, setSort] = useState(initialSort);

    // cId를 인자로 전달
    const licenseData = useFetchLicenseData(nomalUserInfoObject?.cId); 

    return (
        <div className="single-item item">
            <div className="info">
                <h3><a>테스트 공간</a></h3>
                {/* 시작 대시보드*/}
                <Grid
                    data={orderBy(licenseData, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e: GridSortChangeEvent) => {
                        setSort(e.sort);
                        console.log('GridSortChangeEvent', e);
                    }}
                    resizable={true}
                    style={{ height: '100%' }}
                >
                    {/* 데이터없을때 나오는 문구 */}
                    <GridNoRecords>라이선스 선택해주세요.</GridNoRecords>
                    <Column title='라이선스명' field='pdName' cell={(props) => {
                        const pdName = props.dataItem.pdName
                        return (
                            <td style={{ textAlign: 'center' }} >{pdName}</td>
                        );
                    }} />
                    <Column title='시작일' field='ctPeriodFrom' cell={(props) => {
                        const ctPeriodFrom = props.dataItem.ctPeriodFrom
                        return (
                            <td style={{ textAlign: 'center' }} >{ctPeriodFrom}</td>
                        );
                    }} />
                    <Column title='종료일' field='ctPeriodTo' cell={(props) => {
                        const ctPeriodTo = props.dataItem.ctPeriodTo
                        return (
                            <td style={{ textAlign: 'center' }} >{ctPeriodTo}</td>
                        );
                    }} />
                </Grid>
                <br/>
                {nomalUserInfoObject.cId}
                <br/>
                {JSON.stringify(nomalUserInfoObject)}
                <br/>
                {JSON.stringify(licenseData)}
                {/* 끝 대시보드*/}
            </div>
        </div>
    );
}

export default Test;
