// 대용량 유저등록용 엑셀파일 함수
import axios from 'axios';
import { clientConfig } from '../api/clientConfig';
import { getUserInfoFromStorage } from './userInfoModule';
// 세선스토리지에서 정보가져오기
const userInfoObject = getUserInfoFromStorage()

// 중복값 지정
interface ExcelFileUploadResponse {
    duplicateIds?: string;
    duplicateEmails?: string;
  }

  
// 엑셀파일 업로드
// export const excelFileUpload = async (file: string | Blob) => {
// export const excelFileUpload = async (file: File) => {
    export const excelFileUpload = async (file: File): Promise<ExcelFileUploadResponse> => {
    try {
        const formData = new FormData();
        formData.append('excelFile', file);
        
        console.log('userInfoObject: ', userInfoObject.cId);

        console.log('file.namefile.name : ', file.name);

        const response = await axios.post(`${clientConfig.URL}/excelFileUpload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                cId: userInfoObject.cId,
            },
        });
        console.log('excelFileUpload response : ', response); // 서버에서 반환한 메시지 출력
        console.log('중복 아이디 : ', response.data.duplicateIdsA); // 서버에서 반환한 메시지 출력
        console.log('중복 이메일 : ', response.data.duplicateEmailsA); // 서버에서 반환한 메시지 출력

        // 업로드 성공시 중복 아이디 반환
        return {
            duplicateIds: response.data.duplicateIdsA,
            duplicateEmails: response.data.duplicateEmailsA,
        };
        
        // 업로드 성공시 추가적인 로직 수행 가능
    } catch (error: any) {
        console.error('Error excelFileUpload file:', error);
        // console.log('error메시지duplicateIds', error.data.duplicateIds);
        // consolelog('error메시지duplicateEmails', error.data.duplicateEmails);
        throw error;
    }
};



// 엑셀양식 파일 다운로드
// export const excelFileDownload = async (fileName: string) => {
export const excelFileDownload = async () => {
    try {
        const response = await axios.post(`${clientConfig.URL}/excelFileDownload`,
            // { fileName },
            {},
            { responseType: 'blob' }
        );
        console.log('엑셀 response : ', response);
        // 파일 다운로드 로직 추가
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', fileName);
        // 다운로드할 파일 이름 설정
        link.setAttribute('download', 'UserTemplate.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.error('Error while excelDownloading file:', error);
        // 에러 처리 로직 추가
    }

}

