import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { getUserInfoFromStorage } from '../../utils/userInfoModule'
import { clientConfig } from '../../api/clientConfig'
import axios from 'axios'
import { Grid, GridColumn as Column, GridRowClickEvent } from '@progress/kendo-react-grid';
import Popup from '../../Components/Popup/Popup';
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs'
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, DropDownList } from '@progress/kendo-react-dropdowns'
import { ExternalDropZone, Upload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload'
import DialogForm from '../../Components/Dialog/DialogForm'

// ExternalDropZone, Upload 함수
const uploadRef = React.createRef<Upload>();
// 현재 년도 가져오기
const currentYear = new Date().getFullYear();

// 제조일자
const years = Array.from({ length: 6 }, (v, i) => {
  const year = 2025 - i; // 현재 년도부터 ~ 년 전까지의 연도를 가져옴
  return { id: `item${year}`, text: `${year}` };
});


// 솔루션 데이터 타입 선언
interface MasterProductInfoProps {
  pdId: string,
  pdCode: string,
  pdName: string,
  pdVersion: string,
  pdFile: string,
  pdDescription: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdImage: string,
  pdUse: string,
  pdUpateDate: string,
  dsId: string,
  pdHtml: string,
  pdManual: string,
  pfId: string,
  pdVersionYear: string,
  dmId: string,
  dsName: string,
  dsValue: string,
  dsUse: string,
  dsDescription: string,
  dmCode: string,
  dmName: string,
  dmUse: string,
  dmDescription: string,
}

interface MasterProductInfoProps2 {
  dsId: string,
  dmId: string,
  dsName: string,
  dsValue: string,
  dsUse: string,
  dsDescription: string,
}
// 솔루션 데이터 adm_product_file 타입 선언
interface MasterProductYearInfoProps {
  pfId: string,
  pdId: string,
  pdFile: string,
  pdDescription: string,
  pdVersionYear: string,
  pdUse: string,
  pdUpateDate: string,
}

// 솔루션 제품 페이지 설정
interface MasterProductManagementProps {
  handlePageChange: (pageNumber: number) => void;
  onProductEdit: (product: MasterProductInfoProps) => void; // 클릭된 제품 정보를 외부로 내보내기 위한 함수
}

interface MasterProductManagementResistProps {
  handlePageChange: (pageNumber: number) => void;
  selectedOnProductEdit: Array<MasterProductInfoProps>;
}
// 제품 설명서 타입설정
interface FileListProps {
  apf_pfId: string,
  apf_pdFile: string,
  apf_pdDescription: string,
  apf_pdVersionYear: string,
  apf_pdUse: string,
  apf_pdUpateDate: string,
  pdId: string,
  pdCode: string,
  pdName: string,
  pdFile: string,
  pdVersion: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdImage: string,
  dsId: string,
  pdHtml: string,
  pdManual: string,
}

// 연도불러오기 타입
interface comboBoxYearProps {
  dsId: string,
  dmId: string,
  dsName: string,
  dsValue: string,
  dsUse: string,
  dsDescription: string,
}

// 솔루션 제품 목록 페이지
export const Master_Product_Management: React.FC<MasterProductManagementProps> = ({ handlePageChange, onProductEdit }) => {
  console.log(' --- Master_Product_Management 동작감지 --- ');

  // 솔루션 제품 정보
  const [masterProductInfo, setMasterProductInfo] = useState<Array<MasterProductInfoProps>>([])
  console.log('masterProductInfo22 : ', masterProductInfo);
  // 솔루션 제품년도 정보
  // const [masterProductYearInfo, setMasterProductYearInfo] = useState([])
  const [masterProductYearInfo, setMasterProductYearInfo] = useState<Array<MasterProductYearInfoProps>>([])
  console.log('masterProductYearInfo : ', masterProductYearInfo);
  // 다운로드 받을 제품년도 상태 관리
  // const [productYearSelected, setProductYearSelected] = useState<string>('')
  const [productYearSelected, setProductYearSelected] = useState<string[]>(Array(masterProductInfo.length).fill(''));
  console.log('productYearSelected : ', productYearSelected);
  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };

  // 솔루션 관리자 제품목록 불러오기
  const getMasterProductInfo = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getMasterProductInfo`, {
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        console.log('getMasterProductInfo : ', response.data);
        setMasterProductInfo(response.data);
      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getMasterProductInfo();
  }, []);

  // 솔루션 관리자 제품년도 불러오기
  const getMasterProductYearInfo = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getProductYear`, {

      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        console.log('getProductYear : ', response.data);
        setMasterProductYearInfo(response.data);
      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getMasterProductYearInfo();
  }, [setProductYearSelected]);


  // 솔루션 관리자 제품 링크 선택 이벤트
  const onHandleLinkORDownload = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pdName: string,
    pdFile: string,
    pdCode: string,
    pdId: string,
    index: number
  ) => {
    e.preventDefault();
    console.log(productYearSelected[index], pdCode);
    if (!productYearSelected[index]) {
      setPopupMessage('버전을 선택해주세요')
      setShowPopup(true)
      return false;
    }

    try {
      const response = await axios.post(`${clientConfig.URL}/Masterdownload`, {
        pdVersionYear: productYearSelected[index],
        pdCode: pdCode,
        pdId: pdId
      }, {
        responseType: 'blob'
      });

      if (response.status === 200) {
        console.log(response);
        console.log(response.data);
        const blob = response.data;
        // const fileName = response.data.pdFile;
        // console.log('fileName : ', fileName)
        // console.log('fileName2 : ', JSON.parse(await response.data.text()).pdFile)
        // console.log('fileName2 : ', JSON.parse(response.data.text()))

        // 파일 다운로드를 위해 <a> 엘리먼트를 생성하고 클릭하는 방법
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pdName); // 다운로드할 파일 이름 설정
        document.body.appendChild(link);
        link.click();
        // 다운로드 후 URL 객체 및 링크 제거
        window.URL.revokeObjectURL(url);
        link.parentNode?.removeChild(link);
        // // 파일 이름을 JSON 형식에서 추출
        // const fileName = JSON.parse(await response.data.text()).pdFile;
        // console.log('파일 이름:', fileName);

        // // 파일 다운로드 성공 시 브라우저가 새 창에서 파일을 엽니다.
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // console.log('Blob 객체 URL:', url);
        // const a = document.createElement('a');

        // a.href = url;
        // a.download = fileName.trim(); // 파일 이름 설정
        // document.body.appendChild(a);
        // console.log('다운로드 링크:', a);
        // a.click();
        // window.URL.revokeObjectURL(url);

      } else {
        // 파일이 존재하지 않을 경우 404 에러 메시지를 출력합니다.
        console.error('Error while downloading file:', response.data.message);
      }
    } catch (error) {
      console.error('Error while downloading file:', error);
    }
  };

  // 제품 매뉴얼 다운로드받기
  const onHandleMaunalDownload = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pdManual: string,
    pdCode: string,
    pdId: string,
    index: number
  ) => {
    e.preventDefault();
    // console.log(pdManual, productYearSelected[index], pdCode);
    // console.log(e);
    // if (!productYearSelected[index]) {
    //   setPopupMessage('버전을 선택해주세요')
    //   setShowPopup(true)
    //   return false;
    // }

    try {
      const response = await axios.post(`${clientConfig.URL}/MasterManualdownload`, {
        pdManual: pdManual,
        // pdVersionYear: productYearSelected[index],
        pdCode: pdCode,
        pdId: pdId
      }, {
        responseType: 'blob'
      });

      if (response.status === 200) {
        console.log('Product_MasterManualdownload : ', response);
        // 응답으로부터 파일 다운로드
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pdManual); // 빈 문자열로 설정하여 파일명을 서버에서 지정한 이름 그대로 사용
        document.body.appendChild(link);
        link.click();

        // 다운로드 후 URL 객체 및 링크 제거
        window.URL.revokeObjectURL(url);
        link.parentNode?.removeChild(link);
        // // 파일 이름을 JSON 형식에서 추출
        // const fileName = JSON.parse(await response.data.text()).fileName;
        // console.log(fileName);

        // // 파일 다운로드 성공 시 브라우저가 새 창에서 파일을 엽니다.
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const a = document.createElement('a');

        // a.href = url;
        // a.download = fileName.trim(); // 파일 이름 설정
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
      } else {
        // 파일이 존재하지 않을 경우 404 에러 메시지를 출력합니다.
        console.error('Error while downloading file:', response.data.message);
      }
    } catch (error) {
      console.error('Error while downloading file:', error);
    }
  };


  // 제품 수정 버튼 클릭 함수
  // const handleProductEdit = (item : MasterProductInfoProps) => {
  //   console.log(item);
  // };
  const handleProductEdit = (index: number) => {
    // 클릭된 제품의 인덱스를 기반으로 해당 제품 정보를 가져오는 로직을 작성합니다.
    // 예를 들어, Master_Product_Management 컴포넌트에서는 masterProductInfo 배열을 사용하여 인덱스에 해당하는 제품 정보를 가져올 수 있습니다.
    const clickedProduct = masterProductInfo[index];
    // 내보낸 정보를 부모 컴포넌트로 전달합니다.
    onProductEdit(clickedProduct);
    // 가져온 제품 정보를 이용하여 필요한 작업을 수행합니다.
    // 예를 들어, 모달을 열거나 다른 페이지로 이동하여 해당 제품 정보를 보여주는 작업을 수행할 수 있습니다.
    console.log("Clicked product information:", clickedProduct);
    // 이후 필요한 작업을 추가로 구현합니다.
    handlePageChange(4)
  };


  // 제품등록페이지 이동
  const insertProductData = () => {
    handlePageChange(5)

  }

  return (
    <div className="single-item item">
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <div className="superadmin_product_list">
        <div className="superadmin_product_list_title">제품 관리</div>
        <div className="superadmin_product_list_bt_pack">
          <div className="superadmin_product_list_bt_margin">
            <a style={{ cursor: 'pointer' }} onClick={insertProductData}
              // href="master_license_management_product_management_resist.html"
              className="btn btn-theme border btn-sm"
              data-animation="animated slideInUp"
            >
              제품 등록
            </a>
          </div>
        </div>
      </div>
      {/* 한개의 제품 시작 */}
      {/* 등록된 배경이미지가 카드의 배경으로 처리됩니다 */}
      {masterProductInfo.map((item: MasterProductInfoProps, index) => (
        <div
          className="info_product_list_card"
          style={{
            // backgroundImage: 'url("assets/img/product_list_card_01.jpg")'
            // backgroundImage: `url(${clientConfig.URL}/product/${item.pdCode}/${item.pdVersionYear}/${item.pdIcon})`
          }}
          key={index}
        >
          <div className="info_product_list_card_dark">
            <div className="info_product_list_card_contents">
              {/* <div class="info_product_list_card_contents_edit">제품<br>수정</div> */}
              <div className="info_product_list_card_contents_version_type">
                <div className="info_product_list_card_contents_version_title">
                  {/* Version<span>0.74</span> */}
                  Version<span>{item.pdVersion}</span>
                </div>
                {item.pdUse === 'N' && <a className='innoservice_solution_stop' >(서비스 지원 중지)</a>}
                {/* 모바일 */}
                {item.pdType === 'M' && (
                  <div className="info_product_list_card_contents_type_pack">
                    <div className="superadmin_product_list_contents_pd_type_m_list">
                      <a style={{ cursor: 'pointer' }}>Mobile 지원</a>
                    </div>
                    <div className="superadmin_product_list_contents_pd_type_w_list_deactive">
                      <a style={{ cursor: 'pointer' }}>Web 지원</a>
                    </div>
                    <div className="superadmin_product_list_contents_pd_type_c_list_deactive">
                      <a style={{ cursor: 'pointer' }}>CS 지원</a>
                    </div>
                  </div>
                )}
                {/* 웹 */}
                {item.pdType === 'W' && (
                  <div className="info_product_list_card_contents_type_pack">
                    <div className="superadmin_product_list_contents_pd_type_m_list_deactive">
                      <a style={{ cursor: 'pointer' }}>Mobile 지원</a>
                    </div>
                    <div className="superadmin_product_list_contents_pd_type_w_list">
                      <a style={{ cursor: 'pointer' }}>Web 지원</a>
                    </div>
                    <div className="superadmin_product_list_contents_pd_type_c_list_deactive">
                      <a style={{ cursor: 'pointer' }}>CS 지원</a>
                    </div>
                  </div>
                )}
                {/* 데스크톱 */}
                {item.pdType === 'D' && (
                  <div className="info_product_list_card_contents_type_pack">
                    <div className="superadmin_product_list_contents_pd_type_m_list_deactive">
                      <a style={{ cursor: 'pointer' }}>Mobile 지원</a>
                    </div>
                    <div className="superadmin_product_list_contents_pd_type_w_list_deactive">
                      <a style={{ cursor: 'pointer' }}>Web 지원</a>
                    </div>
                    <div className="superadmin_product_list_contents_pd_type_c_list">
                      <a style={{ cursor: 'pointer' }}>CS 지원</a>
                    </div>
                  </div>
                )}
              </div>
              <div className="info_product_list_card_contents_icon_producttitle">
                {/* 등록된 아이콘이 보여지는 곳 */}
                <div className="info_product_list_card_contents_icon_area">
                  <div
                    className="info_product_list_card_contents_icon"
                  // style={{
                  //   // backgroundImage: `url(${clientConfig.URL}/image/${item.pdImage})`
                  //   backgroundImage: `url(${clientConfig.URL}/product/${item.pdCode}/${item.pdVersionYear}/${item.pdIcon})`
                  // }}
                  >
                    {/* 제품 년도버전 경로에 맞는 */}
                    {/* {<img src={`${clientConfig.URL}/product/${item.pdCode}/${item.pdVersionYear}/${item.pdIcon}`} />} */}
                    {/* 제품 아이콘이없으면 기본 이미지 출력 */}
                    {item && item.pdIcon ? (
                      // <img src={`${clientConfig.URL}/product/${item.pdCode}/${item.pdVersionYear}/${item.pdIcon}`} />
                      // <img src={`${clientConfig.URL}/product/${item.pdCode}/${item.pdVersionYear}/${item.pdIcon}`} />
                      <img src={`${clientConfig.URL}/product/${item.pdCode}/${item.pdIcon}`} />
                    ) : (
                      <img src={`${clientConfig.URL}/image/default_product_image.jpg`} />
                    )}

                    <div className="info_product_list_card_contents_edit">
                      {/* <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(4)}>제품 수정</a> */}
                      <a style={{ cursor: 'pointer' }} onClick={() => handleProductEdit(index)}>제품 수정</a>
                    </div>
                  </div>
                </div>
                {/* 제품의 명칭과 등록 또는 수정했을때의 업데이트 날짜는 갱신 */}
                <div className="info_product_list_card_contents_producttitle_area">
                  <div className="info_product_list_card_contents_producttitle_main">
                    {/* InnoM3D */}
                    {item.pdName}
                    <div className="info_product_list_card_contents_producttitle_sub_update_area">
                      <div className="info_product_list_card_contents_producttitle_sub_update">
                        {/* 업데이트 날짜 : 2024년 2월 9일 */}
                        업데이트 날짜
                      </div>
                      <div className="info_product_list_card_contents_producttitle_sub_download">
                        <a>{item.pdUpateDate?.substring(0, 10)}</a>
                      </div>
                    </div>
                  </div>
                  {/* 제품의 부가 설명이 들어가는 곳입니다 */}
                  <div className="info_product_list_card_contents_producttitle_sub">
                    {item.pdDescription}
                  </div>
                </div>
              </div>
              {/* 제품 상세 내용이며 등록자가 html로 저장한 페이지가 새창으로 떠서 별도로 확인이 가능하다 */}
              <div className="info_product_list_card_contents_download_detail_area">
                {item.pdType != 'D' ? (
                  <div className="info_product_list_card_contents_download_detail">
                    {item.pdManual && (
                      <a
                        onClick={(e) => onHandleMaunalDownload(e, item.pdManual, item.pdCode, item.pdId, index)}
                        style={{ cursor: 'pointer' }}
                        key={index}
                      >
                        Manual Download
                      </a>
                    )}
                    {/* <a href={item.pdFile} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}> */}
                    <a href={item.pdFile.startsWith('http') ? item.pdFile : `http://${item.pdFile}`} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                      Link
                    </a>
                  </div>
                ) : (
                  <div className="info_product_list_card_contents_download_detail">
                    {item.pdManual && (
                      <a
                        onClick={(e) => onHandleMaunalDownload(e, item.pdManual, item.pdCode, item.pdId, index)}
                        style={{ cursor: 'pointer' }}
                        key={index}
                      >
                        Manual Download
                      </a>
                    )}
                    {/* <a>{item.pdVersionYear}</a> */}
                    <div className='innoservice_solution_version_type_years'>
                      <DropDownList style={{ borderRadius: '8px', maxHeight: '40px', textAlignLast: 'center', }}
                        // 일치하는 제품 다운로드
                        data={masterProductYearInfo
                          .filter(yearItem => yearItem.pdId === item.pdId)
                          .map(yearItem => yearItem.pdVersionYear)}
                        // defaultValue={masterProductYearInfo
                        //   .filter(yearItem => yearItem.pdId === item.pdId)
                        //   .map(yearItem => yearItem.pdVersionYear)[0]}
                        defaultValue={'버전을 선택해주세요'}
                        // onOpen={(event)=>console.log(event)}
                        // onChange={(event)=>console.log(event, index)}
                        // onChange={(event)=>setProductYearSelected(event.value)}
                        onChange={(event) => {
                          // productYearSelected를 복제하여 새 배열을 생성하고, 해당 index의 값을 변경합니다.
                          const newSelected = [...productYearSelected];
                          newSelected[index] = event.value; // 현재 index에 선택된 값 설정
                          setProductYearSelected(newSelected); // 변경된 배열을 상태로 설정합니다.
                        }}
                      />
                    </div>
                    <a
                      onClick={(e) => onHandleLinkORDownload(e, item.pdName, item.pdFile, item.pdCode, item.pdId, index)}
                      style={{ cursor: 'pointer' }}
                      key={index}
                    >
                      Download
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* 한개의 제품 끝 */}
    </div>
  )
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// 제품 수정 페이지
// export const Master_Product_Management_Resist = () => {
export const Master_Product_Management_Resist: React.FC<MasterProductManagementResistProps> = ({ handlePageChange, selectedOnProductEdit }) => {
  console.log('Master_Product_Management_Resist 동작감지');
  // 제품 데이터 상태 관리
  const [masterProductInfo, setMasterProductInfo] = useState<Array<MasterProductInfoProps>>([])
  // 제조사 데이터 상태 관리
  const [masterProductInfo2, setMasterProductInfo2] = useState<Array<MasterProductInfoProps2>>([])
  // 수정 제품 데이터 받아옴
  const [OnProductEditData] = useState(selectedOnProductEdit)


  // 제조사 데이터 관리
  const [selectedValue, setSelectedValue] = useState(selectedOnProductEdit[0]?.dsName || '');
  const [editValue, setEditValue] = useState(selectedOnProductEdit[0]?.dsName || '');
  const [editValueOut, setEditValueOut] = useState(selectedOnProductEdit[0]?.dsName || '');

  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };

  // 다이어로그창 열림여부 상태 관리
  const [isDialogOpen, setDialogOpen] = useState(false);
  // 다이어로그창의 내부 메세지1 출력 상태 관리
  const [dialogMessage, setDialogMessage] = useState('');
  // 다이어로그창의 내부 메세지2 출력 상태 관리
  const [dialogMessage2, setDialogMessage2] = useState('');
  // 다이어로그창의 내부 메세지3 출력 상태 관리
  const [dialogMessage3, setDialogMessage3] = useState('');

  // 다이어로그창 열림 관리
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  // 다이어로그창 닫힘 관리
  const handleCloseDialog = () => {
    if (OnProductEditData[0]?.pdType === 'W') {
      handleSupportClick('web')
      getSupportStyle('web')
    }
    if (OnProductEditData[0]?.pdType === 'D') {
      handleSupportClick('cs')
      getSupportStyle('cs')
    }
    if (OnProductEditData[0]?.pdType === 'M') {
      handleSupportClick('mobile')
      getSupportStyle('mobile')
    }
    // 다이어로그창 닫기 이벤트
    setDialogOpen(false);

    // console.log('자');
  };
  // 사용자가 대화상자를 확인
  const handleConformDialog = () => {

    axios.post(`${clientConfig.URL}/editPdTypeWheEdit`, {
      pdType: dialogProductTypeValue,
      pdId: selectedOnProductEdit[0]?.pdId,
      pdCode: selectedOnProductEdit[0]?.pdCode
    })
      .then(function (response) {
        console.log('타입변경 : ', response);
        getFileList()
        setDialogOpen(false);

      })
      .catch(function (error) {
        // 요청이 실패했을 때 수행할 작업
        console.error(error);
      });
    // 다이어로그 닫기 이벤트
    setDialogOpen(false);
    getFileList()

  };




  // 제품 구분 상태 관리
  const [selectedSupport, setSelectedSupport] = useState('');
  // 제조일자 관리
  const defaultYear = selectedOnProductEdit[0]?.pdVersionYear || years[0].text;
  // 제조일자 텍스트 아이디 일치하도록 변환
  const selectedYearObj = years.find(year => year.text === defaultYear) || years[0];
  // console.log(selectedYearObj);
  const [selectedYear, setSelectedYear] = React.useState(selectedYearObj);
  // console.log(selectedYear)
  // console.log(selectedYear.text)

  // 상세설명 상태 관리
  const [textAreaValue, setTextAreaValue] = useState('')
  // console.log(textAreaValue);
  // 버전 상태 관리
  const [inputVersion, setInputVersion] = useState(selectedOnProductEdit[0]?.pdVersion || '')
  // console.log(inputVersion);
  // 제품이름 상태 관리
  const [inputProductName, setInputProductName] = useState(selectedOnProductEdit[0]?.pdName || '')
  // console.log(inputProductName);
  // 제품이름 상태 관리
  const [inputLink, setInputLink] = useState(selectedOnProductEdit[0]?.pdFile || '')
  console.log('inputLink : ', inputLink);
  // 제품메뉴얼, 버전별파일
  const [fileList, setFileList] = useState<Array<FileListProps>>([])
  // 연도 상태 관리
  const [comboBoxYear, setComboBoxYear] = useState<Array<comboBoxYearProps>>([])
  console.log('comboBoxYear : ', comboBoxYear);

  // 제품 사용 유무 상태 관리
  const [isChecked, setIsChecked] = useState(selectedOnProductEdit[0]?.pdUse === 'N');
  // 다이어로그창에서 확인 눌러 업데이트할 제품타입 값 상태 관리
  const [dialogProductTypeValue, setDialogProductTypeValue] = useState('')
  console.log('dialogProductTypeValue : ', dialogProductTypeValue)
  // 제조일자 상태 감지
  const handleYearChange = (event: ComboBoxChangeEvent) => {
    console.log('handleYearChange event : ', event);
    if (event.target.value === null) {
      setPopupMessage('정확한 년도를 선택해주세요.')
      setShowPopup(true)
      return false;
    }
    setSelectedYear(event.target.value);
  };

  // // 제조사 입력값
  // const [filterText, setFilterText] = useState('');
  // const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
  //   console.log('handleFilterChange: ', event);
  //   setFilterText(event.filter.value)
  //   console.log('handleFilterChange : ', event.filter.value);
  // };


  // 솔루션 관리자 제품목록 불러오기
  const getMasterProductInfo = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getMasterProductInfo`, {
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        // console.log(response.data);
        setMasterProductInfo(response.data);
      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };
  useEffect(() => {
    getMasterProductInfo();
  }, []);

  const getMasterProductInfo2 = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getMasterProductInfo2`, {
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setMasterProductInfo2(response.data);

      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getMasterProductInfo2();
  }, []);



  // 제품 설명서, 파일 정보 가져오기
  const getFileList = async () => {
    console.log('getFileList : ', selectedOnProductEdit[0]?.pdId);
    try {
      const response = await axios.post(`${clientConfig.URL}/getFileList`, {
        pdId: selectedOnProductEdit[0]?.pdId
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        console.log('getFileList : ', response.data);
        setFileList(response.data);

      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getFileList();
  }, []);


  // 연도 가져오기
  const getComboBoxYear = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getComboBoxYear`, {
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setComboBoxYear(response.data);
      }
    } catch (error) {
      console.error('Error getComboBoxYear fetchData:', error);
    }
  };
  // 연도 가져오기
  useEffect(() => {
    getComboBoxYear();
  }, [selectedOnProductEdit]);




  // 제품 클릭 및 다른 제품선택시 상태 변경
  const handleSupportClick = (supportType: string) => {
    // let asd
    // if (selectedOnProductEdit[0]?.pdType === 'W' ){
    //   asd = 'web'
    // } else if (selectedOnProductEdit[0]?.pdType === 'D' ){
    //   asd = 'cs'

    // }else if(selectedOnProductEdit[0]?.pdType === 'M' ) {
    //   asd = 'mobile'
    // } else return
    // console.log('asd : ', asd)

    // if ((supportType === 'cs') && (asd !== supportType)) {
    //     setPopupMessage('다름')
    //     setShowPopup(true)
    // }
    // console.log('asd : ', asd)
    // console.log('fileList[0]?.pdType : ', fileList[0]?.pdType)
    // console.log('selectedOnProductEdit[0]?.pdType : ', selectedOnProductEdit[0]?.pdType)
    // console.log('supportType : ', supportType);

    setSelectedSupport((prevSupport) => (prevSupport === supportType ? "" : supportType));

  };

  // 선택한 버튼의 스타일 변경
  const getSupportStyle = (supportType: string) => {
    switch (supportType) {
      case "mobile":
        return {
          color: "#ffffff",
          background: "linear-gradient(135deg, rgba(48,28,96,1) 0%, rgba(130,107,151,1) 100%)"
        };
      case "web":
        return {
          color: "#ffffff",
          background: "linear-gradient(135deg, rgba(28,60,96,1) 0%, rgba(107,128,151,1) 100%)"
        };
      case "cs":
        return {
          color: "#ffffff",
          background: "linear-gradient(135deg, rgba(31,96,28,1) 0%, rgba(107,151,124,1) 100%)"
        };
      default:
        return {};
    }
  };

  // 제품수정선택시 나오는 제품지원 미리 선택 
  useEffect(() => {
    if (OnProductEditData[0]?.pdType === 'W') {
      handleSupportClick('web')
      getSupportStyle('web')
    }
    if (OnProductEditData[0]?.pdType === 'D') {
      handleSupportClick('cs')
      getSupportStyle('cs')
    }
    if (OnProductEditData[0]?.pdType === 'M') {
      handleSupportClick('mobile')
      getSupportStyle('mobile')
    }
  }, []);

  // 제품유형 변경처리
  const setProductType = (supportType: string) => {
    // 다이어로그에 확인누르면 업데이트 할 제품타입 저장
    setDialogProductTypeValue(supportType)

    if ((fileList[0]?.pdType === 'D') && (supportType === 'web' || supportType === 'mobile')) {
      // 다이어로그창 열기
      handleOpenDialog();
      setDialogMessage('유형을 변경하시면 기존의 파일이 삭제됩니다. 유형을 변경하시겠습니까?');
      return
    }
    // handleSupportClick(selectedOnProductEdit[0]?.pdType)


    if ((fileList[0]?.pdType === 'W' || fileList[0]?.pdType === 'M') && (supportType === 'cs')) {
      handleOpenDialog();
      setDialogMessage('유형을 변경하시면 기존의 URL이 삭제됩니다. 유형을 변경하시겠습니까?');
      return
    }


    axios.post(`${clientConfig.URL}/editPdType`, {
      pdType: supportType,
      pdId: fileList[0]?.pdId
    })
      .then(function (response) {
        console.log('타입변경 : ', response);
        getFileList()

      })
      .catch(function (error) {
        // 요청이 실패했을 때 수행할 작업
        console.error(error);
      });

    // 리랜더링
    getFileList()

  }




  // 제조사 입력값 감지
  const handleOnChange = (event: ComboBoxChangeEvent) => {

    setSelectedValue(event.target.value)
    // 선택한 수정할 값
    if (event.nativeEvent instanceof PointerEvent) {
      console.log('선택이벤트 : ', event.target.value);
      setEditValueOut(event.target.value)
    }
    // 수정할값에 업데이트할 값
    if (event.nativeEvent instanceof FocusEvent) {
      setEditValue(event.target.value)
      console.log('아웃이벤트 : ', event.target.value);
    }
    // DB변경
    axios.post(`${clientConfig.URL}/editDBMakerName`, {
      pdId: selectedOnProductEdit[0]?.pdId,
      // dsId: selectedOnProductEdit[0]?.dsId,
      dsName: event.target.value,
    })
      .then(function (response) {
        console.log('제조사 변경 : ', response);
      })
      .catch(function (error) {
        // 요청이 실패했을 때 수행할 작업
        console.error(error);
      });
  }

  // 제조사 추가
  const insertMaker = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/insertMakerInfo`, {
        textValue: selectedValue // 선택한 값 전송
        // filterText // 선택한 값 전송
      });
      // console.log('2insertMakerinsertMaker : ', selectedValue);

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('추가하였습니다.');
        setShowPopup(true);
      } else {
        // 삽입 실패 시 중복되었다는 메시지 표시
        setPopupMessage('회사명이 이미 존재합니다');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error inserting maker:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제조사 데이터 불러오기
    getMasterProductInfo2()
  };


  const editMaker = async () => {
    console.log('수정할 대상값 : ', editValueOut);//Original
    console.log('업데이트 값 : ', editValue);//Target
    if (!editValue) {
      setPopupMessage('수정할 값을 입력해주세요.');
      setShowPopup(true);
      return false
    }
    try {
      const response = await axios.post(`${clientConfig.URL}/updateMakerInfo`, {
        textValue: editValueOut,
        textValue2: editValue
      });
      console.log('editMaker : ', selectedValue);

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('수정하였습니다.');
        setShowPopup(true);
      } else {
        setPopupMessage('회사명이 이미 존재합니다.');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error inserting maker:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제조사 데이터 불러오기
    getMasterProductInfo2()
    // 선택값 초기화
    //setEditValue('')
    //setEditValueOut('')
  }


  // 아이콘 업로드 성공 후 
  const handleUploadImage = (event: UploadOnStatusChangeEvent) => {
    // console.log('event', event);
    if (event.response) {
      if (event.response.status == 200) {
        // console.log('event.response.status', event.response.status);
        // console.log('이미지 업로드');
        setPopupMessage("아이콘을 등록했습니다.");
        setShowPopup(true);
        getMasterProductInfo();
        getMasterProductInfo2();
        getFileList()
      }
      else {
        console.log('이미지 업로드 실패');
        // setPopupMessage("사진 업로드 실패");
        // setShowPopup(true);
      }
    }
  }
  // 메뉴얼 업로드 확인
  const handleUploadManual = (event: UploadOnStatusChangeEvent) => {
    console.log('event', event);
    if (event.response) {
      if (event.response.status == 200) {
        // console.log('event.response.status', event.response.status);
        // console.log('이미지 업로드');
        setPopupMessage("매뉴얼 수정했습니다.");
        setShowPopup(true);

        // 제품 설명서, 파일불러오기
        getFileList()
      }
      else {
        console.log('매뉴얼 업로드 실패');
        // setPopupMessage("사진 업로드 실패");
        // setShowPopup(true);
      }
    }
  }

  // 제품파일 업로드 확인
  const handleUploadProduct = (event: UploadOnStatusChangeEvent) => {
    console.log('event', event);
    if (event.response) {
      if (event.response.status == 200) {
        // console.log('event.response.status', event.response.status);
        // console.log('이미지 업로드');
        setPopupMessage("제품 파일을 업로드했습니다.");
        setShowPopup(true);
      }
      else {
        console.log('이미지 업로드 실패');

      }
    }
    // 제품 설명서, 파일불러오기
    getFileList();
  }

  // 모든 제품 정보 변경 
  const editAllProductInfo = async () => {
    if (!inputProductName) {
      setPopupMessage('수정할 이름을 적어주세요')
      setShowPopup(true)
      return false
    }
    if (!inputVersion) {
      setPopupMessage('수정할 버전을 적어주세요')
      setShowPopup(true)
      return false
    }
    try {
      const response = await axios.post(`${clientConfig.URL}/editAllProductInfo`, {
        pfId: selectedOnProductEdit[0]?.pfId,
        pdId: selectedOnProductEdit[0]?.pdId,
        pdDescription: textAreaValue,
        pdVersion: inputVersion,
        pdName: inputProductName,
        inputLink: inputLink
      });

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('수정 완료 하였습니다.');
        setShowPopup(true);
        setTimeout(() => {
          handlePageChange(3);
        }, 1000);
      } else {
        setPopupMessage('오류!.');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error inserting maker:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
  }

  // 매뉴얼 삭제
  const deleteManualWhenEdit = async () => {
    console.log('삭제감지');
    try {
      const response = await axios.post(`${clientConfig.URL}/deleteManualWhenEdit`, {
        pdId: selectedOnProductEdit[0]?.pdId,
        pdCode: selectedOnProductEdit[0]?.pdCode,
        pdManual: selectedOnProductEdit[0]?.pdManual,
      });

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('삭제 완료 하였습니다.');
        setShowPopup(true);
        console.log('삭제성공 : ', response.data);
        // 제품 설명서, 파일불러오기
        getFileList()
        // console.log('삭제이후 fileList : ', fileList);
      } else {
        console.log('오류');
      }
    } catch (error) {
      console.error('Error deleteManualWhenEdit:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제품 설명서, 파일불러오기
    getFileList();
  }

  // 수정중 파일 삭제
  const deleteFileWhenEdit = async (fileId: string, fileYear: string, fileCode: string) => {
    console.log('파일삭제감지', fileId, fileYear, fileCode);
    try {
      const response = await axios.post(`${clientConfig.URL}/deleteFileWhenEdit`, {
        pfId: fileId,
        apf_pdVersionYear: fileYear,
        pdCode: fileCode,
      });
      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('삭제 완료 하였습니다.');
        setShowPopup(true);
        console.log('삭제성공 : ', response.data);
      } else {
        console.log('오류');
      }
    } catch (error) {
      console.error('Error deleteManualWhenEdit:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제품 설명서, 파일불러오기
    getFileList();
  }


  //   const setProductDisable = () => {
  // console.log('pfId, pdId, pdUse', 
  // selectedOnProductEdit[0]?.pfId,
  // selectedOnProductEdit[0]?.pdId,
  // selectedOnProductEdit[0]?.pdUse,
  // )
  // }


  // 체크버튼으로 사용 유무 설정
  const setProductDisable = async () => {
    // 현재 상태의 반대값을 새로운 값으로 설정
    const newValue = isChecked ? 'Y' : 'N';
    // isChecked 상태를 토글
    setIsChecked(!isChecked);

    try {
      await axios.post(`${clientConfig.URL}/setProductDisable`, {
        pdId: selectedOnProductEdit[0]?.pdId,
        newValue: newValue // 새로운 값 전달
        // 다른 필요한 데이터 추가
      });
      if (newValue === "Y") {
        setPopupMessage("사용 중으로 바꿨습니다.")
        setShowPopup(true)
        // setTimeout(() => {
        //   handlePageChange(2)
        // }, 1000);
      } else {
        setPopupMessage("사용 중지 되었습니다.")
        setShowPopup(true)
        // setTimeout(() => {
        //   handlePageChange(2)
        // }, 1000);
      }
    } catch (error) {
      // 업데이트에 실패한 경우 에러를 처리
    }
  };



  return (<div className="single-item item">
    {/* DialogForm 컴포넌트 */}
    {isDialogOpen && (
      <DialogForm
        setTitle=" "
        message={dialogMessage}
        message2={dialogMessage2}
        message3={dialogMessage3}
        onClose={handleCloseDialog}
        onConform={handleConformDialog}
      />
    )}
    {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
    {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
    <div className="superadmin_product_list">
      {/* <div className="superadmin_product_list_title">제품 관리</div>
      <div className="superadmin_product_list_bt_pack">
        <div className="superadmin_product_list_bt_margin">
          <a
            // href="master_license_management_product_management_resist.html"
            aria-disabled
            className="btn btn-theme border btn-sm"
            data-animation="animated slideInUp"
          >
            제품 등록ㅇㅇ
          </a>
        </div>
      </div> */}
    </div>
    <div className="info">
      <div className="contact-form">
        <div className="superadmin_product_list_resister_area">
          <div className="superadmin_product_list_resister_title" />
          <div className="superadmin_product_list_bt_pack_pduse">

            <div className='superadmin_product_list_bt_pack_pduse_title' >
              사용 중지
            </div>

            <div className='superadmin_product_list_bt_pack_pduse_input' >
              {/* <input type="checkbox" onClick={()=>alert('사용 중지되었습니다')} style={{ minHeight: 25, minWidth: 25 }} /> */}
              <input type="checkbox" onClick={setProductDisable} checked={isChecked} />
            </div>
            
          </div>
        </div>

        <div className="superadmin_product_list_resister_area">
          <div className="superadmin_product_list_resister_title">
            제품 정보 수정
          </div>
          {/* {fileList[0]?.pdType} */}
          <div className="superadmin_product_list_bt_pack">
            <div className="superadmin_product_list_bt_margin">
              <a
                style={{ cursor: 'pointer' }}
                className="btn btn-theme border btn-sm"
                data-animation="animated slideInUp"
                onClick={editAllProductInfo}
              >
                수정 하기
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="meta">
        <a className="btn btn-theme border btn-sm" data-animation="animated slideInUp" style={{ cursor: "pointer" }}>사용 중지</a>
      </div> */}
      {/* 제품명, 제품 버전, 제품 구분을 입력 또는 선택하는 폼 */}
      <div className="meta">
        <div className="superadmin_product_list_contents">
          <div className="superadmin_product_list_contents_type_title">
            제품 구분
          </div>
          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_type">
              <div className="superadmin_product_list_contents_type_tag">
                <div className="superadmin_product_list_contents_pd_type_version">
                  <div className="superadmin_product_list_contents_pd_type_version_label">
                    버전입력
                  </div>
                  <input
                    type="text"
                    placeholder="버전입력 ex)1.23.4567"
                    className="superadmin_product_list_resist_version_input"
                    defaultValue={`${selectedOnProductEdit[0]?.pdVersion}`}
                    onChange={(event) => setInputVersion(event.target.value)}
                  />
                </div>
                {/* <div className="superadmin_product_list_contents_pd_type_m" onClick={(event)=>console.log('event', event)}> */}
                <div className="superadmin_product_list_contents_pd_type_m" onClick={() => setProductType("mobile")}>
                  <a style={{ cursor: 'pointer', ...(selectedSupport === "mobile" && getSupportStyle("mobile")) }} onClick={() => handleSupportClick("mobile")}>Mobile 지원</a>
                </div>
                {/* <div className="superadmin_product_list_contents_pd_type_w" onClick={(event)=>console.log('event2', event)}> */}
                <div className="superadmin_product_list_contents_pd_type_w" onClick={() => setProductType("web")}>
                  <a style={{ cursor: 'pointer', ...(selectedSupport === "web" && getSupportStyle("web")) }} onClick={() => handleSupportClick("web")}>Web 지원</a>
                </div>
                {/* <div className="superadmin_product_list_contents_pd_type_c" onClick={(event)=>console.log('event3', event)}> */}
                <div className="superadmin_product_list_contents_pd_type_c" onClick={() => setProductType("cs")}>
                  <a style={{ cursor: 'pointer', ...(selectedSupport === "cs" && getSupportStyle("cs")) }} onClick={() => handleSupportClick("cs")}>CS 지원</a>
                </div>
              </div>
            </div>
          </div>
          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_name_version_type_left">
              <div className="superadmin_product_list_contents_title">
                제품명
              </div>
              <input
                type="text"
                placeholder="제품명을 입력해주세요"
                className="superadmin_product_list_resist_input"
                defaultValue={`${selectedOnProductEdit[0]?.pdName}`}
                onChange={(event) => setInputProductName(event.target.value)}
              />
            </div>
            <div className="superadmin_product_list_contents_name_version_type_right">
              <div className="superadmin_product_list_contents_title">
                제조사
              </div>
              <div className="superadmin_product_list_contents_name_version_type_right_flex">
                {/* 제조사 선택 */}
                <ComboBox
                  style={{ borderRadius: '8px', height: '50px' }}
                  data={masterProductInfo2.map(item => item.dsName)}
                  defaultValue={`${selectedOnProductEdit[0]?.dsName}` || ''}
                  // value={selectedValue}
                  filterable={true}
                  // onFilterChange={handleFilterChange}
                  // 검색어 화면 남기기
                  allowCustom={true}
                  // 입력값이 변경될 때마다 상태 업데이트
                  // onChange={(event) => setSelectedValue(event.target.value)}
                  onChange={(event) => handleOnChange(event)}
                />
                <div className="superadmin_product_list_resist_input_typeB_icon">
                  <div className="superadmin_icon_pack" onClick={insertMaker}>
                    {/* 제조사 등록 */}
                    <i className="fa fa-plus" aria-hidden="false" />
                  </div>
                  <div className="superadmin_icon_pack" onClick={editMaker} >
                    <i className="fa fa-pencil-alt" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_name_version_type_single">
              <div className="superadmin_product_list_contents_title">
                제품 부가 설명
              </div>
              <textarea className="form-control_content"
                id="comments"
                name="comments"
                placeholder="제품의 부가 설명을 입력해주세요"
                defaultValue={`${selectedOnProductEdit[0]?.pdDescription}`}
              // style={{ height: '112px', width: '100%' }} 
              />
            </div>
          </div>
        </div>
      </div>
      <div className="superadmin_product_list_contents_upload_title">
        제품 아이콘
      </div>
      {/* 제품 아이콘, 배경, 제품 파일을 등록하는 폼 */}
      <div className="meta">
        <div className="superadmin_product_list_contents">
          <div className="superadmin_product_list_contents_upload_area">
            <div className="superadmin_product_list_contents_upload_area_left">
              <div className="superadmin_product_list_contents_upload_pack">
                {/* 제품아이콘 이미지없으면 기본이미지 출력하며 URL뒤에 날짜시간을 더해 데이터 변경시 리랜더링없이 출력가능 */}
                {fileList[0]?.pdIcon ? (
                  <img src={`${clientConfig.URL}/product/${fileList[0]?.pdCode}/${fileList[0]?.pdIcon}?${new Date().getTime()}`} style={{ height: '126px', width: '126px' }} />
                ) : (
                  <img src={`assets/img/only_icon_resist_info.png?${new Date().getTime()}`} />
                )}
              </div>
            </div>
            <div className="superadmin_product_list_contents_upload_area_right">
              <div className="superadmin_product_list_contents_upload_pack">
                <div className="file-upload">
                  <input
                    type="text"
                    className="upload-name"
                    defaultValue="이미지, 가로 세로 512px 이상"
                    disabled={true}
                  />
                  <div className="bulk_upload_BT">
                    {/* 제품 아이콘 수정 업로드 */}
                    <Upload
                      // 파일의 초기목록
                      // defaultFiles={[]}
                      multiple={false}
                      // cors 설정
                      withCredentials={false}
                      saveUrl={`${clientConfig.URL}/updateIconImage?pdCode=${selectedOnProductEdit[0]?.pdCode}&pdName=${selectedOnProductEdit[0]?.pdName}&pdId=${selectedOnProductEdit[0]?.pdId}&pdVersionYear=${selectedOnProductEdit[0]?.pdVersionYear}`}
                      onStatusChange={(event) => handleUploadImage(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="meta">
        <div className="superadmin_product_list_contents">
          {/* 제품 상세설명 */}
          <div className="superadmin_product_list_contents_title">
            제품 상세 설명
          </div>
          <textarea className="form-control_content"
            id="comments"
            name="comments"
            placeholder="제품의 부가 설명을 입력해주세요"
            defaultValue={selectedOnProductEdit[0]?.pdDescription}
            onChange={(event) => setTextAreaValue(event.target.value)}
          // style={{ height: '112px', width: '663px' }} 
          />

        </div>
      </div>
      {/* 활용 https://www.telerik.com/kendo-react-ui/components/dropdowns/dropdownlist/filtering/ */}
      <div className="superadmin_product_list_contents_name_version_type_border">
        <div className="superadmin_product_list_contents_upload_area">
          <div className="superadmin_product_list_contents_upload_pack_files">
            <div className="superadmin_product_list_contents_upload_title">
              제품 설명서
            </div>
            <div className="file-upload">
              {/* 메뉴얼 수정 업로드 */}
              <Upload
                // 파일의 초기목록
                // defaultFiles={[]}
                multiple={false}
                withCredentials={false}
                saveUrl={`${clientConfig.URL}/updateManual?pdCode=${selectedOnProductEdit[0]?.pdCode}&pdName=${selectedOnProductEdit[0]?.pdName}&pdId=${selectedOnProductEdit[0]?.pdId}`}
                onStatusChange={(event) => handleUploadManual(event)}
              />
              {/* <label htmlFor="ex_filename" className="bulk_upload_BT">
                파일 선택
              </label>
              <input type="file" id="ex_filename" className="upload-hidden" /> */}
            </div>
            <div className={`superadmin_product_list_contents_name_version_type_border ${fileList[0]?.pdManual ? '' : 'hidden'}`}>
              <div className="superadmin_product_list_contents_name_version_type_border_file_listup_block">
                <div className="superadmin_product_list_contents_name_version_type_border_file_listup">
                  <div>{fileList[0]?.pdManual}</div>
                  <div onClick={deleteManualWhenEdit} style={{ cursor: 'pointer', width: '30px', textAlign: 'center' }}>X</div>

                </div>
                {/* {folders.map((folder, index) => (
                  <div className="superadmin_product_list_contents_name_version_type_border_file_listup" key={index}>
                    {folder.startsWith(`${selectedOnProductEdit[0]?.pdName}_Manual`) && (
                      <>
                        <div>{folder}</div>
                        <div onClick={deleteManualWhenEdit} style={{ cursor: 'pointer', width: '30px', textAlign: 'center' }}>X</div>
                      </>
                    )}
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 다운로드제품이면 보여주고 아니면 url 입력 */}
      {fileList[0]?.pdType == 'D' ? (
        <div className="superadmin_product_list_contents_name_version_type_border_spc">
          <div className="superadmin_product_list_contents_name_version_type_border">
            <div className="superadmin_product_list_contents_name_version_type_single">
              <div className="superadmin_product_list_contents_title">제품 버전</div>
              <div className="superadmin_product_list_contents_name_version_type_right_flex">
                <ComboBox
                  data={years}
                  // data={comboBoxYear.map(item => ({ id: item.dsId, text: item.dsName }))}
                  textField="text"
                  dataItemKey="id"
                  value={selectedYear}
                  onChange={handleYearChange}
                  style={{ borderRadius: '8px', height: '50px' }}
                />
                <div className="superadmin_product_list_resist_input_typeB_icon">
                  <div className="superadmin_icon_pack">
                    <i className="fa fa-plus" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
            <div className="superadmin_product_list_contents_upload_area">
              <div className="superadmin_product_list_contents_upload_pack_files">
                <div className="superadmin_product_list_contents_upload_title">
                  제품 파일
                </div>
                <div className="file-upload">
                  <Upload
                    // 파일의 초기목록
                    // defaultFiles={[]}
                    multiple={false}
                    withCredentials={false}
                    saveUrl={`${clientConfig.URL}/updateProduct?pdCode=${selectedOnProductEdit[0]?.pdCode}&pdName=${selectedOnProductEdit[0]?.pdName}&pdVersion=${selectedOnProductEdit[0]?.pdVersion}&pfId=${selectedOnProductEdit[0]?.pfId}&pdId=${selectedOnProductEdit[0]?.pdId}&pdVersionYear=${selectedYear.text}`}
                    onStatusChange={(event) => handleUploadProduct(event)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 영교님 리스트업 복수 나열입니다 */}
          <div className={`superadmin_product_list_contents_name_version_type_border ${fileList[0]?.apf_pdVersionYear ? '' : 'hidden'}`}>
            <div className="superadmin_product_list_contents_name_version_type_border_file_listup_block">
              {fileList.map(file => (
                <div className="superadmin_product_list_contents_name_version_type_border_file_listup">
                  <div>{file.apf_pdVersionYear} / {file.apf_pdFile}</div>
                  <div key={file.apf_pfId} onClick={() => deleteFileWhenEdit(file.apf_pfId, file.apf_pdVersionYear, file.pdCode)} style={{ cursor: 'pointer', width: '30px', textAlign: 'center' }}>X</div>
                </div>
              ))}
              {/* {folders.map((folder, index) => (
                <div className="superadmin_product_list_contents_name_version_type_border_file_listup" key={index}>
                  <div>{folder}</div>
                  <div onClick={() => deleteFileWhenEdit(folder)} style={{ cursor: 'pointer', width: '30px', textAlign: 'center' }}>X</div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="superadmin_product_list_contents_name_version_type_border">
            <div className="superadmin_product_list_contents_name_version_type_single">
              <div className="superadmin_product_list_contents_title">
                Link URL 입력
              </div>
              <input
                type="text"
                placeholder="URL을 입력해주세요"
                className="superadmin_product_list_resist_input"
                defaultValue={fileList[0]?.pdFile}
                onChange={(event) => setInputLink(event.target.value)} />
            </div>
          </div>
        </>
      )}
      <div className="superadmin_product_list_resister">
        <div className="superadmin_product_list_bt_pack">
          <div className="superadmin_product_list_bt_margin">
            <a
              // href="master_license_management_product_management.html"
              className="btn btn-theme border btn-sm"
              data-animation="animated slideInUp"
              onClick={editAllProductInfo}
            >
              수정 하기
            </a>
          </div>
          <div className="superadmin_product_list_bt_margin">

          </div>
        </div>
      </div>

    </div>
  </div>
  )
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// 제품 등록 페이지
export const Master_Product_Management_Add: React.FC<MasterProductManagementResistProps> = ({ handlePageChange, selectedOnProductEdit }) => {

  // 제품 데이터 상태 관리
  const [masterProductInfo, setMasterProductInfo] = useState<Array<MasterProductInfoProps>>([])
  console.log('masterProductInfo : ', masterProductInfo);
  // 제조사 데이터 상태 관리
  const [masterProductInfo2, setMasterProductInfo2] = useState<Array<MasterProductInfoProps2>>([])
  // 수정 제품 데이터 받아옴
  const [OnProductEditData] = useState(selectedOnProductEdit)

  // 제조사 데이터 관리
  const [selectedValueId, setSelectedValueId] = useState(masterProductInfo2[0]?.dsId || '');
  // console.log(selectedValueId);
  const [selectedValue, setSelectedValue] = useState(masterProductInfo2[0]?.dsName || '');
  const [editValue, setEditValue] = useState(masterProductInfo2[0]?.dsName || '');
  const [editValueOut, setEditValueOut] = useState(masterProductInfo2[0]?.dsName || '');

  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };

  // 화면 렌더링 키 상태 관리
  const [key, setKey] = useState(0);
  // 화면 렌더링 키 값 증가로 관리
  const refreshDiv = () => {
    setKey(prevKey => prevKey + 1);
  }


  // 제품 구분 상태 관리
  const [selectedSupport, setSelectedSupport] = useState('');
  // 제조일자 관리
  const defaultYear = currentYear.toString();
  // 제조일자 텍스트 아이디 일치하도록 변환
  const selectedYearObj = years.find(year => year.text === defaultYear) || years[0];
  // console.log(selectedYearObj);
  const [selectedYear, setSelectedYear] = React.useState(selectedYearObj);
  console.log('selectedYear.text: ', selectedYear.text)
  // console.log(selectedYear.text)

  // 상세설명 상태 관리
  const [textAreaValue, setTextAreaValue] = useState('')
  // console.log(textAreaValue);
  // 버전 상태 관리
  const [inputVersion, setInputVersion] = useState('')
  // console.log(inputVersion);
  // 제품이름 상태 관리
  const [inputProductName, setInputProductName] = useState('')
  // 제품 코드 상태 관리
  const [inputProductCode, setInputProductCode] = useState('')
  // console.log(inputProductName);
  // 제품이름 상태 관리
  const [inputLink, setInputLink] = useState('')
  // console.log(inputLink);


  // 두번째 업로드 상태 관리
  const [showSecondUpload, setShowSecondUpload] = useState(false)
  // 제품파일 업로드여부 상태 관리
  const [checkedUploadProduct, setCheckedUploadProduct] = useState(false)
  // 제품코드 pdcode 중복 체크 완료여부 상태 관리
  const [checkedByPdCode, setCheckedByPdCode] = useState(false)

  // 두번째업로드 pdId 상태 관리
  const [uploadToPdId, setUploadToPdId] = useState(null)
  // 두번째업로드 pfId 상태 관리
  const [uploadToPfId, setUploadToPfId] = useState(null)
  // 두번째업로드 pdCode 상태 관리
  const [uploadToPdCode, setUploadToPdCode] = useState(null)
  // 제품메뉴얼, 버전별파일
  const [fileList, setFileList] = useState<Array<FileListProps>>([])
  console.log('fileList: ', fileList);

  // 제조일자 상태 감지
  const handleYearChange = (event: ComboBoxChangeEvent) => {
    console.log('handleYearChange event : ', event);
    if (event.target.value === null) {
      setPopupMessage('정확한 년도를 선택해주세요.')
      setShowPopup(true)
      return false;
    }
    setSelectedYear(event.target.value);
    // 등록중 제조년도 변경
    // axios.post(`${clientConfig.URL}/editPdVersionYearWhenAdd`, {
    //   dsId: event.target.value.id,
    //   dsName: event.target.value.text,
    // })
    //   .then(function (response) {
    //     console.log('제품등록중 제조사 변경 : ', response);
    //   })
    //   .catch(function (error) {
    //     // 요청이 실패했을 때 수행할 작업
    //     console.log('제품등록중 제조사 변경Error : ', error);
    //     console.error(error);
    //   });
  };


  // 솔루션 관리자 제품목록 불러오기
  const getMasterProductInfo = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getMasterProductInfo`, {
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        // console.log(response.data);
        setMasterProductInfo(response.data);
      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getMasterProductInfo();
  }, []);

  const getMasterProductInfo2 = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getMasterProductInfo2`, {
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setMasterProductInfo2(response.data);
        console.log('setMasterProductInfo2: ', response.data);

      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getMasterProductInfo2();
  }, []);



  // 제품 설명서, 파일 정보 가져오기
  const getFileList = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/getFileList`, {
        pdId: uploadToPdId
      });
      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setFileList(response.data);
        getMasterProductInfo();
        getMasterProductInfo2();
      }
    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  useEffect(() => {
    getFileList();
  }, []);


  // 제품타입 상태 변경
  const handleSupportClick = (supportType: string) => {
    if (showSecondUpload) {
      console.log('상태 변경금지');
      return false
    }
    setSelectedSupport((prevSupport) => (prevSupport === supportType ? "" : supportType));
    console.log(supportType);
    // 등록중 제품타입변경
    if (showSecondUpload) {
      axios.post(`${clientConfig.URL}/editPdType`, {
        pdType: supportType,
        pdId: uploadToPdId
      })
        .then(function (response) {
          console.log('타입변경 : ', response);
        })
        .catch(function (error) {
          // 요청이 실패했을 때 수행할 작업
          console.error(error);
        });
    }


  };
  // 선택한 버튼의 스타일 변경
  const getSupportStyle = (supportType: string) => {
    switch (supportType) {
      case "mobile":
        return {
          color: "#ffffff",
          background: "linear-gradient(135deg, rgba(48,28,96,1) 0%, rgba(130,107,151,1) 100%)"
        };
      case "web":
        return {
          color: "#ffffff",
          background: "linear-gradient(135deg, rgba(28,60,96,1) 0%, rgba(107,128,151,1) 100%)"
        };
      case "cs":
        return {
          color: "#ffffff",
          background: "linear-gradient(135deg, rgba(31,96,28,1) 0%, rgba(107,151,124,1) 100%)"
        };
      default:
        return {};
    }
  };



  // 제조사 입력값 감지
  const handleOnChange = (event: ComboBoxChangeEvent) => {
    console.log(event.target.value);
    setSelectedValue(event.target.value?.text)
    setSelectedValueId(event.target.value?.id)
    // 선택한 수정할 값
    if (event.nativeEvent instanceof PointerEvent) {
      console.log('선택이벤트 : ', event.target.value.text);
      setEditValueOut(event.target.value.text)
    }
    // 수정할값에 업데이트할 값
    if (event.nativeEvent instanceof FocusEvent) {
      setEditValue(event.target.value.text)
      console.log('아웃이벤트 : ', event.target.value.text);
    }
    // DB변경
    // axios.post(`${clientConfig.URL}/editDBMakerName`, {
    //   dsId: event.target.value.id,
    //   dsName: event.target.value.text,
    // })
    //   .then(function (response) {
    //     console.log('제품등록중 제조사 변경 : ', response);
    //   })
    //   .catch(function (error) {
    //     // 요청이 실패했을 때 수행할 작업
    //     console.log('제품등록중 제조사 변경Error : ', error);
    //     console.error(error);
    //   });
  }

  // 제조사 추가
  const insertMaker = async () => {
    if (showSecondUpload) {
      console.log('선택금지');
      return false
    }
    try {
      const response = await axios.post(`${clientConfig.URL}/insertMakerInfo`, {
        textValue: selectedValue // 선택한 값 전송
        // filterText // 선택한 값 전송
      });
      // console.log('2insertMakerinsertMaker : ', selectedValue);

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('추가하였습니다.');
        setShowPopup(true);
      } else {
        // 삽입 실패 시 중복되었다는 메시지 표시
        setPopupMessage('회사명이 이미 존재합니다');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error inserting maker:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제조사 데이터 불러오기
    getMasterProductInfo2()
  };

  // 제조사 수정
  const editMaker = async () => {
    if (showSecondUpload) {
      console.log('선택금지');
      return false
    }
    console.log('수정할 대상값 : ', editValueOut);//Original
    console.log('업데이트 값 : ', editValue);//Target
    if (!editValue) {
      setPopupMessage('수정할 값을 입력해주세요.');
      setShowPopup(true);
      return false
    }
    try {
      const response = await axios.post(`${clientConfig.URL}/updateMakerInfo`, {
        textValue: editValueOut,
        textValue2: editValue
      });
      console.log('editMaker : ', selectedValue);

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('수정하였습니다.');
        setShowPopup(true);
      } else {
        setPopupMessage('회사명이 이미 존재합니다.');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error inserting maker:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제조사 데이터 불러오기
    getMasterProductInfo2()
    // 선택값 초기화
    //setEditValue('')
    //setEditValueOut('')
  }


  // 제품 아이콘 등록
  const handleUploadImage = (event: UploadOnStatusChangeEvent) => {
    console.log('handleUploadImage', event);
    if (event.response) {
      if (event.response.status == 200) {
        setPopupMessage("아이콘을 등록했습니다.");
        setShowPopup(true);
        getFileList();
        // div 재렌더링
        refreshDiv()
      }
      else {
        console.log('아이콘 업로드 실패');
        // setPopupMessage("사진 업로드 실패");
        // setShowPopup(true);
      }
    }
  }
  // 메뉴얼 업로드 확인
  const handleUploadManual = (event: UploadOnStatusChangeEvent) => {
    console.log('event', event);
    if (event.response) {
      if (event.response.status == 200) {
        setPopupMessage("매뉴얼 업로드했습니다.");
        setShowPopup(true);
      }
      else {
        console.log('매뉴얼 업로드 실패');
        // setPopupMessage("사진 업로드 실패");
        // setShowPopup(true);
      }
    }
    // 제품목록데이터 불러오기
    getMasterProductInfo()
    // 제품 설명서, 파일불러오기
    getFileList()
  }

  // 제품파일 업로드 확인
  const handleUploadProduct = (event: UploadOnStatusChangeEvent) => {
    console.log('event', event);
    if (event.response) {
      if (event.response.status == 200) {

        setPopupMessage("제품 파일을 업로드했습니다.");
        setShowPopup(true);
        // 제품파일업로드 확인
        setCheckedUploadProduct(true)
      }
      else {
        console.log('제품 파일을 업로드 업로드 실패');
        // setPopupMessage("사진 업로드 실패");
        // setShowPopup(true);
      }
    }
    // 제품 설명서, 파일불러오기
    getFileList()
  }

  // 첫번째 제품 정보 등록  
  const firstUpload = async () => {
    if (!inputVersion) {
      setPopupMessage('제품 버전을 입력해주세요')
      setShowPopup(true)
      return false;
    } else if (!selectedSupport) {
      setPopupMessage('제품 지원을 선택해주세요')
      setShowPopup(true)
      return false;
    } else if (!inputProductCode) {
      setPopupMessage('제품 코드를 입력해주세요')
      setShowPopup(true)
      return false;
    }
    else if (!checkedByPdCode) {
      setPopupMessage('제품 코드의 중복검사를 해주세요')
      setShowPopup(true)
      return false;
    } else if (!inputProductName) {
      setPopupMessage('제품 이름을 입력해주세요')
      setShowPopup(true)
      return false;
    } else if (!selectedValueId) {
      setPopupMessage('제조사를 선택해주세요')
      setShowPopup(true)
      return false;
    } else {

      try {
        const response = await axios.post(`${clientConfig.URL}/firstUploadProductInfo`, {
          pdCode: inputProductCode,
          pdName: inputProductName,
          pdType: selectedSupport,
          pdVersion: inputVersion,
          pdDescription: textAreaValue,
          dsId: selectedValueId,
          pdVersionYear: selectedYear.text,
        });

        // 삽입 성공 시
        if (response.data && response.data.success) {
          // console.log(response.data);
          setPopupMessage('제품 정보가 등록되었습니다.');
          setShowPopup(true);
          // 두번째입력 오픈
          setShowSecondUpload(true)
          // 제품등록정보불러오기
          getMasterProductInfo()
          // 제품목록 pdId
          setUploadToPdId(response.data.results_pdId)
          // 제품목록 pfid
          setUploadToPfId(response.data.fileResults_pfid)
          // 제품목록 pdCode
          setUploadToPdCode(response.data.randomCodeIsPdCode)
          console.log('firstUploadProductInfo : ', response);
        } else {
          console.log('firstUpload ERR : ', response);
        }
      } catch (error) {
        console.error('Error firstUpload:', error);
        // 서버 오류 발생 시 메시지 표시
        setPopupMessage('서버 오류가 발생했습니다.');
        setShowPopup(true);
      }
    }
  }

  // 두번째 업로드
  const secondUploadtoFile = async () => {
    if (!checkedUploadProduct) {
      setPopupMessage('제품파일을 등록해주세요')
      setShowPopup(true)
    }
    // 
    try {
      const response = await axios.post(`${clientConfig.URL}/secondUploadtoFile`, {
        // pfId: uploadToPfId,
        pfId: fileList[0]?.apf_pfId,
        pdDescription: textAreaValue,
      });

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('제품 정보가 등록되었습니다.');
        setShowPopup(true);
        setTimeout(() => {
          handlePageChange(3);
        }, 1000);
        console.log('secondUploadtoFile : ', response);
      } else {
        console.log('secondUpload ERR : ', response);
      }
    } catch (error) {
      console.error('Error secondUpload:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
  }

  //URL 인 경우
  const secondUploadtoURL = async () => {
    if (!inputLink) {
      setPopupMessage('URL을 입력해주세요')
      setShowPopup(true)
      return false
    }
    // 
    try {
      const response = await axios.post(`${clientConfig.URL}/secondUploadtoURL`, {
        // pdId: uploadToPdId,
        pdId: uploadToPdId,
        // pfId: uploadToPfId,
        pdDescription: textAreaValue,
        pdFile: inputLink,
      });

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        window.location.reload(); // 페이지 새로고침
        setPopupMessage('제품 정보가 등록되었습니다.');
        setShowPopup(true);
        setTimeout(() => {
          handlePageChange(3);
        }, 1000);
        console.log('secondUploadtoURL : ', response);
      } else {
        console.log('secondUpload ERR : ', response);
      }
    } catch (error) {
      console.error('Error secondUpload:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
  }

  // 입력한 제품코드 pdCode 중복체크
  const checkedPdCode = async () => {
    if (showSecondUpload) {
      console.log('선택금지');
      return false
    } else {
      console.log('코드네임 중복 췤');
      if (!inputProductCode) {
        setPopupMessage('제품 코드를 입력해주세요')
        setShowPopup(true)
        return false
      } else {
        console.log(inputProductCode);
        try {
          const response = await axios.post(`${clientConfig.URL}/checkedPdCodeInfo`, {
            pdCode: inputProductCode,
          });

          if (response.data && response.data.success) {
            setPopupMessage('사용 가능한 제품코드 입니다.');
            setShowPopup(true);
            setCheckedByPdCode(true)
            console.log('chckedPdCodeInfo : ', response);
          } else {
            setPopupMessage('제품코드가 중복됩니다.');
            setShowPopup(true);
            console.log('chckedPdCodeInfo ERR : ', response);
          }
        } catch (error) {
          console.error('Error chckedPdCodeInfo:', error);
          // 서버 오류 발생 시 메시지 표시
          setPopupMessage('서버 오류가 발생했습니다.');
          setShowPopup(true);
        }
      }
    }
  }

  // 매뉴얼 삭제
  const deleteManualWhenEdit = async () => {
    console.log('삭제감지');
    try {
      const response = await axios.post(`${clientConfig.URL}/deleteManualWhenEdit`, {
        pdId: fileList[0]?.pdId,
        pdCode: fileList[0]?.pdCode,
        pdManual: fileList[0]?.pdManual,
      });

      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('삭제 완료 하였습니다.');
        setShowPopup(true);
        console.log('삭제성공 : ', response.data);
        // 제품 설명서, 파일불러오기
        getFileList();
        // console.log('삭제이후 fileList : ', fileList);
      } else {
        console.log('오류');
      }
    } catch (error) {
      console.error('Error deleteManualWhenEdit:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제품 설명서, 파일불러오기
    getFileList();
  }

  // 수정중 파일 삭제
  const deleteFileWhenEdit = async (fileId: string, fileYear: string, fileCode: string) => {
    console.log('파일삭제감지', fileId, fileYear, fileCode);
    try {
      const response = await axios.post(`${clientConfig.URL}/deleteFileWhenEdit`, {
        pfId: fileId,
        apf_pdVersionYear: fileYear,
        pdCode: fileCode,
      });
      // 삽입 성공 시
      if (response.data && response.data.success) {
        // console.log(response.data);
        setPopupMessage('삭제 완료 하였습니다.');
        setShowPopup(true);
        console.log('삭제성공 : ', response.data);
      } else {
        console.log('오류');
      }
    } catch (error) {
      console.error('Error deleteManualWhenEdit:', error);
      // 서버 오류 발생 시 메시지 표시
      setPopupMessage('서버 오류가 발생했습니다.');
      setShowPopup(true);
    }
    // 제품 설명서, 파일불러오기
    getFileList();
  }


  return (<div className="single-item item">
    {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
    {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
    {/* <div className="superadmin_product_list">
      <div className="superadmin_product_list_title">제품 관리</div>
      <div className="superadmin_product_list_bt_pack">
        <div className="superadmin_product_list_bt_margin">
          <a
            // href="master_license_management_product_management_resist.html"
            aria-disabled
            className="btn btn-theme border btn-sm"
            data-animation="animated slideInUp"
          >
            제품 등록하기
          </a>
        </div>
      </div>
    </div> */}
    <div className="info">
      <div className="contact-form">
        <div className="superadmin_product_list_resister_area">
          <div className="superadmin_product_list_resister_title">
            제품 정보 입력
          </div>

        </div>
      </div>
      {/* 제품명, 제품 버전, 제품 구분을 입력 또는 선택하는 폼 */}
      <div className="meta">
        <div className="superadmin_product_list_contents">
          <div className="superadmin_product_list_contents_type_title">
            제품 구분
          </div>
          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_type">
              <div className="superadmin_product_list_contents_type_tag">
                <div className="superadmin_product_list_contents_pd_type_version">
                  <div className="superadmin_product_list_contents_pd_type_version_label">
                    버전입력
                  </div>
                  <input
                    type="text"
                    placeholder="버전입력 ex)1.23.4567"
                    className="superadmin_product_list_resist_version_input"
                    // defaultValue={`${selectedOnProductEdit[0]?.pdVersion}`}
                    onChange={(event) => setInputVersion(event.target.value)}
                    disabled={showSecondUpload}

                  />
                </div>
                <div className="superadmin_product_list_contents_pd_type_m">
                  <a style={{ cursor: 'pointer', ...(selectedSupport === "mobile" && getSupportStyle("mobile")) }} onClick={() => handleSupportClick("mobile")}>Mobile 지원</a>
                </div>
                <div className="superadmin_product_list_contents_pd_type_w">
                  <a style={{ cursor: 'pointer', ...(selectedSupport === "web" && getSupportStyle("web")) }} onClick={() => handleSupportClick("web")}>Web 지원</a>
                </div>
                <div className="superadmin_product_list_contents_pd_type_c">
                  <a style={{ cursor: 'pointer', ...(selectedSupport === "cs" && getSupportStyle("cs")) }} onClick={() => handleSupportClick("cs")}>CS 지원</a>
                </div>
              </div>
            </div>
          </div>

          {/* //#region 제품코드 입력칸 임의 제작 */}
          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_name_version_type_right">
              <div className="superadmin_product_list_contents_title">
                제품 코드
              </div>
              <div className="superadmin_product_list_contents_name_version_type_right_flex">
                <input
                  type="text"
                  placeholder="제품코드를 입력해주세요"
                  className="superadmin_product_list_resist_input"
                  onChange={(event) => setInputProductCode(event.target.value)}
                  disabled={showSecondUpload}
                />
                <div className="superadmin_product_list_resist_input_typeB_icon" >
                  <div className="superadmin_icon_pack" onClick={checkedPdCode}>
                    {/* 제조사 등록 */}
                    <i className="fa fa-check" aria-hidden="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* //#endregion */}

          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_name_version_type_left">
              <div className="superadmin_product_list_contents_title">
                제품명
              </div>
              <input
                type="text"
                placeholder="제품명을 입력해주세요"
                className="superadmin_product_list_resist_input"
                // defaultValue={`${selectedOnProductEdit[0]?.pdName}`}
                onChange={(event) => setInputProductName(event.target.value)}
                disabled={showSecondUpload}
              />
            </div>
            <div className="superadmin_product_list_contents_name_version_type_right">
              <div className="superadmin_product_list_contents_title">
                제조사
              </div>
              <div className="superadmin_product_list_contents_name_version_type_right_flex">

                {/* 제조사 선택 */}
                <ComboBox
                  // data={masterProductInfo2.map(item => item.dsName)}
                  data={masterProductInfo2.map(item => ({
                    text: item.dsName, // 보여지는 텍스트는 dsName
                    id: item.dsId // 실제 값은 dsId
                  }))}
                  textField="text"
                  dataItemKey="id"
                  placeholder={'제조사를 선택해주세요.'}
                  // value={selectedValue}
                  filterable={true}
                  // onFilterChange={handleFilterChange}
                  // 검색어 화면 남기기
                  allowCustom={true}
                  // 입력값이 변경될 때마다 상태 업데이트
                  // onChange={(event) => setSelectedValue(event.target.value)}
                  onChange={(event) => handleOnChange(event)}
                  disabled={showSecondUpload}
                  style={{ borderRadius: '8px', height: '50px' }}

                />
                <div className="superadmin_product_list_resist_input_typeB_icon" >
                  <div className="superadmin_icon_pack">
                    {/* 제조사 등록 */}
                    <i className="fa fa-plus" aria-hidden="false" onClick={insertMaker}
                    />
                  </div>
                  <div className="superadmin_icon_pack">
                    <i className="fa fa-pencil-alt" aria-hidden="true" onClick={editMaker} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_name_version_type_single">
              <div className="superadmin_product_list_contents_title">
                제품 부가 설명
              </div>
              {/* <input
                type="text"
                placeholder="제품의 부가 설명을 입력해주세요"
                className="superadmin_product_list_resist_input"
                onChange={(event) => setTextAreaValue(event.target.value)}
                disabled={showSecondUpload}
              /> */}
              <textarea className="form-control_content"
                id="comments"
                name="comments"
                placeholder="제품의 부가 설명을 입력해주세요"
                onChange={(event) => setTextAreaValue(event.target.value)}
                disabled={showSecondUpload}
              // style={{ height: '112px', width: '663px' }} 
              />
            </div>
          </div>
          <div className="superadmin_product_list_resister" hidden={showSecondUpload}>
            <div className="superadmin_product_list_bt_pack">
              <div className="superadmin_product_list_bt_margin">
                <a
                  // href="master_license_management_product_management.html"
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  onClick={firstUpload}
                >
                  제품정보 등록 하기
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="meta" onClick={firstUpload} style={{ cursor: 'pointer' }} hidden={showSecondUpload}>
        {/* 영교님 안내 문구 */}
        <div className="superadmin_product_list_contents">
          <img src="assets/img/info_gd_line.png" />
        </div>
      </div>
      {/* 두번쨰업로드 */}
      {showSecondUpload && (
        <div>
          <div className="superadmin_product_list_contents_upload_pack_foricon">
            <div className="superadmin_product_list_contents_icon_preview_area">
              <div key={key} className="superadmin_product_list_contents_icon_preview">
                {fileList[0]?.pdIcon ? (
                  <img src={`${clientConfig.URL}/product/${fileList[0]?.pdCode}/${fileList[0]?.pdIcon}`} />
                ) : (
                  <img src="assets/img/resist_icon_thumbnail_preview.png" />
                )}
              </div>
            </div>
            <img src="assets/img/resist_icon_thumbnail.png" />
          </div>
          <div className="superadmin_product_list_contents_name_version_type">
            <div className="superadmin_product_list_contents_upload_pack">
              <div className="superadmin_product_list_contents_upload_title">
                제품 아이콘
              </div>
              <div className="file-upload">
                <Upload
                  // 파일의 초기목록
                  multiple={false}
                  withCredentials={false}
                  // saveUrl={`${clientConfig.URL}/upLoadIconImage?pdId=${uploadToPdId}&pdCode=${uploadToPdCode}&pdVersionYear=${selectedYear.text}`}
                  saveUrl={`${clientConfig.URL}/upLoadIconImage?pdId=${uploadToPdId}&pdCode=${inputProductCode}&pdVersionYear=${selectedYear.text}`}
                  restrictions={{
                    // 최대 10MB 까지
                    maxFileSize: 5000000,
                    // 파일 형식 제한
                    allowedExtensions: [".jpg", ".jpeg", ".jfif", ".tiff", ".png", ".bmp", ".gif", ".svg", ".webp", ".avif"],
                  }}
                  onStatusChange={(event) => handleUploadImage(event)}
                />
              </div>
            </div>
          </div>
          <div className="meta">
            <div className="superadmin_product_list_contents">
              {/* 제품 상세설명 */}
              <div className="superadmin_product_list_contents_title">
                제품 상세 설명
              </div>
              <textarea className="form-control_content"
                id="comments"
                name="comments"
                placeholder="제품의 부가 설명을 입력해주세요"
                // style={{ height: '112px', width: '663px' }}
                onChange={(event) => setTextAreaValue(event.target.value)}
              />
            </div>
          </div>
          {/* 활용 https://www.telerik.com/kendo-react-ui/components/dropdowns/dropdownlist/filtering/ */}
          <div className="superadmin_product_list_contents_name_version_type_border">
            <div className="superadmin_product_list_contents_upload_area">
              <div className="superadmin_product_list_contents_upload_pack_files">
                <div className="superadmin_product_list_contents_upload_title">
                  제품 설명서
                </div>
                <div className="file-upload">
                  {/* 제품 설명서 업로드 */}
                  <Upload
                    // 파일의 초기목록
                    // defaultFiles={[]}
                    multiple={false}
                    withCredentials={false}
                    // saveUrl={`${clientConfig.URL}/upLoadManual?pdId=${uploadToPdId}&pdCode=${uploadToPdCode}&pdVersionYear=${selectedYear.text}`}
                    saveUrl={`${clientConfig.URL}/updateManual?pdId=${uploadToPdId}&pdCode=${inputProductCode}&pdVersionYear=${selectedYear.text}&pdName=${inputProductName}`}
                    onStatusChange={(event) => handleUploadManual(event)}
                  />
                </div>
                <div className={`superadmin_product_list_contents_name_version_type_border ${fileList[0]?.pdManual ? '' : 'hidden'}`}>
                  <div className="superadmin_product_list_contents_name_version_type_border_file_listup_block">
                    <div className="superadmin_product_list_contents_name_version_type_border_file_listup">
                      <div>{fileList[0]?.pdManual}</div>
                      <div
                        onClick={deleteManualWhenEdit}
                        style={{ cursor: 'pointer', width: '30px', textAlign: 'center' }}>X</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 다운로드제품이면 보여주고 아니면 url 입력 */}
          {selectedSupport == 'cs' ? (
            <div className="superadmin_product_list_contents_name_version_type_border_spc">
              <div className="superadmin_product_list_contents_name_version_type_border">
                <div className="superadmin_product_list_contents_name_version_type_single">
                  <div className="superadmin_product_list_contents_title">제품 버전</div>
                  <div className="superadmin_product_list_contents_name_version_type_right_flex">
                    <ComboBox
                      data={years}
                      textField="text"
                      dataItemKey="id"
                      value={selectedYear}
                      onChange={handleYearChange}
                      style={{ borderRadius: '8px', height: '50px' }}
                    />
                    <div className="superadmin_product_list_resist_input_typeB_icon">
                      <div className="superadmin_icon_pack">
                        <i className="fa fa-plus" aria-hidden="true" />
                      </div>
                    </div>
                  </div>
                  {/* <input type="text" placeholder="제품 버전을 입력해주세요. ex)2024" class="superadmin_product_list_resist_input"> */}
                </div>
                <div className="superadmin_product_list_contents_upload_area">
                  <div className="superadmin_product_list_contents_upload_pack_files">
                    <div className="superadmin_product_list_contents_upload_title">
                      제품 파일
                    </div>
                    <div className="file-upload">
                      <input
                        type="text"
                        className="upload-name"
                        defaultValue="해당 제품의 파일을 선택해주세요"
                        disabled
                      />
                      {/* 제품 업로드 구역 */}
                      <Upload
                        // 파일의 초기목록
                        // defaultFiles={[]}
                        multiple={false}
                        withCredentials={false}
                        // saveUrl={`${clientConfig.URL}/upLoadProduct?pdId=${uploadToPdId}&pdCode=${uploadToPdCode}&pfId=${uploadToPfId}&pdVersionYear=${selectedYear.text}`}
                        saveUrl={`${clientConfig.URL}/updateProduct?pdId=${uploadToPdId}&pdCode=${inputProductCode}&pfId=${uploadToPfId}&pdVersionYear=${selectedYear.text}&pdName=${inputProductName}&pdVersion=${inputVersion}`}
                        onStatusChange={(event) => handleUploadProduct(event)}
                      />
                      {/* <label htmlFor="ex_filename" className="bulk_upload_BT">
                  파일 선택
                </label> */}
                      <input type="file" id="ex_filename" className="upload-hidden" />
                    </div>
                  </div>
                </div>
              </div>
              {/* 영교님 리스트업 복수 나열입니다 */}
              <div className={`superadmin_product_list_contents_name_version_type_border ${fileList[0]?.apf_pdVersionYear ? '' : 'hidden'}`}>
                <div className="superadmin_product_list_contents_name_version_type_border_file_listup_block">
                  {fileList.map(file => (
                    <div className="superadmin_product_list_contents_name_version_type_border_file_listup">
                      <div>{file.apf_pdVersionYear} / {file.apf_pdFile}</div>
                      <div key={file.apf_pfId} onClick={() => deleteFileWhenEdit(file.apf_pfId, file.apf_pdVersionYear, file.pdCode)} style={{ cursor: 'pointer', width: '30px', textAlign: 'center' }}>X</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="superadmin_product_list_resister">
                <div className="superadmin_product_list_bt_pack">
                  <div className="superadmin_product_list_bt_margin">
                    <a
                      // href="master_license_management_product_management.html"
                      className="btn btn-theme border btn-sm"
                      data-animation="animated slideInUp"
                      onClick={secondUploadtoFile}
                    >
                      등록 하기
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="superadmin_product_list_contents_name_version_type_border">
                <div className="superadmin_product_list_contents_name_version_type_single">
                  <div className="superadmin_product_list_contents_title">
                    Link URL 입력
                  </div>
                  <input
                    type="text"
                    placeholder="URL을 입력해주세요"
                    className="superadmin_product_list_resist_input"
                    onChange={(event) => setInputLink(event.target.value)} />
                </div>
              </div>
              <div className="superadmin_product_list_resister">
                <div className="superadmin_product_list_bt_pack">
                  {/* <div className="superadmin_product_list_bt_margin">
                            <a
                              // href="master_license_management_product_management.html"
                              className="btn btn-theme border btn-sm"
                              data-animation="animated slideInUp"
                              onClick={editAllProductInfo}
                            >
                              수정 완료
                            </a>
                          </div> */}
                  <div className="superadmin_product_list_bt_margin">
                    <a
                      // href="master_license_management_product_management.html"
                      className="btn btn-theme border btn-sm"
                      data-animation="animated slideInUp"
                      onClick={secondUploadtoURL}
                    >
                      등록 하기
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  </div>

  )
}