import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface PopupProps {
  message: string;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);

  const closeDialog = useCallback(() => {
      setVisible(false);
      // 확인 버튼 클릭 시 onClose 함수를 호출하여 부모 컴포넌트에 알립니다.
      onClose();
  }, [onClose]);


  // // 창을 띄울 때 바로 열도록 useEffect 사용
  useEffect(() => {
    setVisible(true);

    // 3초 후에 팝업을 자동으로 닫도록 설정
    const timer = setTimeout(() => {
      closeDialog();
    }, 1000);

    return () => {
      // 컴포넌트가 언마운트되면 타이머를 클리어하여 메모리 누수 방지
      clearTimeout(timer);
    };

  }, [closeDialog]);

  return (
    <div>
      {visible && (
        <Dialog title={"알림"} onClose={closeDialog}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            {message} {/* 전달받은 메시지 출력 */}
          </p>
          <DialogActionsBar>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default Popup;
