import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  // 페이지 이동
  const navigate = useNavigate();
  // 클릭 횟수 상태 관리
  const [clickCount, setClickCount] = useState(0);

  const handleClick = () => {
    // 클릭 횟수 1 증가
    setClickCount(prevCount => prevCount + 1);
    // 클릭 횟수가 7번이 되면 hiddenFunction 호출
    if (clickCount === 6) {
      navigate(`/master`);
      // 클릭 횟수 초기화
      setClickCount(0);
    }
  }

  return (
    <div>
      {/* <!-- Start Footer 
    ============================================= --> */}
      <footer className="bg-dark">
        <div className="container">
          <div className="row">
            <div className="f-items default-padding">

            </div>
          </div>
        </div>
        {/* <!-- Start Footer Bottom --> */}
        <div className="footer-bottom bg-dark text-light">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p>&copy; Copyright 2024. All Rights Reserved by <a href="#">InnoActive</a></p>
              </div>
              <div className="col-md-6 text-right link">
                <ul>
                  {/* <li>
                    <a href="#">Policy</a>
                  </li>
                  <li>
                    <a href="#">License</a>
                  </li>
                  <li>
                    <a href="#">Support</a>
                  </li> */}
                  {/* 정책 완료시 변경 - HN책임 */}
                  <li>
                    <p onClick={handleClick}>Innovation of Creative & Convergence</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Footer Bottom --> */}
      </footer>
      {/* <!-- End Footer --> */}
    </div>
  )
}

export default Footer