import React, { useEffect, useState, useRef, ForwardedRef, forwardRef, ChangeEvent } from 'react'
import { clientConfig } from '../api/clientConfig'
import axios from 'axios';
import { getUserInfoFromStorage } from '../utils/userInfoModule';
import Popup from './Popup/Popup';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import DialogForm from './Dialog/DialogForm';

interface Menu {
  mId: string;
  pdId: string;
  mName: string;
  mUrl: string;
  mType: string;
  mImageUrl: string;
  msId: string;
  msName: string;
  msUrl: string;
  msTarget: string;
}

// 유저 데이터 타입 정의
interface UserInfoData {
  uLoginId: string,
  uName: string,
  uEmail: string,
  uPhone: string,
  uAgree: string,
  uDept: string,
  uRank: string,
  uAdmin: string,
  uActive: string,
  uId: string,
  cId: string,
}

// 등록유저 타입 정의
interface AddUserInfo {
  cId: string;
  id: string;
  email: string;
  name: string;
  phone: string;
  dept: string;
  rank: string;
}

// 수정시 유저 타입 정의
interface EditUserInfo {
  id: string;
  email: string;
  name: string;
  phone: string;
  dept: string;
  rank: string;
  admin: string;
}

interface MasterPartnerHeaderProps {
  openMenu: boolean;
  handleMenu: () => void;
  companyInfo: any; // 받아올 회사 정보를 담을 props

}

// const MasterContractHeader = ({ }) => {
const MasterPartnerHeader: React.FC<MasterPartnerHeaderProps> = ({ openMenu, handleMenu, companyInfo }) => {
  // 위젯창 보여주기 상태 관리
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);
  // console.log('MasterContractHeader : ', openMenu);
  // console.log('MasterContractHeader에 있나요? : ', companyInfo);
  // console.log('MasterContractHeader에 있나요? : ', companyInfo?.cId);

  // 다이어로그창 열림여부 상태 관리
  const [isDialogOpen, setDialogOpen] = useState(false);
  // 다이어로그창의 내부 메세지1 출력 상태 관리
  const [dialogMessage, setDialogMessage] = useState('');
  // 다이어로그창의 내부 메세지2 출력 상태 관리
  const [dialogMessage2, setDialogMessage2] = useState('');
  // 다이어로그창의 내부 메세지3 출력 상태 관리
  const [dialogMessage3, setDialogMessage3] = useState('');

  // 다이어로그창 열림
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  // 다이어로그창 닫힘
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // 사용자가 대화상자를 확인
  const handleConformDialog = () => {
    handleCloseDialog();
  };

  useEffect(() => {
    setSideMenuVisible(openMenu);
    setOpenUserInfo(false)
  }, [openMenu]);
  // 위젯창의 X버튼 눌러서 위젯창 닫기
  const closeSideMenu = () => {
    setSideMenuVisible(false);
    handleMenu()
    setOpenUserInfo(false)
    console.log('toggleSideMenu 닫기 동작!');
  };


  const [menuData, setMenuData] = useState<Array<Menu>>([]);
  // 고객센터 매뉴 상태 관리
  const [menuData2, setMenuData2] = useState<Array<Menu>>([]);
  // 검색창 보여주기 상태 관리
  const [isSearchVisible, setSearchVisible] = useState(false);
  // DB 유저정보 관리
  const [userData, setUserData] = useState<Array<UserInfoData>>([]);
  const [userData2, setUserData2] = useState<Array<UserInfoData>>([]);
  // 유저 등록 보여주기 상태 관리
  const [showEditUserInput, setEditShowUserInput] = useState(false);
  const [showUserInput, setShowUserInput] = useState(false);

  // 회사코드
  const [companyCode, setCompanyCode] = useState('');
  // 회사명
  const [companyName, setCompanyName] = useState('');
  // 회사 사업번호
  const [registrationNumber, setRegistrationNumber] = useState('');
  // 회사 관리자
  const [administrator, setAdministrator] = useState('');
  // 회사 대표전화
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  // 회사 우편번호
  const [postalCode, setPostalCode] = useState('');
  // 회사 주소
  const [address, setAddress] = useState('');
  // 비고
  const [note, setNote] = useState('');

  // 편집할곳
  // 회사 cId
  const [companyId, setCompanyId] = useState(companyInfo?.cId);
  // 회사코드
  const [editCompanyCode, setEditCompanyCode] = useState(companyInfo?.cCode || '');
  // 회사명
  const [editCompanyName, setEditCompanyName] = useState(companyInfo?.cName || '');
  // 회사 사업번호
  const [editRegistrationNumber, setEditRegistrationNumber] = useState(companyInfo?.cNumber || '');
  // 회사 관리자
  const [editAdministrator, setEditAdministrator] = useState(companyInfo?.uId || '');
  // 회사 대표전화
  const [editCompanyPhoneNumber, setEditCompanyPhoneNumber] = useState(companyInfo?.cPhone || '');
  // 회사 우편번호
  const [editPostalCode, setEditPostalCode] = useState(companyInfo?.cPost || '');
  // 회사 주소
  const [editAddress, setEditAddress] = useState(companyInfo?.cAddress || '');
  // 비고
  const [editNote, setEditNote] = useState(companyInfo?.cDescription || '');
  // 수정중 등록한 파트너사 cId 상태관리
  const [toInsertCid, setToInsertCid] = useState('')
  // console.log('등록한 회사의 cId : ', toInsertCid);
  // 담당자 정보창 보여주기 상태 관리
  const [openUserInfo, setOpenUserInfo] = useState(false);


  // 선택값 변경시 업데이트
  useEffect(() => {
    setCompanyId(companyInfo?.cId);
    setEditCompanyCode(companyInfo?.cCode || '');
    setEditCompanyName(companyInfo?.cName || '');
    setEditRegistrationNumber(companyInfo?.cNumber || '');
    setEditAdministrator(companyInfo?.uId || '');
    setEditCompanyPhoneNumber(companyInfo?.cPhone || '');
    setEditPostalCode(companyInfo?.cPost || '');
    setEditAddress(companyInfo?.cAddress || '');
    setEditNote(companyInfo?.cDescription || '');
  }, [companyInfo]);

  // 유저 등록 정보
  const [addUser, setAddUser] = useState<AddUserInfo>({
    cId: '',
    id: '',
    email: '',
    name: '',
    dept: '',
    phone: '',
    rank: '',
  })

  // 유저 수정 정보 관리
  const [editUser, setEditUser] = useState<EditUserInfo>({
    id: '',
    email: '',
    name: '',
    dept: '',
    phone: '',
    rank: '',
    admin: '',
  })

  // 로그아웃 버튼 클릭 시 sessionStorage에서 isLoggedIn 정보 삭제
  const handleLogout = () => {
    //@ts-ignore
    sessionStorage.clear();
    window.location.reload();
    console.log('로그아웃');
  };


  // 검색창 열고 닫기
  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
    console.log('toggleSearch 동작!');
  };
  // 위젯창 열고 닫기
  const toggleSideMenu = () => {
    setSideMenuVisible(true);
    handleMenu()
  }
  // 세선스토리지에서 정보가져오기
  const userInfoObject = getUserInfoFromStorage();
  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };
  // PRODUCT 메뉴 DB조회
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu`, {
        })
        if (response.data.length > 0) {
          setMenuData(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_PRODUCT fetchData:', error);
      }
    };

    fetchData();
  }, []);

  // 고객센터 메뉴 DB조회
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu2`, {
        })
        if (response.data.length > 0) {
          setMenuData2(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_고객센터 fetchData:', error);
      }
    };

    fetchData2();
  }, []);

  // DB 유저 정보조회
  useEffect(() => {

    const fetchData = async () => {
      try {

        // 서버로 cId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getAdminToUserInfoData`, {
          cId: companyInfo?.cId,
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setUserData(response.data);
          // console.log(response.data);
        } else {
          setUserData([])
        }

      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    fetchData();
    // console.log('실행');
    // 등록한 파일 초기화
    setSelectedFile(null)
    setSelectedFile2(null)
  }, [companyInfo]);

  // 유저 정보값불러오기
  const callUserInfo = async () => {
    try {

      // 서버로 cId를 보내기
      const response = await axios.post(`${clientConfig.URL}/getAdminToUserInfoData`, {
        cId: companyInfo?.cId,
      });

      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setUserData(response.data);
        // console.log(response.data);
      } else {
        setUserData([])
      }

    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  // 수정중 추가한 유저 정보값불러오기
  const callUserInfoWhenAdd = async () => {
    try {

      // 서버로 cId를 보내기
      const response = await axios.post(`${clientConfig.URL}/getAdminToUserInfoData`, {
        cId: toInsertCid,
      });

      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setUserData2(response.data);
        // console.log(response.data);
      } else {
        setUserData2([])
      }

    } catch (error) {
      console.error('Error user_license fetchData:', error);
    }
  };

  // 수정시 파일 상태관리
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // 수정시 파일 업로드 관리
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file || null);
  };

  // 등록시 파일 상태관리
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);
  // 등록시 파일 업로드 관리
  const handleFileChange2 = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile2(file || null);
  };

  // 파트너사 정보수정
  const handelEditInfo = async () => {

    if (!companyId) {
      setPopupMessage("수정할 회사를 선택해주세요.");
      setShowPopup(true);
      return false;
    }
    // if (!editCompanyCode) {
    //   setPopupMessage("회사 코드를 입력해주세요.");
    //   setShowPopup(true);
    //   return false;
    // }
    else if (!editCompanyName) {
      setPopupMessage("회사이름을 입력해주세요.");
      setShowPopup(true);
      return false;
    }
    else {

      // try {
      //   const response = await axios.post(`${clientConfig.URL}/editMasterCompanyInfo`, {
      //     cId: companyId,
      //     cName: editCompanyName,
      //     cCode: editCompanyCode,
      //     cNumber: editRegistrationNumber,
      //     uId: editAdministrator,
      //     cPhone: editCompanyPhoneNumber,
      //     cPost: editPostalCode,
      //     cAddress: editAddress,
      //     cDescription: editNote,
      //     cFileName: selectedFile,
      //   })
      try {
        const formData = new FormData();
        formData.append('cCode', editCompanyCode);
        if (selectedFile) {
          formData.append('file', selectedFile); // 파일을 FormData에 추가
          formData.append('cFileName', selectedFile.name);
          // console.log('editCompanyCode : ', editCompanyCode);
        }
        // 나머지 필드 추가
        formData.append('cId', companyId);
        formData.append('cName', editCompanyName);

        formData.append('cNumber', editRegistrationNumber);
        formData.append('uId', editAdministrator);
        formData.append('cPhone', editCompanyPhoneNumber);
        formData.append('cPost', editPostalCode);
        formData.append('cAddress', editAddress);
        formData.append('cDescription', editNote);

        const response = await axios.post(`${clientConfig.URL}/editMasterCompanyInfo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data' // 파일 업로드를 위한 헤더 설정
          }

        });
        // console.log('response : ', response);
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          setPopupMessage("수정 되었습니다.");
          setShowPopup(true);
          handleMenu()
        }
      } catch (error) {
        // console.log('editMasterCompanyInfo / error :', error);
        console.error('editMasterCompanyInfo / error :', error);
        setPopupMessage("수정 중 서버오류가 발생했습니다.");
        setShowPopup(true);
      }
    }
  }



  // 유저 정보 등록값 관리
  const handleUserAdd = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setAddUser({
      ...addUser,
      [field]: e.target.value,
    });
  };


  // 유저 정보 수정값 관리
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    // console.log('handleChange : ' , e);
    // console.log('handleChangetypeof : ' , typeof e);
    setEditUser({
      ...editUser,
      [field]: e.target.value,
    });
  };



  // 파트너사  등록하기
  const handelInsertInfo = async () => {
    if (!companyName) {
      setPopupMessage("회사이름을 입력해주세요.");
      setShowPopup(true);
      return false;
    }
    else if (!registrationNumber) {
      setPopupMessage("사업자번호를 입력해주세요.");
      setShowPopup(true);
      return false;
    }
    else if (!companyPhoneNumber) {
      setPopupMessage("전화번호를 입력해주세요");
      setShowPopup(true);
      return false;
    }
    else {

      try {
        const response = await axios.post(`${clientConfig.URL}/insertMasterCompanyInfo`, {
          cName: companyName,
          cCode: companyCode,
          cNumber: registrationNumber,
          uId: administrator || null,
          cPhone: companyPhoneNumber,
          cPost: postalCode,
          cAddress: address,
          cDescription: note,
          cFileName: selectedFile2?.name || selectedFile2 || '',
        })
        // console.log('response : ', response);
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          setPopupMessage("추가 되었습니다.");
          setShowPopup(true);
          // 유저 등록할 창보여줌
          setShowUserInput(true)
          // 유저 등록할 회사cId 정보 받아옴
          setToInsertCid(response.data.sendCid)
          setCompanyCode(response.data.sendCCode)
          // console.log('companyCode : ', companyCode);
          setPopupMessage("회사 등록 완료.");
          setShowPopup(true);
        }
      } catch (error) {
        // console.log('insertMasterCompanyInfo / error :', error);
        console.error('insertMasterCompanyInfo / error :', error);
        // setPopupMessage("수정 중 서버오류가 발생했습니다.");
        // setShowPopup(true);
      }
    }
  }

  // 파트너사 수정중 유저 추가
  const handelInsertUserInfo = async () => {

    if (!userInfoObject) {
      setPopupMessage("올바른 접근이 아닙니다!");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.id) {
      setPopupMessage("아이디가 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(addUser.id)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.name) {
      setPopupMessage("이름이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.email) {
      setPopupMessage("이메일이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(addUser.email)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }

    else {

      try {
        const response = await axios.post(`${clientConfig.URL}/insertUserInfo`, {
          cId: companyInfo?.cId,
          uLoginId: addUser.id,
          uName: addUser.name,
          uEmail: addUser.email,
          uPhone: addUser.phone,
          uDept: addUser.dept,
          uRank: addUser.rank,
          uAdmin: '관리',
        })
        // console.log('response : ', response);
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          // setPopupMessage("등록 되었습니다. 임시 비밀번호는 이메일로 발송됩니다");
          setPopupMessage("등록 되었습니다. 비밀번호는 1111");
          setShowPopup(true);
          // 임시비밀번호 이메일 발송
          // handleForgotPassword()
          // 등록창 감추기
          setEditShowUserInput(false)
          // 유저정보 불러오기
          callUserInfo()
          callUserInfoWhenAdd()
        } else {
          setPopupMessage("등록 실패.");
          setShowPopup(true);
        }
      } catch (error: any) {
        console.error('insertUserInfo / error :', error);
        if (error.response && error.response.status === 400) {
          setPopupMessage("아이디가 중복됩니다.");
          setShowPopup(true);
          // setPopupMessage(error.response.data.error);
          // setShowPopup(true);
        } else {
          console.log('insertUserInfo : ', error);
          // setPopupMessage("등록 중 서버오류가 발생했습니다.");
          // setShowPopup(true);
        }
      }
    }
  }

  // 파트너사등록중 유저추가
  const handelInsertWhenAddUserInfo = async () => {

    if (!userInfoObject) {
      setPopupMessage("올바른 접근이 아닙니다!");
      setShowPopup(true);
      return false;
    }
    else if (!toInsertCid) {
      setPopupMessage("관리자를 등록해주세요.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.id) {
      setPopupMessage("아이디가 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(editUser.id)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.name) {
      setPopupMessage("이름이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.email) {
      setPopupMessage("이메일이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(editUser.email)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }

    else {
      try {
        const formData = new FormData();
        formData.append('cCode', companyCode);
        if (selectedFile2) {
          formData.append('file', selectedFile2); // 파일을 FormData에 추가
          formData.append('cFileName', selectedFile2.name);
        }
        // 나머지 필드 추가
        formData.append('cId', toInsertCid);
        formData.append('uLoginId', editUser.id);
        formData.append('uName', editUser.name);
        formData.append('uEmail', editUser.email);
        formData.append('uPhone', editUser.phone);
        formData.append('uDept', editUser.dept);
        formData.append('uRank', editUser.rank);
        formData.append('uAdmin', '관리');

        const response = await axios.post(`${clientConfig.URL}/insertUserInfoWhenAdd`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data' // 파일 업로드를 위한 헤더 설정
          }

        });

        // console.log('response : ', response);
        if (response.status === 200) {
          // setPopupMessage("등록 되었습니다. 임시 비밀번호는 이메일로 발송됩니다");
          setPopupMessage("등록 되었습니다. 비밀번호는 1111");
          setShowPopup(true);
          // 임시비밀번호 이메일 발송
          // handleForgotPasswordWhenAdd()
          setShowUserInput(false)
        }
        else {
          setPopupMessage("등록 되었습니다.");
          setShowPopup(true);
          // 등록창 감추기
          setShowUserInput(false)
          // 유저정보 불러오기
          callUserInfo()
          callUserInfoWhenAdd()
          closeSideMenu()
          setSelectedFile2(null)
        }
      } catch (error: any) {
        console.error('insertUserInfoWhenAdd / error :', error);
        if (error.response && error.response.status === 400) {
          // setPopupMessage("아이디가 중복됩니다.");
          // setShowPopup(true);
          // setPopupMessage(error.response.data.error);
          // setShowPopup(true);
        } else {
          // setPopupMessage("등록 중 서버오류가 발생했습니다.");
          // setShowPopup(true);
        }
      }
    }
  }

  // 이메일발송
  // 아이디 확인 및 이메일 발송
  const handleForgotPassword = async () => {
    // const handleForgotPassword = async () => {
    // event.preventDefault();

    try {
      // 아이디로 이메일 조회 ${clientConfig.URL}
      const responseEmail = await axios.post(`${clientConfig.URL}/getEmail`, {
        uLoginId: addUser.id,
      });
      // alert();
      const { success, uEmail } = responseEmail.data;
      // console.log('responseEmail.data');
      // console.log(responseEmail.data);
      if (success) {
        // 이메일 전송
        const response = await axios.post(`${clientConfig.URL}/sendemail`, {
          toEmail: addUser.email, // 조회된 이메일로 전송
          subject: '임시 비밀번호 발급',
          text: '임시 비밀번호를 발급합니다.',
          uLoginId: addUser.id
        });

        if (response.data.success) {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일이 성공적으로 보내졌습니다.`);
        } else {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일 보내기 실패: ${response.data.message}`);
        }
      } else {
        // 팝업 메시지 설정
        setPopupMessage("비밀번호는 입력한 이메일를 확인해주세요.");
      }

      // 팝업 열기
      setShowPopup(true);
    } catch (error) {
      // sendLog('Error handleForgotPassword /getEmail', `Error details : ${error}`, uLoginId, getFileName);
      console.error('이메일 조회 오류:', error);
      // 팝업 메시지 설정
      setPopupMessage("서버 오류 관리자에게 문의해 주세요.");
      // 팝업 열기
      setShowPopup(true);
    }
  };

  // 
  const handleForgotPasswordWhenAdd = async () => {
    // const handleForgotPassword = async () => {
    // event.preventDefault();

    try {
      // 아이디로 이메일 조회 ${clientConfig.URL}
      const responseEmail = await axios.post(`${clientConfig.URL}/getEmail`, {
        uLoginId: editUser.id,
      });
      // alert();
      const { success, uEmail } = responseEmail.data;
      // console.log('responseEmail.data');
      // console.log(responseEmail.data);
      if (success) {
        // 이메일 전송
        const response = await axios.post(`${clientConfig.URL}/sendemail`, {
          toEmail: editUser.email, // 조회된 이메일로 전송
          subject: '임시 비밀번호 발급',
          text: '임시 비밀번호를 발급합니다.',
          uLoginId: editUser.id
        });

        if (response.data.success) {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일이 성공적으로 보내졌습니다.`);
        } else {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일 보내기 실패: ${response.data.message}`);
        }
      } else {
        // 팝업 메시지 설정
        setPopupMessage("비밀번호는 입력한 이메일를 확인해주세요.");
      }

      // 팝업 열기
      setShowPopup(true);
    } catch (error) {
      // sendLog('Error handleForgotPassword /getEmail', `Error details : ${error}`, uLoginId, getFileName);
      console.error('이메일 조회 오류:', error);
      // 팝업 메시지 설정
      setPopupMessage("서버 오류 관리자에게 문의해 주세요.");
      // 팝업 열기
      setShowPopup(true);
    }
  };

  // 클라이언트에서 파일 다운로드를 요청하는 함수
  const downloadAttachedFile = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/downloadAttachedFile`, {
        cCode: editCompanyCode
      }, {
        responseType: 'blob' // 파일 다운로드를 위해 responseType을 'blob'으로 설정
      });

      // 응답으로부터 파일 다운로드
      // console.log('downloadAttachedFile : ', response)
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', companyInfo?.cFileName); // 빈 문자열로 설정하여 파일명을 서버에서 지정한 이름 그대로 사용
      document.body.appendChild(link);
      link.click();

      // 다운로드 후 URL 객체 및 링크 제거
      window.URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('파일 다운로드 오류:', error);
      // 오류 처리
    }
  };

  const [selectedPartnerFile, setSelectedPartnerFile] = useState<File | null>(null);
  // console.log('파일타입 감지 selectedPartnerFile.type : ', selectedPartnerFile?.type)
  // 파일 업로드 관리
  const handlePartnerFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
        // 파일 확장자 확인
        const allowedExtensions = ['xlsx', 'xls'];
        const fileExtension = file?.name.split('.').pop()?.toLowerCase() || '';
        // console.log('fileExtension :', fileExtension);
        if (allowedExtensions.includes(fileExtension)) {
          // console.log('selectedPartnerFile : ', file);
          setSelectedPartnerFile(file || null);
        } else {
          // 선택한 파일의 확장자가 허용되지 않으면 안내 팝업 표시
          console.error('지원하지 않는 파일 형식입니다. .xlsx 또는 .xls 파일을 선택해주세요.');
          setPopupMessage("지원하지 않는 파일 형식입니다. .xlsx 또는 .xls 파일을 선택해주세요.");
          setShowPopup(true);
        }

  };

  // 양식 업로드
  const excelFileUploadPartner = async () => {
    if (!selectedPartnerFile) {
      setPopupMessage('파일을 선택 올려주세요')
      setShowPopup(true)
      return false
    }
    // // 파일이 엑셀 파일이 아닌 경우 경고 메시지 표시
    // if (selectedPartnerFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //   setPopupMessage('엑셀 파일을 올려주세요');
    //   setShowPopup(true);
    //   return;
    // }

    const formData = new FormData();
    if (selectedPartnerFile) {
      formData.append('file', selectedPartnerFile);
      // formData.append('partnerFileName', selectedPartnerFile.name);
    }

    try {

      const response = await axios.post(`${clientConfig.URL}/excelFileUploadPartner`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // 파일 업로드를 위한 헤더 설정
        }
      });
      if (response.status === 200) {
        setPopupMessage("업로드 완료.");
        setShowPopup(true);
        setSelectedPartnerFile(null)
        closeSideMenu()
        // console.log('excelFileUploadPartner : ', response);
      }
      else {
        console.log('response 오류', response)
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        console.log(error.response);
        // 회사이름이 없으면 메세지 나옴
        // 다이어로그창 열기
        handleOpenDialog();
        setDialogMessage(error.response.data.message1)
        setDialogMessage2(error.response.data.message2)
        // setPopupMessage(error.response.data.message);
        // setShowPopup(true);
      }
      console.error('Error excelFileUploadPartner file:', error);
    }
  }

  // 양식 다운로드
  const downloadPartnerFile = async () => {
    try {
      const response = await axios.post(`${clientConfig.URL}/excelFilePartnerDownload`,
        // { fileName },
        {},
        { responseType: 'blob' }
      );
      // console.log('엑셀 response : ', response);
      // 파일 다운로드 로직 추가
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      // link.setAttribute('download', fileName);
      // 다운로드할 파일 이름 설정
      link.setAttribute('download', 'PartnerTemplate.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error while excelDownloading file:', error);
      // 에러 처리 로직 추가
    }
  }

// 현재 파트너사의 담당자 정보창보여주기
  const getAdminInfo = () => {
    // const setDataArr = JSON.stringify(userData)
    // console.log('JSON.stringify', setDataArr)
    // console.log('userData', userData)
    setOpenUserInfo(true)
  }

  return (
    <div>
      {/* DialogForm 컴포넌트 */}
      {isDialogOpen && (
        <DialogForm
          setTitle=" "
          message={dialogMessage}
          message2={dialogMessage2}
          message3={dialogMessage3}
          onClose={handleCloseDialog}
          onConform={handleConformDialog}
        />
      )}
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <header id="home">
        {/* <!-- Start Navigation --> */}
        <nav className="navbar navbar-default navbar-fixed dark bootsnav">
          {/* <!-- Start Top Search --> */}
          <div className="container_box"></div>
          <div className="container">
            <div className="row">
              {/* <div className={`top-search ${isSearchVisible ? 'visible' : 'hidden'}`}> */}
              <div className="top-search" style={{ display: isSearchVisible ? 'block' : 'none' }}>
                <div className="input-group">
                  <form action="#">
                    <input type="text" name="text" className="form-control" placeholder="서비스 및 라이선스 검색" />
                    <button type="submit">
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Top Search --> */}

          <div className="container">

            {/* <!-- Start Atribute Navigation --> */}
            <div className="attr-nav">
              <ul>
                {/* <li className="search"><a style={{ cursor: 'pointer' }} onClick={handleClick} ><i className="fa fa-search"></i></a></li> */}
                {/* 검색창 */}
                <li className="search"><a style={{ cursor: 'pointer' }} onClick={toggleSearch} ><i className="fa fa-search"></i></a></li>
                {/* 위젯창 */}
                <li className="side-menu"><a style={{ cursor: 'pointer' }} onClick={toggleSideMenu}><i className="fa fa-bars"></i></a></li>
              </ul>
            </div>
            {/* <!-- End Atribute Navigation --> */}

            {/* <!-- Start Header Navigation --> */}
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                <i className="fa fa-bars"></i>
              </button>
              <a className="navbar-brand" href="/main">
                {/* <img src="assets/img/logo.png" className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" /> */}
                <img src={`${clientConfig.URL}/image/logo.png`} className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" />
              </a>
            </div>
            {/* <!-- End Header Navigation --> */}

            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >Product</a>
                  <ul className="dropdown-menu">
                    {/* <Button>Browse</Button> */}
                    {menuData.map(item => <li><a href={item.msUrl} target={item.msTarget} key={item.pdId}>{item.msName}</a></li>)}
                  </ul>
                </li>

                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >고객센터</a>
                  <ul className="dropdown-menu">
                    {menuData2.map(item => <li><a href={item.msUrl} key={item.pdId}>{item.msName}</a></li>)}

                  </ul>
                </li>

                <li>

                  {/* @ts-ignore */}
                  {/* {sessionStorage.getItem('isLoggedIn') === 'true' ? ( */}
                  {sessionStorage.getItem('isMasterLoggedIn') === 'true' || sessionStorage.getItem('isLoggedIn') === 'true' ? (
                    <a href="login" onClick={handleLogout}>Logout</a>
                  ) : (
                    <a href="login">Login</a>
                  )}
                </li>
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown">
                    내 메뉴
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="user_license">마이 페이지</a>
                    </li>
                    {/* <li>
                  <a href="admin_license_user_update">내 정보 수정</a>
                </li> */}
                    <li>
                      <a href="master_license_management">
                        솔루션 관리자
                      </a>
                    </li>
                    <li>
                      <a href="admin_license">파트너 관리자</a>
                    </li>
                    {/* <li>
                  <a href="user_license">파트너 일반사용자</a>
                </li> */}
                    {/* <li>
                  <a href="index">로그아웃</a>
                </li> */}
                  </ul>
                </li>
              </ul>
            </div>
            {/* <!-- /.navbar-collapse --> */}
          </div>
          <div className={`side ${isSideMenuVisible ? 'on' : ''}`} >
            {/* 위젯창 닫기 버튼 */}
            {/* <div style={{display:'flex', flexDirection:'column'}}> */}
            <a style={{ cursor: 'pointer' }} className="close-side" onClick={closeSideMenu}><i className="fa fa-times"></i></a>
            <a style={{ cursor: 'pointer' }} className="arrow-left" hidden={!(openUserInfo)} onClick={()=>setOpenUserInfo(false)}><i className="fa fa-arrow-left"></i></a>
            {/* </div> */}
            {companyInfo ? (
              <div>
              <div className="widget" hidden={openUserInfo}>
                <h4 className="title_widget">파트너사 정보 수정하기</h4>
                <div className="widget_superadmin_contract_area">
                  <div className="widget_superadmin_partner_left">
                    {/* <div className="widget_superadmin_partner_label">고객사번호</div> */}
                    <div className="widget_superadmin_partner_label">고객사명</div>
                    <div className="widget_superadmin_partner_label">사업자번호</div>
                    <div className="widget_superadmin_partner_label">관리자</div>
                    <div className="widget_superadmin_partner_label">대표전화</div>
                    <div className="widget_superadmin_partner_label">우편번호</div>
                    <div className="widget_superadmin_partner_label_spc">주소</div>
                    <div className="widget_superadmin_partner_label_spc">비고</div>
                  </div>
                  <div className="widget_superadmin_partner_right">
                    {/* 고객사 검색 및 선택 https://www.telerik.com/kendo-react-ui/components/dropdowns/multicolumncombobox/custom-rendering/ */}
                    {/* <div className="widget_superadmin_partner_label">
    <input
      type="text"
      placeholder="고객사를 번호를 입력해주세요"
      className="widget_superadmin_partner_input"
      value={editCompanyCode}
      onChange={(e) => setEditCompanyCode(e.target.value)}
    />
  </div> */}
                    <div className="widget_superadmin_partner_label">
                      <input
                        type="text"
                        placeholder="고객사를 입력해주세요"
                        className="widget_superadmin_partner_input"
                        value={editCompanyName}
                        onChange={(e) => setEditCompanyName(e.target.value)} />
                    </div>
                    <div className="widget_superadmin_partner_label">
                      <input
                        type="text"
                        placeholder="사업자번호를 입력해주세요"
                        className="widget_superadmin_partner_input"
                        value={editRegistrationNumber}
                        onChange={(e) => setEditRegistrationNumber(e.target.value)} />
                    </div>
                    <div className="widget_superadmin_partner_label">
                      {/* <input
    type="text"
    placeholder="관리자를 입력해주세요"
    className="widget_superadmin_partner_input"
    value={editAdministrator}
    onChange={(e) => setEditAdministrator(e.target.value)}
  /> */}
                      <select
                        id="widget_superadmin_partner_input"
                        className='admin_partner_choose_form'
                        style={{ height: '36px' }}
                        value={editAdministrator}
                        onChange={(e) => setEditAdministrator(e.target.value)}
                      >
                        <option value="" hidden>담당자 선택</option>
                        {userData.map(user => (
                          user.cId === companyInfo?.cId &&
                          // <option key={user.uId} defaultValue={user.uId}>{user.uName}</option>
                          <option key={user.uId} value={user.uId}>{user.uName}</option>
                        ))}

                        {/* <option value={companyInfo?.uId}>{companyInfo?.uName}</option> */}
                      </select>
                      {!showEditUserInput && (
                        <div className="superadmin_icon_pack_widget" onClick={() => setEditShowUserInput(true)}>
                          <i className="fa fa-plus" aria-hidden="true" />
                        </div>
                      )}
                      {showEditUserInput && (
                        <div className="superadmin_icon_pack_widget" onClick={() => setEditShowUserInput(false)}>
                          <i className="fa fa-minus" aria-hidden="true" />
                        </div>
                      )}
                      <div className="superadmin_icon_pack_widget" onClick={getAdminInfo} hidden={!(editAdministrator)}>
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="widget_superadmin_partner_label">
                      <input
                        type="text"
                        placeholder="대표번호를 입력해주세요"
                        className="widget_superadmin_partner_input"
                        value={editCompanyPhoneNumber}
                        onChange={(e) => setEditCompanyPhoneNumber(e.target.value)} />
                    </div>
                    <div className="widget_superadmin_partner_label">
                      <input
                        className="widget_superadmin_partner_input"
                        type="text"
                        placeholder="우편번호를 입력해주세요"
                        value={editPostalCode}
                        onChange={(e) => setEditPostalCode(e.target.value)} />
                      {/* 아래 요소를 주석 처리하면 버튼 사라짐 (우편번호 검색시 사용), 새창으로 열기해서 해주세요 영교님 */}
                      {/* <div
    className="superadmin_icon_pack_widget"
    // onClick={() => window.open('https://www.epost.go.kr/search.RetrieveIntegrationNewZipCdList.comm')}
  >
    <i className="fa fa-search" aria-hidden="true" />
  </div> */}
                    </div>
                    <div className="widget_superadmin_partner_label_spc">
                      <textarea
                        className="admin_answer_content"
                        // className="widget_superadmin_partner_input_spc"
                        // type="text"
                        placeholder="주소를 입력해주세요"
                        value={editAddress}
                        onChange={(e) => setEditAddress(e.target.value)}
                        style={{ minHeight: '64px', resize: 'none', borderRadius: '8px' }} />
                    </div>
                    <div className="widget_superadmin_partner_label_spc">
                      <textarea
                        className="admin_answer_content"
                        // <input
                        //  type="text"
                        //  className="widget_superadmin_partner_input_spc"
                        placeholder="특이사항을 적어주세요"
                        value={editNote}
                        onChange={(e) => setEditNote(e.target.value)}
                        style={{ minHeight: '64px', resize: 'none', borderRadius: '8px' }} />
                    </div>
                  </div>
                  {/* <div class="superadmin_product_list_resist_input_typeB_icon">
        <div class="superadmin_icon_pack">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
        <div class="superadmin_icon_pack">
            <i class="fa fa-pencil-alt" aria-hidden="true"></i>
        </div>
        
        
    </div> */}
                </div>
                {showEditUserInput && (
                  <div className="widget">
                    <h4 className="title_widget">파트너사 관리자 생성하기</h4>
                    <div className="widget_user_resist_area">
                      <div className="widget_user_resist_left">
                        <div className="widget_user_resist_left_label">ID :</div>
                        <div className="widget_user_resist_left_label">Name :</div>
                        <div className="widget_user_resist_left_label">E-mail :</div>
                        <div className="widget_user_resist_left_label">phone :</div>
                        <div className="widget_user_resist_left_label">부서 :</div>
                        <div className="widget_user_resist_left_label">직급 :</div>
                      </div>
                      <div className="widget_user_resist_right">
                        <div className="widget_user_resist_right_label">
                          <input type="text" className="widget_user_resist_input" placeholder='아이디는 이메일 형식입니다.'
                            onChange={(e) => handleUserAdd(e, 'id')} />
                        </div>
                        <div className="widget_user_resist_right_label">
                          <input type="text" className="widget_user_resist_input"
                            // onChange={(e) => handleUserAdd(e, 'name')}
                            value={addUser?.name}
                            onChange={(e) => {
                              const value = e.target.value;
                              // 한글과 영어만 허용하는 정규식
                              const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]*$/;
                              // 입력된 값에서 한글과 영어 이외의 문자를 제거
                              if (regex.test(value)) {
                                handleUserAdd(e, 'name');
                              }
                            } } />
                        </div>
                        <div className="widget_user_resist_right_label">
                          <input type="text" className="widget_user_resist_input"
                            onChange={(e) => handleUserAdd(e, 'email')} />
                        </div>
                        <div className="widget_user_resist_right_label">
                          <input type="text" className="widget_user_resist_input"
                            onChange={(e) => handleUserAdd(e, 'phone')} />
                        </div>
                        <div className="widget_user_resist_right_label">
                          <input type="text" className="widget_user_resist_input"
                            onChange={(e) => handleUserAdd(e, 'dept')} />
                        </div>
                        <div className="widget_user_resist_right_label">
                          <input type="text" className="widget_user_resist_input"
                            onChange={(e) => handleUserAdd(e, 'rank')} />
                        </div>

                        {/* <div class="widget_user_resist_right_label"><input type="password" class="widget_user_resist_input"></div> */}
                      </div>
                    </div>
                    <div className="widget_user_resist_area_bt">
                      <a
                        // style={{ cursor: 'pointer' }}
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        // onClick={() => { setPopupMessage("등록되었습니다."); setShowPopup(true); }}
                        onClick={() => { handelInsertUserInfo(); } }
                      >
                        사용자 등록
                      </a>
                    </div>
                  </div>
                )}
                <div className="widget">
                  <h4 className="title_upload">파일 첨부
                    {/* 첨부파일이있으면 보여주기 */}
                    {companyInfo?.cFileName && (
                      <a
                        className="btn btn-theme border btn-xs"
                        data-animation="animated slideInUp"
                        onClick={downloadAttachedFile}
                      >
                        첨부파일 다운로드
                      </a>
                    )}
                  </h4>
                  <div className="widget_user_resist_area">
                    <div className="file-upload fillwidth">
                      {/* <input
    type="text"
    className="upload-name"
    defaultValue="파일을 첨부해주세요"
    disabled
  />
  <label htmlFor="ex_filename" className="bulk_upload_BT">
    파일 선택
  </label>
  <input type="file" id="ex_filename" className="upload-hidden" /> */}
                      <input
                        type="text"
                        className="upload-name"
                        value={selectedFile ? selectedFile.name : (companyInfo?.cFileName || "파일을 첨부해주세요")}
                        disabled />
                      <label htmlFor="ex_filename" className="bulk_upload_BT">
                        파일 선택
                      </label>
                      <input
                        type="file"
                        id="ex_filename"
                        className="upload-hidden"
                        onChange={handleFileChange} />
                    </div>
                  </div>
                </div>
                <div className="widget_user_resist_area_bt">
                  {/* <a
    href="#"
    className="btn btn-theme border btn-sm"
    data-animation="animated slideInUp"
    onClick={handelUpdateUserInfo}
  >
    등록하기
  </a> */}
                  <a
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                    onClick={handelEditInfo}
                  >
                    수정하기
                  </a>
                </div>
              </div>
              {/* 여기서부턴 담당자 정보조회칸입니다 */}
              <div className="widget" hidden={!(openUserInfo)}>
                    <h4 className="title_widget">파트너사 담당자 정보</h4>
                    <div className="widget_user_resist_area_bl">
                    <div className="widget_user_resist_left_label_bl">
                        Name : {companyInfo?.uName}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          readOnly
                          className="widget_user_resist_input_bl"
                          value={companyInfo?.uName ?? ''}
                          autoComplete='off'
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        E-mail : {userData[0]?.uEmail}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          readOnly
                          className="widget_user_resist_input_bl"
                          value={userData[0]?.uEmail}
                          autoComplete='off'
                          onChange={(e) => handleChange(e, 'email')}
                          maxLength={500}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        Phone : {userData[0]?.uPhone}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          readOnly
                          className="widget_user_resist_input_bl"
                          value={userData[0]?.uPhone ?? ''}
                          autoComplete='off'
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        부서 : {userData[0]?.uDept}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          readOnly
                          className="widget_user_resist_input_bl"
                          value={userData[0]?.uDept ?? ''}
                          autoComplete='off'
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        직급 : {userData[0]?.uRank}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          readOnly
                          className="widget_user_resist_input_bl"
                          value={userData[0]?.uRank ?? ''}
                          autoComplete='off'
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        권한 : {userData[0]?.uAdmin}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                      <input
                          type="text"
                          readOnly
                          className="widget_user_resist_input_bl"
                          value={userData[0]?.uAdmin ?? ''}
                          autoComplete='off'
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div>

                    </div>
                    {/* <div className="widget_user_resist_area_bt">
                      <a
                        // style={{ cursor: 'pointer' }}
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        // onClick={() => { setPopupMessage("수정되었습니다."); setShowPopup(true); }}
                        // onClick={handelUpdateUserInfo}
                      >
                        정보 수정
                      </a>
                    </div> */}
                  </div>
              </div>
            ) : (
              <div>
                <div className="widget">
                  <h4 className="title_widget">파트너사 정보 등록하기</h4>
                  <div className="widget_superadmin_contract_area">
                    <div className="widget_superadmin_partner_left">
                      {/* <div className="widget_superadmin_partner_label">고객사번호</div> */}
                      <div className="widget_superadmin_partner_label">고객사명</div>
                      <div className="widget_superadmin_partner_label">사업자번호</div>
                      {/* <div className="widget_superadmin_partner_label">관리자</div> */}
                      <div className="widget_superadmin_partner_label">대표전화</div>
                      <div className="widget_superadmin_partner_label">우편번호</div>
                      <div className="widget_superadmin_partner_label_spc">주소</div>
                      <div className="widget_superadmin_partner_label_spc">비고</div>
                    </div>
                    <div className="widget_superadmin_partner_right">
                      {/* 고객사 검색 및 선택 https://www.telerik.com/kendo-react-ui/components/dropdowns/multicolumncombobox/custom-rendering/ */}
                      {/* <div className="widget_superadmin_partner_label">
    <input
      type="text"
      placeholder="고객사를 번호를 입력해주세요"
      className="widget_superadmin_partner_input"
      onChange={(e) => setCompanyCode(e.target.value)}
    />
  </div> */}
                      <div className="widget_superadmin_partner_label">
                        <input
                          type="text"
                          placeholder="고객사를 입력해주세요"
                          className="widget_superadmin_partner_input"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)} />
                      </div>
                      <div className="widget_superadmin_partner_label">
                        <input
                          type="text"
                          placeholder="사업자번호를 입력해주세요"
                          className="widget_superadmin_partner_input"
                          value={registrationNumber}
                          onChange={(e) => setRegistrationNumber(e.target.value)} />
                      </div>
                      {/* <div className="widget_superadmin_partner_label"> */}
                      {/* <input
      type="text"
      placeholder="관리자를 입력해주세요"
      className="widget_superadmin_partner_input"
      onChange={(e) => setAdministrator(e.target.value)}
    /> */}
                      {/* <select
      id="widget_superadmin_partner_input"
      className='admin_partner_choose_form'
      style={{ height: '36px' }}
      value={administrator}
      onChange={(e) => setAdministrator(e.target.value)}
    >
      {userData2.map(user => (
        user.cId === toInsertCid &&
        <option key={user.uId} value={user.uId}>{user.uName}</option>
      ))} */}

                      {/* <option value={companyInfo?.uId}>{companyInfo?.uName}</option> */}
                      {/* </select> */}
                      {/* <div className="superadmin_icon_pack_widget" >
      <i className="fa fa-plus" aria-hidden="true" />
    </div> */}
                      {/* </div> */}
                      <div className="widget_superadmin_partner_label">
                        <input
                          type="text"
                          placeholder="대표번호를 입력해주세요"
                          className="widget_superadmin_partner_input"
                          value={companyPhoneNumber}
                          onChange={(e) => setCompanyPhoneNumber(e.target.value)} />
                      </div>
                      <div className="widget_superadmin_partner_label">
                        <input
                          type="text"
                          className="widget_superadmin_partner_input"
                          placeholder="우편번호를 입력해주세요"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)} />
                        {/* 아래 요소를 주석 처리하면 버튼 사라짐 (우편번호 검색시 사용), 새창으로 열기해서 해주세요 영교님 */}
                        {/* <div
    className="superadmin_icon_pack_widget"
    // onClick={() => window.open('https://www.epost.go.kr/search.RetrieveIntegrationNewZipCdList.comm')}
  >
    <i className="fa fa-search" aria-hidden="true" />
  </div> */}
                      </div>
                      <div className="widget_superadmin_partner_label_spc">
                        <textarea
                          className="admin_answer_content"
                          style={{ minHeight: '64px', resize: 'none', borderRadius: '8px' }}
                          //  <input
                          // type="text"
                          // className="widget_superadmin_partner_input_spc"
                          placeholder="주소를 입력해주세요"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)} />
                      </div>
                      <div className="widget_superadmin_partner_label_spc">
                        <textarea
                          className="admin_answer_content"
                          style={{ minHeight: '64px', resize: 'none', borderRadius: '8px' }}
                          // <input
                          // type="text"
                          // className="widget_superadmin_partner_input_spc"
                          placeholder="특이사항을 적어주세요"
                          value={note}
                          onChange={(e) => setNote(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  {showUserInput && (
                    <div className="widget">
                      <h4 className="title_widget">파트너사 관리자 등록 하기</h4>
                      <div className="widget_user_resist_area">
                        <div className="widget_user_resist_left">
                          <div className="widget_user_resist_left_label">ID :</div>
                          <div className="widget_user_resist_left_label">Name :</div>
                          <div className="widget_user_resist_left_label">E-mail :</div>
                          <div className="widget_user_resist_left_label">phone :</div>
                          <div className="widget_user_resist_left_label">부서 :</div>
                          <div className="widget_user_resist_left_label">직급 :</div>
                        </div>
                        <div className="widget_user_resist_right">
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              placeholder='아이디는 이메일 형식입니다.'
                              onChange={(e) => handleChange(e, 'id')} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleChange(e, 'name')} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleChange(e, 'email')} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleChange(e, 'phone')} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleChange(e, 'dept')} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleChange(e, 'rank')} />
                          </div>

                        </div>
                      </div>
                      {/* <div className="widget_user_resist_area_bt">
          <a
            className="btn btn-theme border btn-sm"
            data-animation="animated slideInUp"
            onClick={() => { handelInsertWhenAddUserInfo() }}
          >
            사용자 등록
          </a>
        </div> */}
                    </div>
                  )}
                  {showUserInput && (
                    <div className="widget">
                      <h4 className="title_upload">파일 첨부</h4>
                      <div className="widget_user_resist_area">
                        <div className="file-upload fillwidth">
                          {/* <input
          type="text"
          className="upload-name"
          defaultValue="파일을 첨부해주세요"
          disabled
        />
        <label htmlFor="ex_filename" className="bulk_upload_BT">
          파일 선택
        </label>
        <input type="file" id="ex_filename" className="upload-hidden" /> */}
                          <input
                            type="text"
                            className="upload-name"
                            value={selectedFile2 ? selectedFile2.name : "파일을 첨부해주세요"}
                            disabled />
                          <label htmlFor="ex_filename2" className="bulk_upload_BT">
                            파일 선택
                          </label>
                          <input
                            type="file"
                            id="ex_filename2"
                            className="upload-hidden"
                            onChange={handleFileChange2} />
                        </div>
                      </div>
                    </div>
                  )}
                  ※ 기본 정보를 입력하셔야 담당자와 파일을 첨부할 수 있습니다.
                  <div className="widget_user_resist_area_bt">
                    {/* 첫번째등록 */}
                    {!showUserInput && (
                      <a
                        style={{ cursor: 'pointer' }}
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        onClick={handelInsertInfo}
                      >
                        등록하기
                      </a>
                    )}
                    {showUserInput && (
                      <a
                        style={{ cursor: 'pointer' }}
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        onClick={handelInsertWhenAddUserInfo}
                      >
                        파트너사 관리자 등록하기
                      </a>
                    )}
                    {/* <a
    href="#"
    className="btn btn-theme border btn-sm"
    data-animation="animated slideInUp"
  >
    수정하기
  </a> */}
                  </div>
                </div>
                <div className="widget">
                  <h4 className="title_upload">
                    파트너사 정보 대량 업로드
                    <a
                      className="btn btn-theme border btn-xs"
                      data-animation="animated slideInUp"
                      style={{ cursor: "pointer" }}
                      onClick={downloadPartnerFile}
                    >
                      양식 다운로드
                    </a>
                  </h4>
                  <div className="widget_user_resist_area">
                    <div className="file-upload">
                      <input
                        type="text"
                        className="upload-name"
                        readOnly
                        value={selectedPartnerFile ? selectedPartnerFile?.name : "엑셀파일을 선택해주세요"} />
                      <label htmlFor="ex_filename" className="bulk_upload_BT">
                        파일 선택
                      </label>
                      <input type="file" id="ex_filename" className="upload-hidden" onChange={handlePartnerFileChange} />
                    </div>
                  </div>
                  <div className="widget_user_resist_area_bt">
                    <a
                      className="btn btn-theme border btn-sm"
                      data-animation="animated slideInUp"
                      onClick={excelFileUploadPartner}
                    >
                      엑셀 업로드
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

        </nav>

      </header>
    </div>
  )
}

export default MasterPartnerHeader