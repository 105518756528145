import React, { useEffect, useState, useRef, ForwardedRef, forwardRef, ChangeEvent } from 'react'
import { clientConfig } from '../api/clientConfig'
import axios from 'axios';
import { getUserInfoFromStorage } from '../utils/userInfoModule';
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import Popup from './Popup/Popup';
import { excelFileDownload, excelFileUpload } from '../utils/excelFile';
import DialogForm from './Dialog/DialogForm';
import Admin_License from '../pages/Admin/Admin_License';
import { useNavigate } from 'react-router-dom';

// import { Button } from "@progress/kendo-react-buttons";

interface Menu {
  mId: string;
  pdId: string;
  mName: string;
  mUrl: string;
  mType: string;
  mImageUrl: string;
  msId: string;
  msName: string;
  msUrl: string;
  msTarget: string;
}

interface EditUserInfo {
  id: string;
  email: string;
  name: string;
  phone: string;
  dept: string;
  rank: string;
  admin: string;
}

interface AddUserInfo {
  id: string;
  email: string;
  name: string;
  phone: string;
  dept: string;
  rank: string;
  admin: string;
}

// Admin_License에서 선택한 유저정보 가져오기
interface HeaderProps {
  seletedRowUserInfo?: any;
  onRerender?: () => void;
  handlePageChange?: number;
}



// const Header = ({ data }): HeaderProps => {
// const Header = React.forwardRef((props, ref) => {
const Header = forwardRef((props: HeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
  // URL 이동
  const navigate = useNavigate();
  // Admin_License에서 선택한 유저정보 가져오기
  const { seletedRowUserInfo, onRerender, handlePageChange } = props;
  // console.log('handlePageChange_Header : ', handlePageChange);
  // console.log('propspropsprops: ', seletedRowUserInfo?.uEmail ?? '' );
  // PRODUCT 매뉴 상태 관리
  const [menuData, setMenuData] = useState<Array<Menu>>([]);
  // 고객센터 매뉴 상태 관리
  const [menuData2, setMenuData2] = useState<Array<Menu>>([]);
  // 검색창 보여주기 상태 관리
  const [isSearchVisible, setSearchVisible] = useState(false);
  // 위젯창 보여주기 상태 관리
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);
  // 괸리자헤더 상태 설정
  const [isShowAdminHeader, setIsShowAdminHeader] = useState(false);
  // 세선스토리지에서 정보가져오기
  const userInfoObject = getUserInfoFromStorage();
  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };
  // 파일업로드 상태 관리
  const [file, setFile] = useState<File | null>(null);
  const inputFileName = file?.name
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogMessage2, setDialogMessage2] = useState('');
  const [dialogMessage3, setDialogMessage3] = useState('');

  const handleOpenDialog = () => {
    setDialogOpen(true);
    // 선언된 부모컴포넌트로 받아 정보업데이트
    onRerender && onRerender();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConformDialog = () => {
    // 사용자가 대화상자를 확인할 때 특정 로직을 처리할 수 있습니다.
    handleCloseDialog();
  };

  // 파일업로드 변경 감지
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    console.log('selectedFile : ', selectedFile);
    console.log('File : ', selectedFile?.name);

    // 파일 확장자 확인
    const allowedExtensions = ['xlsx', 'xls'];
    const fileExtension = selectedFile?.name.split('.').pop()?.toLowerCase() || '';
    // console.log('fileExtension :', fileExtension);
    if (allowedExtensions.includes(fileExtension)) {
      setFile(selectedFile || null);
    } else {
      // 선택한 파일의 확장자가 허용되지 않으면 안내 팝업 표시
      console.error('지원하지 않는 파일 형식입니다. .xlsx 또는 .xls 파일을 선택해주세요.');
      setPopupMessage("지원하지 않는 파일 형식입니다. .xlsx 또는 .xls 파일을 선택해주세요.");
      setShowPopup(true);
    }
  };


  // 업로드 버튼클릭
  const handleExcelUpload = () => {
    // console.log('handleExcelUpload : ', handleExcelUpload);
    if (file) {
      // 업로드
      excelFileUpload(file)
        // .then(({ duplicateIds, duplicateEmails }) => {
        .then((response) => {
          const { duplicateIds, duplicateEmails } = response || {};  // 기본값으로 빈 객체 사용
          // 중복 아이디와 이메일을 받아와서 다이얼로그 메시지 설정
          console.log(duplicateIds, duplicateEmails);
          // 다이어로그창 열기
          handleOpenDialog();
          // 빈 배열인 경우에 대한 처리 추가
          const message =
            Array.isArray(duplicateIds) && duplicateIds.length > 0 || Array.isArray(duplicateEmails) && duplicateEmails.length > 0
              ? '를 제외하고 업로드 되었습니다'
              : '업로드 되었습니다';

          const duplicateIdsMessage =
            Array.isArray(duplicateIds) && duplicateIds.length > 0
              ? `중복된 아이디: ${duplicateIds.join(', ')}`
              : '';

          const duplicateEmailsMessage =
            Array.isArray(duplicateEmails) && duplicateEmails.length > 0
              ? `중복된 이메일: ${duplicateEmails.join(', ')}`
              : '';
          //  다이어로그창 메세지
          setDialogMessage(`${duplicateIdsMessage}`);
          setDialogMessage2(`${duplicateEmailsMessage}`);
          setDialogMessage3(`${message}`);
        })

        .catch((error) => {
          console.error('Error during file upload:', error);
          setPopupMessage("파일 업로드 중 오류가 발생했습니다.");
          setShowPopup(true);
        });

    } else {
      // 파일이 선택되지 않았을 때의 처리
      console.error('파일을 선택해주세요.');
      setPopupMessage("파일을 선택해주세요.");
      setShowPopup(true);

    }
  };



  // 유저 수정 정보 관리
  const [editUser, setEditUser] = useState<EditUserInfo>({
    id: '',
    email: '',
    name: '',
    dept: '',
    phone: '',
    rank: '',
    admin: '',
  })

  // 유저 등록 정보
  const [addUser, setAddUser] = useState<AddUserInfo>({
    id: '',
    email: '',
    name: '',
    dept: '',
    phone: '',
    rank: '',
    admin: '일반',
  })


  // 관리헤더 useEffect
  useEffect(() => {
    // 권한 가져오기
    setEditUser({
      ...editUser,
      id: seletedRowUserInfo?.uLoginId ?? userInfoObject?.uLoginId,
      email: seletedRowUserInfo?.uEmail ?? userInfoObject?.uEmail,
      name: seletedRowUserInfo?.uName ?? userInfoObject?.uName,
      dept: seletedRowUserInfo?.uDept ?? userInfoObject?.uDept,
      phone: seletedRowUserInfo?.uPhone ?? userInfoObject?.uPhone,
      rank: seletedRowUserInfo?.uRank ?? userInfoObject?.uRank,
      admin: seletedRowUserInfo?.uAdmin ?? userInfoObject?.uAdmin,
    });

    // 현재 페이지의 URL을 가져오기
    const currentURL = window.location.href;

    // URL이 " ~/admin_license "로 끝나면 관리헤더를 보이게 함
    // if (currentURL.includes("/admin_license")) {
    //   setIsShowAdminHeader(true);
    // } else {
    //   setIsShowAdminHeader(false);
    // }
  }, [seletedRowUserInfo])

  // 사용자 관리페이지일때만 보여주기
  useEffect(() => {
    if (handlePageChange === 3) {
      setIsShowAdminHeader(true);
    } else {
      setIsShowAdminHeader(false);
    }
  }, [handlePageChange])


  // 검색창 열고 닫기
  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
    console.log('toggleSearch 동작!');
  };
  // 위젯창 열고 닫기
  const toggleSideMenu = () => {
    setSideMenuVisible(true);
    // 권한 가져오기
    setEditUser({
      ...editUser,
      id: seletedRowUserInfo?.uLoginId ?? userInfoObject?.uLoginId,
      email: seletedRowUserInfo?.uEmail ?? userInfoObject?.uEmail,
      name: seletedRowUserInfo?.uName ?? userInfoObject?.uName,
      dept: seletedRowUserInfo?.uDept ?? userInfoObject?.uDept,
      phone: seletedRowUserInfo?.uPhone ?? userInfoObject?.uPhone,
      rank: seletedRowUserInfo?.uRank ?? userInfoObject?.uRank,
      admin: seletedRowUserInfo?.uAdmin ?? userInfoObject?.uAdmin,
    });
  };

  // 위젯창의 X버튼 눌러서 위젯창 닫기
  const closeSideMenu = () => {
    setSideMenuVisible(false);
    console.log('toggleSideMenu 닫기 동작!');
  };

  useEffect(() => {
    if (ref) {
      // @ts-ignore 외부호출 위젯창 동작
      ref.current = { toggleSideMenu };
    }
  }, [toggleSideMenu, ref]);

  // 로그아웃 버튼 클릭 시 sessionStorage에서 isLoggedIn 정보 삭제
  const handleLogout = () => {
    //@ts-ignore
    sessionStorage.clear();
    window.location.reload();
    console.log('로그아웃');
  };

  // PRODUCT 메뉴 DB조회
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu`, {
        })
        if (response.data.length > 0) {
          setMenuData(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_PRODUCT fetchData:', error);
      }
    };

    fetchData();
  }, []);

  // 고객센터 메뉴 DB조회
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu2`, {
        })
        if (response.data.length > 0) {
          setMenuData2(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_고객센터 fetchData:', error);
      }
    };

    fetchData2();
  }, []);

  // 유저 정보 수정값 관리
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    // console.log('handleChange : ' , e);
    // console.log('handleChangetypeof : ' , typeof e);
    setEditUser({
      ...editUser,
      [field]: e.target.value,
    });
  };

  // 유저 정보 수정값 중 DropDownList의 onChange 이벤트 핸들러
  const handleAdminChange = (event: DropDownListChangeEvent) => {
    const selectedValue = event.value;
    // console.log('selectedValue', selectedValue);
    setEditUser({
      ...editUser,
      admin: selectedValue,
    });
  };

  // 유저 정보 등록값 관리
  const handleUserAdd = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    // console.log('handleChange : ' , e);
    // console.log('handleChangetypeof : ' , typeof e);
    setAddUser({
      ...addUser,
      [field]: e.target.value,
    });
  };

  // 유저 정보 등록값 중 DropDownList의 onChange 이벤트 핸들러
  const handleAddAdmin = (event: DropDownListChangeEvent) => {
    const selectedValue = event.value;
    // console.log('selectedValue', selectedValue);
    setAddUser({
      ...addUser,
      admin: selectedValue,
    });
  };
  // console.log('seletedRowUserInfo.cId', seletedRowUserInfo?.cId ?? userInfoObject.cId);
  // console.log('seletedRowUserInfo.uId', seletedRowUserInfo?.uId ?? userInfoObject.uid);

  // 유저 정보 업데이트
  const handelUpdateUserInfo = async () => {
    // console.log('handelUpdateUserInfo동작 : ', handelUpdateUserInfo);
    if (!userInfoObject) {
      setPopupMessage("올바른 접근이 아닙니다!");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.email) {
      setPopupMessage("이메일이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.id) {
      setPopupMessage("아이디가 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.name) {
      setPopupMessage("이름이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.admin) {
      setPopupMessage("권한을 설정해주세요");
      setShowPopup(true);
      return false;
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(editUser.email)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }
    else {

      try {
        const response = await axios.post(`${clientConfig.URL}/updateUserInfo`, {
          cId: seletedRowUserInfo?.cId ?? userInfoObject.cId,
          uId: seletedRowUserInfo?.uId ?? userInfoObject.uid,
          uLoginId: editUser.id,
          uName: editUser.name,
          uEmail: editUser.email,
          uPhone: editUser.phone,
          uDept: editUser.dept,
          uRank: editUser.rank,
          uAdmin: editUser.admin,
        })
        // console.log('response : ', response);
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          setPopupMessage("수정되었습니다.");
          setShowPopup(true);
          // 선택한 사용자 정보의 uName을 수정된 이름으로 업데이트
          if (seletedRowUserInfo) {
            seletedRowUserInfo.uName = editUser.name;
            seletedRowUserInfo.uEmail = editUser.email;
            seletedRowUserInfo.uPhone = editUser.phone;
            seletedRowUserInfo.uDept = editUser.dept;
          }
          // onRerender && onRerender();
        } else {
          setPopupMessage("수정 실패.");
          setShowPopup(true);
        }
      } catch (error: any) {
        console.error('updateUserInfo / error :', error);
        if (error.response && error.response.status === 400) {
          setPopupMessage("아이디가 중복됩니다.");
          setShowPopup(true);
          // setPopupMessage(error.response.data.error);
          // setShowPopup(true);
        } else {
          setPopupMessage("수정 중 서버오류가 발생했습니다.");
          setShowPopup(true);
        }
      }
    }
    // 선언된 부모컴포넌트로 받아 정보업데이트
    onRerender && onRerender();
  }

  // 유저등록 요청
  const handelInsertUserInfo = async () => {

    if (!userInfoObject) {
      setPopupMessage("올바른 접근이 아닙니다!");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.name) {
      setPopupMessage("이름이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.id) {
      setPopupMessage("아이디가 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(addUser.id)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.email) {
      setPopupMessage("이메일이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(addUser.email)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.admin) {
      setPopupMessage("권한을 설정해주세요");
      setShowPopup(true);
      return false;
    } 

    else {

      try {
        const response = await axios.post(`${clientConfig.URL}/insertUserInfo`, {
          cId: userInfoObject.cId,
          uLoginId: addUser.id,
          uName: addUser.name,
          uEmail: addUser.email,
          uPhone: addUser.phone,
          uDept: addUser.dept,
          uRank: addUser.rank,
          uAdmin: addUser.admin,
        })
        // console.log('response : ', response);
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          // setPopupMessage("등록 되었습니다. 임시 비밀번호는 이메일로 발송됩니다");
          setPopupMessage("등록 되었습니다. 비밀번호는 1111");
          setShowPopup(true);
          // 임시비밀번호 이메일 발송
          // handleForgotPassword()
        } else {
          setPopupMessage("등록 실패.");
          setShowPopup(true);
        }
      } catch (error: any) {
        console.error('insertUserInfo / error :', error);
        if (error.response && error.response.status === 400) {
          setPopupMessage("아이디가 중복됩니다.");
          setShowPopup(true);
          // setPopupMessage(error.response.data.error);
          // setShowPopup(true);
        } else {
          setPopupMessage("등록 중 서버오류가 발생했습니다.");
          setShowPopup(true);
        }
      }
    }
    // 선언된 부모컴포넌트로 받아 정보업데이트
    onRerender && onRerender();
  }

  // 이메일발송
  // 아이디 확인 및 이메일 발송
  const handleForgotPassword = async () => {
    // const handleForgotPassword = async () => {
    // event.preventDefault();

    try {
      // 아이디로 이메일 조회 ${clientConfig.URL}
      const responseEmail = await axios.post(`${clientConfig.URL}/getEmail`, {
        uLoginId: addUser.id,
      });
      // alert();
      const { success, uEmail } = responseEmail.data;
      // console.log('responseEmail.data');
      // console.log(responseEmail.data);
      if (success) {
        // 이메일 전송
        const response = await axios.post(`${clientConfig.URL}/sendemail`, {
          toEmail: addUser.email, // 조회된 이메일로 전송
          subject: '임시 비밀번호 발급',
          text: '임시 비밀번호를 발급합니다.',
          uLoginId: addUser.id
        });

        if (response.data.success) {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일이 성공적으로 보내졌습니다.`);
        } else {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일 보내기 실패: ${response.data.message}`);
        }
      } else {
        // 팝업 메시지 설정
        setPopupMessage("이메일를 확인해주세요.");
      }

      // 팝업 열기
      setShowPopup(true);
    } catch (error) {
      // sendLog('Error handleForgotPassword /getEmail', `Error details : ${error}`, uLoginId, getFileName);
      console.error('이메일 조회 오류:', error);
      // 팝업 메시지 설정
      setPopupMessage("서버 오류 관리자에게 문의해 주세요.");
      // 팝업 열기
      setShowPopup(true);
    }
  };

  // 문의하기 페이지로 이동
  // const moveURL = () => {
  //   window.location.href = `/user_license?userPage=2`
  // }
  const moveURL = () => {
    navigate(`/user_license?userPage=2`)
  }

  return (
    <div>
      {/* DialogForm 컴포넌트 */}
      {isDialogOpen && (
        <DialogForm
          setTitle=" "
          message={dialogMessage}
          message2={dialogMessage2}
          message3={dialogMessage3}
          onClose={handleCloseDialog}
          onConform={handleConformDialog}
        />
      )}
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}

      {/* <!-- Header  ============================================= --> */}
      <header id="home">
        {/* <!-- Start Navigation --> */}
        <nav className="navbar navbar-default navbar-fixed dark bootsnav">
          {/* <!-- Start Top Search --> */}
          <div className="container_box"></div>
          <div className="container">
            <div className="row">
              {/* <div className={`top-search ${isSearchVisible ? 'visible' : 'hidden'}`}> */}
              <div className="top-search" style={{ display: isSearchVisible ? 'block' : 'none' }}>
                <div className="input-group">
                  <form action="#">
                    <input type="text" name="text" className="form-control" placeholder="서비스 및 라이선스 검색" />
                    <button type="submit">
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Top Search --> */}

          <div className="container">

            {/* <!-- Start Atribute Navigation --> */}
            <div className="attr-nav">
              <ul>
                {/* <li className="search"><a style={{ cursor: 'pointer' }} onClick={handleClick} ><i className="fa fa-search"></i></a></li> */}
                {/* 검색창 */}
                <li className="search"><a style={{ cursor: 'pointer' }} onClick={toggleSearch} ><i className="fa fa-search"></i></a></li>
                {/* 위젯창 */}
                <li className="side-menu"><a style={{ cursor: 'pointer' }} onClick={toggleSideMenu}><i className="fa fa-bars"></i></a></li>
              </ul>
            </div>
            {/* <!-- End Atribute Navigation --> */}

            {/* <!-- Start Header Navigation --> */}
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                <i className="fa fa-bars"></i>
              </button>
              <a className="navbar-brand" href="/main">
                {/* <img src="assets/img/logo.png" className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" /> */}
                <img src={`${clientConfig.URL}/image/logo.png`} className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" />
              </a>
            </div>
            {/* <!-- End Header Navigation --> */}

            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >Product</a>
                  <ul className="dropdown-menu">
                    {/* <Button>Browse</Button> */}
                    {menuData.map(item => <li><a href={item.msUrl} target={item.msTarget} key={item.pdId}>{item.msName}</a></li>)}

                    {/* <li><a style={{ cursor: 'pointer' }}>InnoChecker</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoR3D</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoM3D</a></li>
                    <li><a style={{ cursor: 'pointer' }}>INNOVA ISO</a></li>
                    <li><a style={{ cursor: 'pointer' }}>INNOVA Clash Detector</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoPCF</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoFMS</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoRevit</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoNavisWorks</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoRPA</a></li> */}
                  </ul>
                </li>

                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >고객센터</a>
                  <ul className="dropdown-menu">
                    {menuData2.map(item => <li><a href={item.msUrl} key={item.pdId}>{item.msName}</a></li>)}

                    {/* <li><a style={{ cursor: 'pointer' }}>공지사항</a></li>
                    <li><a style={{ cursor: 'pointer' }}>자주하는 질문</a></li>
                    <li><a style={{ cursor: 'pointer' }}>1:1 문의하기</a></li>
                    <li><a style={{ cursor: 'pointer' }}>솔루션 구입문의</a></li> */}
                  </ul>
                </li>

                <li>
                  {/* <a href="login">Login</a> */}
                  {/* 로그인 상태에 따라 렌더링되는 로그인/로그아웃 링크 */}
                  {/* @ts-ignore */}
                  {sessionStorage.getItem('isMasterLoggedIn') === 'true' || sessionStorage.getItem('isLoggedIn') === 'true' ? (
                    <a href="login" onClick={handleLogout}>Logout</a>
                  ) : (
                    <a href="login">Login</a>
                  )}
                </li>
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown">
                    내 메뉴
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="user_license">마이 페이지</a>
                    </li>
                    {/* @ts-ignore */}
                    {sessionStorage.getItem('isMasterLoggedIn') === 'true' &&
                      <li>
                        <a href="master_license_management">
                          솔루션 관리자
                        </a>
                      </li>
                    }
                    {/* @ts-ignore */}
                    {(sessionStorage.getItem('isMasterLoggedIn') === 'true' || JSON.parse(sessionStorage.getItem('userInfo'))?.uAdmin === "관리") &&
                      <li>
                        <a href="admin_license">파트너 관리자</a>
                      </li>
                    }
                    {/* <li>
                      <a href="user_license">파트너 일반사용자</a>
                    </li> */}
                    {/* <li>
                      <a href="index">로그아웃</a>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </div>
            {/* <!-- /.navbar-collapse --> */}
          </div>

          {/* <!-- Start Side Menu --> */}
          {/* <div className="side"> */}
          {/* 위젯창 열고 닫기 side 클래스에 on 클래스 추가 또는 제거 */}
          <div className={`side ${isSideMenuVisible ? 'on' : ''}`} >
            {/* 위젯창 닫기 버튼 */}
            <a style={{ cursor: 'pointer' }} className="close-side" onClick={closeSideMenu}><i className="fa fa-times"></i></a>
            {isShowAdminHeader ? (
              <div>
                {seletedRowUserInfo ? (
                  <div className="widget">
                    <h4 className="title_widget">사용자 수정 하기</h4>
                    <div className="widget_user_resist_area_bl">
                    <div className="widget_user_resist_left_label_bl">
                        Name : {seletedRowUserInfo?.uName ?? userInfoObject?.uName}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          placeholder="수정할 이름을 입력해주세요"
                          className="widget_user_resist_input_bl"
                          value={editUser?.name ?? ''}
                          autoComplete='off'
                          // onChange={(e) => handleChange(e, 'name')}
                          onChange={(e) => {
                            const value = e.target.value;
                            // 한글과 영어만 허용하는 정규식
                            const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]*$/;
                            // 입력된 값이 한글 또는 영어일 경우에만 handleUserAdd 호출
                            if (regex.test(value)) {
                              handleChange(e, 'name');
                            }
                          }}
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        E-mail : {seletedRowUserInfo?.uEmail ?? userInfoObject?.uEmail}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          placeholder="수정할 E-mail을 입력해주세요"
                          className="widget_user_resist_input_bl"
                          value={editUser?.email ?? userInfoObject?.uEmail}
                          autoComplete='off'
                          onChange={(e) => handleChange(e, 'email')}
                          maxLength={500}
                        />
                      </div>
                      {/* <div className="widget_user_resist_left_label_bl" hidden>
                        ID : {seletedRowUserInfo?.uLoginId ?? userInfoObject?.uLoginId}
                      </div>
                      <div className="widget_user_resist_left_label_bl" hidden>
                        <input
                          type="text"
                          placeholder="수정할 ID를 입력해주세요"
                          className="widget_user_resist_input_bl"
                          value={editUser?.id ?? ''}
                          onChange={(e) => handleChange(e, 'id')}
                          hidden
                        />
                      </div> */}
                      <div className="widget_user_resist_left_label_bl">
                        Phone  : {seletedRowUserInfo?.uPhone ?? userInfoObject?.uPhone}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          placeholder="수정할 전화번호를 입력해주세요"
                          className="widget_user_resist_input_bl"
                          value={editUser?.phone ?? ''}
                          autoComplete='off'
                          onChange={(e) => handleChange(e, 'phone')}
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        부서 : {seletedRowUserInfo?.uDept ?? userInfoObject?.uDept}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          placeholder="수정할 부서를 입력해주세요"
                          className="widget_user_resist_input_bl"
                          value={editUser?.dept ?? ''}
                          autoComplete='off'
                          onChange={(e) => handleChange(e, 'dept')}
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        직급 : {seletedRowUserInfo?.uRank ?? userInfoObject?.uRank}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        <input
                          type="text"
                          placeholder="수정할 직급을 입력해주세요"
                          className="widget_user_resist_input_bl"
                          value={editUser?.rank ?? ''}
                          autoComplete='off'
                          onChange={(e) => handleChange(e, 'rank')}
                          maxLength={50}
                        />
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        권한 : {seletedRowUserInfo?.uAdmin ?? userInfoObject?.uAdmin}
                      </div>
                      <div className="widget_user_resist_left_label_bl">
                        {/* <input
                        type="text"
                        placeholder="수정할 권한을 선택해주세요"
                        className="widget_user_resist_input_bl"
                        // value={userInfoObject.uAdmin}
                        onChange={(e) => handleChange(e, 'admin')}
                      /> */}
                        <DropDownList
                          value={editUser?.admin ?? userInfoObject?.uAdmin}
                          // onChange={(e) => handleChange(e, 'admin')}
                          onChange={handleAdminChange}
                          data={['일반', '관리']}
                        />
                      </div>
                          {/* <div class="widget_user_resist_right">

                          <div class="widget_user_resist_right_label"><input type="text" class="widget_user_resist_input"></div>
                          <div class="widget_user_resist_right_label"><input type="text" class="widget_user_resist_input"></div>
                          <div class="widget_user_resist_right_label"><input type="text" class="widget_user_resist_input"></div>

                      </div> */}
                    </div>
                    <div>

                    </div>
                    <div className="widget_user_resist_area_bt">
                      <a
                        // style={{ cursor: 'pointer' }}
                        className="btn btn-theme border btn-sm"
                        data-animation="animated slideInUp"
                        // onClick={() => { setPopupMessage("수정되었습니다."); setShowPopup(true); }}
                        onClick={handelUpdateUserInfo}
                      >
                        정보 수정
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="widget">
                      <h4 className="title_widget">사용자 등록 하기</h4>
                      <div className="widget_user_resist_area">
                        {/* <div class="sidebar-info">
          <form>
              <input type="text" class="form-control">
              <input type="submit" value="search">
          </form>
      </div> */}
                        <div className="widget_user_resist_left">
                          <div className="widget_user_resist_left_label">Name :</div>
                          <div className="widget_user_resist_left_label">ID :</div>
                          <div className="widget_user_resist_left_label">E-mail :</div>
                          <div className="widget_user_resist_left_label">phone :</div>
                          <div className="widget_user_resist_left_label">부서 :</div>
                          <div className="widget_user_resist_left_label">직급 :</div>
                          <div className="widget_user_resist_left_label">권한 :</div>
                          {/* <div class="widget_user_resist_left_label">PW :</div> */}
                        </div>
                        <div className="widget_user_resist_right">
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              // onChange={(e) => handleUserAdd(e, 'name')}
                                value={addUser?.name}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // 한글과 영어만 허용하는 정규식
                                  const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]*$/;
                                  // 입력된 값이 한글 또는 영어일 경우에만 handleUserAdd 호출
                                  if (regex.test(value)) {
                                    handleUserAdd(e, 'name');
                                  }
                                }}
                              maxLength={50}
                              />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input" placeholder='아이디는 이메일 형식입니다.'
                              onChange={(e) => handleUserAdd(e, 'id')}
                              value={addUser.id}
                              maxLength={500}
                              // onBlur={() => {
                              //   if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(addUser.id)) {
                              //     setPopupMessage("올바른 이메일 형식이 아닙니다.");
                              //     setShowPopup(true);
                              //   }
                              // }}
                            />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleUserAdd(e, 'email')} maxLength={500} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleUserAdd(e, 'phone')} maxLength={50} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleUserAdd(e, 'dept')} maxLength={50} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            <input type="text" className="widget_user_resist_input"
                              onChange={(e) => handleUserAdd(e, 'rank')} maxLength={50} />
                          </div>
                          <div className="widget_user_resist_right_label">
                            {/* <input type="text" className="widget_user_resist_input"/> */}
                            <DropDownList
                              // onChange={(e) => handleChange(e, 'admin')}
                              onChange={handleAddAdmin}
                              defaultValue={'일반'}
                              // defaultItem={addUser.admin}
                              data={['일반','관리']} />
                          </div>
                          {/* <div class="widget_user_resist_right_label"><input type="password" class="widget_user_resist_input"></div> */}
                        </div>
                      </div>
                      <div className="widget_user_resist_area_bt">
                        <a
                          // style={{ cursor: 'pointer' }}
                          className="btn btn-theme border btn-sm"
                          data-animation="animated slideInUp"
                          // onClick={() => { setPopupMessage("등록되었습니다."); setShowPopup(true); }}
                          onClick={() => { handelInsertUserInfo(); }}
                        >
                          사용자 등록
                        </a>
                      </div>
                    </div>
                    <div className="widget">
                      <h4 className="title_upload">
                        사용자 정보 대량 업로드
                        <a
                          style={{ cursor: 'pointer' }}
                          className="btn btn-theme border btn-xs"
                          data-animation="animated slideInUp"
                          // onClick={excelFileDownload('양식')}
                          onClick={() => excelFileDownload()}
                        >
                          양식 다운로드
                        </a>
                      </h4>
                      <div className="widget_user_resist_area">
                        {/* <div class="sidebar-info">
          <form>
              <input type="text" class="form-control">
              <input type="submit" value="search">
          </form>
      </div> */}
                        {/* <div class="widget_user_resist_left">
      
      <div class="widget_user_resist_left_label_bulk">파일선택 :</div>


  </div> */}
                        <div className="file-upload">
                          <input
                            type="text"
                            className="upload-name"
                            // defaultValue={"엑셀파일을 선택해주세요"}
                            value={inputFileName ?? "엑셀파일을 선택해주세요"}
                            readOnly />
                          <label htmlFor="ex_filename" className="bulk_upload_BT">
                            파일 선택
                          </label>
                          <input type="file" id="ex_filename" className="upload-hidden"
                            onChange={handleFileChange} />
                        </div>
                      </div>
                      <div className="widget_user_resist_area_bt">
                        <a
                          // style={{ cursor: 'pointer' }}
                          className="btn btn-theme border btn-sm"
                          data-animation="animated slideInUp"
                          onClick={() => handleExcelUpload()}
                        >
                          엑셀 업로드
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="widget">
                  <h4 className="title">이노서비스 도움말</h4>
                  <img src="assets/img/1500x700.png" alt="Thumb" />
                  <p>
                    이노액티브에서 제공 되고 있는 서비스와 제품 다운로드에 대한 가이드라인을 제공하고 있습니다.<br />
                    제품 다운로드 페이지에서 서비스 받고 있는 제품을 다운 로드 할 수 있으며 라이선스 관리 페이지에서 라이선스를 신청하여 좀더 빠르고 편하게 서비스를 이용 할 수 있습니다.
                  </p>
                  {/* <a style={{ cursor: 'pointer' }} className="btn btn-theme border btn-sm" data-animation="animated slideInUp" onClick={()=>window.location.href = '/user_license'}>문의 하기</a> */}
                  <a style={{ cursor: 'pointer' }} className="btn btn-theme border btn-sm" data-animation="animated slideInUp" onClick={moveURL}>문의 하기</a>
                </div>
                <div className="widget">
                  <h4 className="title">Product Help Links</h4>
                  <ul>
                    {menuData.map(item => <li><a href={item.msUrl} target={item.msTarget} key={item.pdId}>{item.msName}</a></li>)}
                    {/* <li><a style={{ cursor: 'pointer' }}>InnoChecker</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoR3D</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoM3D</a></li>
                    <li><a style={{ cursor: 'pointer' }}>INNOVA ISO</a></li>
                    <li><a style={{ cursor: 'pointer' }}>INNOVA Clash Detector</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoPCF</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoFMS</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoRevit</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoNavisWorks</a></li>
                    <li><a style={{ cursor: 'pointer' }}>InnoRPA</a></li> */}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {/* <!-- End Side Menu --> */}

        </nav>
        {/* <!-- End Navigation --> */}

      </header>
      {/* <!-- End Header --> */}
    </div>
  )
})

export default Header