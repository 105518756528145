import React, { useEffect, useState } from 'react'
import { clientConfig } from '../../api/clientConfig';
import axios from 'axios';
import { Grid, GridColumn, GridNoRecords, GridRowClickEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import MasterPartnerHeader from '../../Components/MasterPartnerHeader';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import './Master_Contract_Management_style.css'

interface sendCompanyInfoPorps {
  cId: string,
  cCode: string,
  cName: string,
  cNumber: string,
  cPhone: string,
  cPost: string,
  cAddress: string,
  cFilePath: string,
  cFileName: string,
  cDescription: string,
  uId: string,
  uName: string,
  uPhone: string,
  uEmail: string,
}

interface Master_Partner_ManagementProps {
  handleMenu: () => void;
  onSendCompanyInfo: any;
}

export const Master_Partner_Management: React.FC<Master_Partner_ManagementProps> = ({ handleMenu, onSendCompanyInfo }) => {
  // 정보값
  const [masterCompanyInfo, setMasterCompanyInfo] = useState([])
  const [sendCompanyInfo, setSendCompanyInfo] = useState<Array<sendCompanyInfoPorps>>([])
  // 정렬 상태 관리
  // const [sort, setSort] = React.useState(Array<SortDescriptor>);\
      // 파트너사 이름 정렬
      const nameSort: Array<SortDescriptor> = [
        { field: "cName", dir: "asc" },
      ];

  const [sort, setSort] = React.useState(nameSort);
  // 검색어 상태관리
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  // 검색어 입력란 변경이벤트 처리
  const handleSearchChange = (event: InputChangeEvent) => {
    setSearchKeyword(event.target.value as string);
  };

  //  검색어에 일치하는 데이터 필터링
  const filteredData = masterCompanyInfo.filter((row) => {
    // const filteredData = masterContractData.filter((row) => {
    return Object.values(row).some((value) => {
      return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
    });
  });

  const getInfo = () => {
    axios.post(`${clientConfig.URL}/getMasterCompany`, {})
      .then(response => {
        // 요청이 성공했을 때 실행할 코드
        // console.log('getMasterCompany:');
        // 서버로부터 성공적인 응답을 받았을 때
        if (response.status === 200) {
          // console.log('getMasterCompany 정보:', response.data);
          setMasterCompanyInfo(response.data)
        } else {
          console.error('getMasterCompany 정보 가져오기 실패:', response.data.message);
        }
      })
      .catch(error => {
        // 요청이 실패했을 때 실행할 코드
        console.error('getMasterCompany 정보 가져오기 실패:', error);
        // 오류 메시지를 표시하거나 기타 작업을 수행합니다.
      });
  }


  useEffect(() => {
    getInfo()
  }, [sendCompanyInfo, onSendCompanyInfo, handleMenu])


  // console.log('masterCompanyInfo : ', masterCompanyInfo);

  // 클릭시 파트너사 정보 넘기기
  const handlePartnerInfo = (event: GridRowClickEvent) => {
    onSendCompanyInfo(event.dataItem)
    // 메뉴가 이미 열려있을 때는 메뉴를 닫지 않고 정보만 전달
    // if (!sendCompanyInfo.length) {
    //   setSendCompanyInfo(event.dataItem);
    //   handleMenu(); // 메뉴 열기
    // } else {
    //   setSendCompanyInfo(event.dataItem);
    // }
    handleMenu(); // 메뉴 열기
  }

  //  등록 버튼 눌러서 위젯 메뉴 열기
  const handleOpenMenu = () => {
    onSendCompanyInfo()
    handleMenu();
  };


  return (
    <div className="single-item item">
      <div className="info">
        <div className="contact-form">
          <div className="superadmin_product_list">
            <div className="superadmin_product_list_title">파트너사 관리</div>
          </div>
          <div className="superadmin_product_list_bt_area">
            <div className="superadmin_product_list_bt_pack">
              <div className="superadmin_product_list_bt_margin">
                <a
                  style={{ cursor: 'pointer' }}
                  className="btn btn-theme border btn-scm"
                  data-animation="animated slideInUp"
                  onClick={handleOpenMenu}
                >
                  등록
                </a>
              </div>

            </div>
          </div>

          <a style={{ cursor: 'pointer' }}>
            {/* <img src="assets/img/Contractor_temp.jpg" alt="Thumb" /> */}

            <Grid
              className='masterPartnerGrid'
              // data={masterCompanyInfo}
              data={orderBy(filteredData, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              resizable={true}
              onRowClick={handlePartnerInfo}
            >
              <GridToolbar>
                검색 :
                <Input
                  placeholder='검색할 내용을 입력해 주세요.'
                  style={{ width: '50%' }}
                  onChange={handleSearchChange}
                />
              </GridToolbar>
              {/* 데이터없을때 나오는 문구 */}
              <GridNoRecords>
                <div style={{ height: '100vh' }}>
                  파트너사를 등록해주세요.
                </div>
              </GridNoRecords>
              <GridColumn title='고객사명' field='cName' />
              <GridColumn title='사업자등록번호' field='cNumber' className='masterPartnerGridColumn' />
              <GridColumn title='담당자' field='uName' className='masterPartnerGridColumn' />
              <GridColumn title='연락처' field='cPhone' className='masterPartnerGridColumn' />
              {/* <GridColumn title='우편번호' field='cPost' /> */}
              {/* <GridColumn title='주소' field='cAddress' /> */}
              {/* <GridColumn title='비고' field='cDescription' /> */}
            </Grid>
          </a>
        </div>
        {/* 경계선 */}
        <div className="meta"></div>
        <div className="superadmin_product_list_resister">
          <div className="superadmin_product_list_bt_pack">

          </div>
        </div>
      </div>
    </div>

  )
}
