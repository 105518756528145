import React, { useEffect, useState } from 'react'
import { clientConfig } from '../../api/clientConfig';
import axios from 'axios';
import { Grid, GridColumn, GridNoRecords, GridRowClickEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import MasterPartnerHeader from '../../Components/MasterPartnerHeader';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';

interface sendSolutionAdminInfoPorps {

  caId: string,
  caLoginId: string,
  caLoginPw: string,
  caName: string,
  caEmail: string,
  caPhone: string,
  caDept: string,
  caRank: string,
  caRegDate: string,
}

interface Master_Solution_AdminProps {
  handleMenu2: () => void;
  onSendSolutionAdmin: any;
}

export const Master_Solution_Admin: React.FC<Master_Solution_AdminProps> = ({ handleMenu2, onSendSolutionAdmin }) => {
  // 정보값
  const [getMasterSolutionAdmin, setGetMasterSolutionAdmin] = useState([])
  const [solutionAdminInfo, setSolutionAdminInfo] = useState<Array<sendSolutionAdminInfoPorps>>([])
	// 정렬 상태 관리
	// const [sort, setSort] = React.useState(Array<SortDescriptor>);
  // 솔루션 관리자 이름 정렬
  const nameSort: Array<SortDescriptor> = [
    { field: "caName", dir: "asc" },
  ];
  
	const [sort, setSort] = React.useState(nameSort);

  // 검색어 상태관리
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  // 검색어 입력란 변경이벤트 처리
  const handleSearchChange = (event: InputChangeEvent) => {
    setSearchKeyword(event.target.value as string);
  };

  //  검색어에 일치하는 데이터 필터링
  const filteredMasterUserData = getMasterSolutionAdmin.filter((row) => {
    // const filteredData = masterContractData.filter((row) => {
    return Object.values(row).some((value) => {
      return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
    });
  });
  // useEffect(() => {
  //   onSendSolutionAdmin(solutionAdminInfo);
  // }, [solutionAdminInfo, onSendSolutionAdmin]);

  // console.log('getMasterSolution : ',solutionAdminInfo);


  const getInfo = () => {
    axios.post(`${clientConfig.URL}/getMasterSolution`, {})
      .then(response => {
        // 요청이 성공했을 때 실행할 코드
        // console.log('getMasterSolution:');
        // 서버로부터 성공적인 응답을 받았을 때
        if (response.status === 200) {
          // console.log('getMasterSolution 정보:', response.data);
          setGetMasterSolutionAdmin(response.data)
        } else {
          console.error('getMasterSolution 정보 가져오기 실패:', response.data.message);
        }
      })
      .catch(error => {
        // 요청이 실패했을 때 실행할 코드
        console.error('getMasterSolution 정보 가져오기 실패:', error);
        // 오류 메시지를 표시하거나 기타 작업을 수행합니다.
      });
  }


  useEffect(() => {
    getInfo()
  }, [solutionAdminInfo, onSendSolutionAdmin, handleMenu2])


  // console.log('masterCompanyInfo : ', masterCompanyInfo);

  // 클릭시 파트너사 정보 넘기기
  const handlePartnerInfo = (event: GridRowClickEvent) => {
    // console.log(event.dataItem);
    onSendSolutionAdmin(event.dataItem)
    // // 메뉴가 이미 열려있을 때는 메뉴를 닫지 않고 정보만 전달
    // if (!solutionAdminInfo.length) {
    //   setSolutionAdminInfo(event.dataItem);
    //   handleMenu2(); // 메뉴 열기
    // } else {
    //   setSolutionAdminInfo(event.dataItem);
    // }
    handleMenu2(); // 메뉴 열기
  }

  //   위젯 메뉴 열기
  const handleOpenMenu = () => {
    // 수정정보 초기화
    onSendSolutionAdmin();
    handleMenu2();
  };

  return (
    <div className="single-item item">
      <div className="info">
        <div className="contact-form">
          <div className="superadmin_product_list">
            <div className="superadmin_product_list_title">솔루션 관리자 설정</div>
          </div>
          <div className="superadmin_product_list_bt_area">
            <div className="superadmin_product_list_bt_pack">
              <div className="superadmin_product_list_bt_margin">
                <a
                  style={{ cursor: 'pointer' }}
                  className="btn btn-theme border btn-scm"
                  data-animation="animated slideInUp"
                  onClick={handleOpenMenu}
                >
                  등록
                </a>
              </div>

            </div>
          </div>

          <a style={{ cursor: 'pointer' }}>
            {/* 로그인계정, 이름, 이메일, 연락처, 부서, 직급 */}
            <Grid
              // data={getMasterSolutionAdmin}
              data={orderBy(filteredMasterUserData, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
              }}
              resizable={true}
              onRowClick={handlePartnerInfo}
            >
              <GridToolbar>
                검색 :
                <Input
                  placeholder='검색할 내용을 입력해 주세요.'
                  style={{ width: '50%' }}
                  onChange={handleSearchChange}
                />
              </GridToolbar>
              {/* 데이터없을때 나오는 문구 */}
              <GridNoRecords>
                <div style={{ height: '100vh' }}>
                  솔루션 관리자를 등록해주세요.
                </div>
              </GridNoRecords>
              <GridColumn title='로그인계정' field='caLoginId' />
              <GridColumn title='이름' field='caName' />
              <GridColumn title='이메일' field='caEmail' />
              <GridColumn title='연락처' field='caPhone' />
              <GridColumn title='부서' field='caDept' />
              <GridColumn title='직급' field='caRank' />
            </Grid>
          </a>
        </div>
        {/* 경계선 */}
        <div className="meta"></div>
        <div className="superadmin_product_list_resister">
          <div className="superadmin_product_list_bt_pack">

          </div>
        </div>
      </div>
    </div>

  )
}
