import { useEffect, useState } from 'react';
import axios from 'axios';
import { clientConfig } from '../../api/clientConfig';

interface License {
  uId: string;
  pdId: string,
  pdCode: string,
  pdName: string;
  pdVersion: string,
  pdFile: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdDescription: string,
  pdImage: string,
  dsName: string;
  uLoginId: string,
  uName: string,
}


const useFetchLicenseData = (cId: number) => {
    const [licenseData, setLicenseData] = useState<Array<License>>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // 서버로 cId를 보내기
                const response = await axios.post(`${clientConfig.URL}/getLicenseInfo`, {
                    cId: cId,
                });

                if (response.data.length > 0) {
                    // 서버에서 받아온 데이터를 상태로 설정
                    setLicenseData(response.data);
                    console.log('getLicenseInfo response.data : ', response.data);
                } else {
                    setLicenseData([])
                }

            } catch (error) {
                console.error('Error getLicenseInfo fetchData:', error);
            }
        };

        fetchData();
    }, [cId]); // cId가 변경될 때마다 데이터 다시 가져오기

    return licenseData;
}

export default useFetchLicenseData;
