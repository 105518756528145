import React, { useEffect, useState } from 'react'
import { clientConfig } from '../../api/clientConfig';
import axios from 'axios';
import {
  Grid, GridColumn, GridNoRecords, GridRowClickEvent, GridToolbar,
  GridDetailRowProps,
  GridExpandChangeEvent,
} from '@progress/kendo-react-grid';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import './Master_Contract_Management_style.css'
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Calendar, CalendarChangeEvent, DatePicker, DatePickerChangeEvent, DateRangePicker, DateRangePickerChangeEvent } from '@progress/kendo-react-dateinputs';

// 계약 정보 데이터 타입 정의
interface MasterContractDataProps {
  ctId: string,
  ctCode: string,
  uId: string,
  cId: string,
  ctDate: string,
  ctPeriodFrom: string,
  ctPeriodTo: string,
  ctLicense: string,
  ctLicenseProject: string,
  ctLicenseTag: string,
  ctEndStatus: string,
  ctSerialKey: string,
  ctPriceStandard: string,
  ctPurchasePrice: string,
  ctSalesPrice: string,
  ctFilePath: string,
  ctFileName: string,
  uLoginId: string,
  uLoginPw: string,
  uName: string,
  uEmail: string,
  uPhone: string,
  uAgree: string,
  uDept: string,
  uRank: string,
  uNumber: string,
  uAdmin: string,
  uActive: string,
  WorkerGuid: string,
  uRegDate: string,
  cCode: string,
  cName: string,
  cNumber: string,
  cPhone: string,
  cPost: string,
  cAddress: string,
  cFilePath: string,
  cFileName: string,
  cDescription: string,
  pdId: string,
  pdCode: string,
  pdName: string,
  pdVersion: string,
  pdFile: string,
  pdDescription: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdImage: string,
  pdUse: string,
  pdUpateDate: string,
  dsId: string,
  pdHtml: string,
  pdManual: string,
  expanded?: boolean,
}
// 상세 계약 정보 데이터 타입 정의
interface DetailMasterContractDataProps {
  ctId: string,
  ctCode: string,
  uId: string,
  uLoginId: string,
  cId: string,
  ctDate: string,
  ctPeriodFrom: string,
  ctPeriodTo: string,
  ctLicense: string,
  pdCode: string,
  ctLicenseProject: string,
  ctLicenseTag: string,
  WorkerGuid: string,
  act_dsId: string,
  dsId: string,
  ctEndStatus: string,
  ctSerialKey: string,
  ctPriceStandard: string,
  ctPurchasePrice: string,
  ctSalesPrice: string,
  ctVendor: string,
  ctTerm: string,
  ctFilePath: string,
  ctFileName: string,
  uLoginPw: string,
  uName: string,
  uEmail: string,
  uPhone: string,
  uAgree: string,
  uDept: string,
  uRank: string,
  uNumber: string,
  uAdmin: string,
  uActive: string,
  uRegDate: string,
  cCode: string,
  cName: string,
  cNumber: string,
  cPhone: string,
  cPost: string,
  cAddress: string,
  cFilePath: string,
  cFileName: string,
  cDescription: string,
  pdId: string,
  pdName: string,
  pdVersion: string,
  pdFile: string,
  pdDescription: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdImage: string,
  pdUse: string,
  pdUpateDate: string,
  pdHtml: string,
  pdManual: string,
  LicenseType: string,
  Purchase: string,
  Sales: string,
  Margin: string,
  MarginRate: string,
  expanded?: boolean,
}
// handleMenu3 : 우측메뉴열기 
// onSendMasterContract : 우측메뉴로 데이터 보내기
interface Master_Contract_ManagementProps {
  // handlePageChange: (pageNumber: number) => void;
  handleMenu3: () => void;
  onSendMasterContract: any;
}

export const Master_Contract_Management: React.FC<Master_Contract_ManagementProps> = ({ handleMenu3, onSendMasterContract }) => {
  // 계약 정보 상태 관리
  const [masterContractData, setMasterContractData] = useState<Array<MasterContractDataProps>>([])
  // 상세 계약 정보 상태 관리
  const [detailMasterContractData, setDetailMasterContractData] = useState<Array<DetailMasterContractDataProps>>([])
  // 상세 고객사 정보 상태 관리
  const [detailMasterCompanyInfo, setDetailMasterCompanyInfo] = useState<Array<DetailMasterContractDataProps>>([])
  // 상세보기 상태관리
  const [showDetail, setShowDetail] = useState(false)
  // 검색어 상태관리
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  // 상세보기 검색어 상태관리
  const [searchKeywordDetail, setSearchKeywordDetail] = useState<string>('');

  // 계약 정보 엑셀로 내보내기 상태 관리
  const _export = React.useRef<ExcelExport | null>(null);
  // 계약 정보 엑셀로 내보내기는 함수
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  // 현재날짜로 부터 (30, 60, 90)일 남은 계약 정보 상태관리
  const [sortByMonth, setSortByMonth] = useState('')
  // 버튼으로 계약정보 날짜정렬할 값 넘겨 받기
  const activeSort = (month: string) => {
    // #region 필터값 조건 초기화
    setSelectedProductName('')
    setSelectedCompanyName('')
    setDateRange({ start: null, end: null })
    // #endregion 
    // 같은 버튼을 누르면 초기화하기
    if (sortByMonth === month) {
      setSortByMonth('');
    } else {
      setSortByMonth(month);
    }
  };

  // 제품명 필터 조건 검색 상태 관리
  const [selectedProductName, setSelectedProductName] = useState('');
  // 회사명 필터 조건 검색 상태 관리
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  // 회사명선택시 나오는 cId 필터 조건 검색 상태 관리
  const [selectedCompanyCid, setSelectedCompanyCid] = useState('');
  // console.log('selectedCompanyCid : ', selectedCompanyCid)
  // 계약날짜 필터 조건 검색 상태 관리
  const [dateRange, setDateRange] = useState<{ start: Date | null; end: Date | null }>({ start: new Date, end: new Date });
  console.log('dateRange : ', dateRange)
  // 필터 조건 검색실행 함수
  const handleSearch = () => {
    const filtered = detailMasterContractData.filter((row) => {
      const matchesProductName = selectedProductName ? row.pdName === selectedProductName : true;
      const matchesCompanyName = selectedCompanyName ? row.cName === selectedCompanyName : true;
      const matchesDateRange = dateRange.start && dateRange.end
        ? new Date(row.ctPeriodTo) >= new Date(dateRange.start) && new Date(row.ctPeriodTo) <= new Date(dateRange.end)
        : true;
      return matchesProductName && matchesCompanyName && matchesDateRange;
    });
    setDetailMasterContractData(filtered);
  };

  // 계약정보에 검색어에 일치하는 데이터 필터링
  const filteredData = detailMasterContractData.filter((row) => {
    // const filteredData = masterContractData.filter((row) => {
    return Object.values(row).some((value) => {
      return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
    });
  }
  );
  // // 상세 계약정보에 검색어에 일치하는 데이터 필터링
  // const filteredDataDetail = masterContractData.filter((row) => {
  //   return Object.values(row).some((value) => {
  //     return value && value.toString().toLowerCase().includes(searchKeywordDetail.toLowerCase());
  //   });
  // });
  const handleStartChange = (event: DatePickerChangeEvent ) => {
    setDateRange(({
      ...dateRange,
      start: event.target?.value
    }));
  };

  const handleEndChange = (event: DatePickerChangeEvent) => {
    setDateRange(({
      ...dateRange,
      end: event.target?.value
    }));
  };

  // console.log('1 setDateRange', dateRange)
  // console.log('2 setDateRange', dateRange)

  // 안씀 계약 정보 불러오기
  const getInfo = () => {
    axios.post(`${clientConfig.URL}/getMasterContract`, {})
      .then(response => {
        // 요청이 성공했을 때 실행할 코드
        console.log('Master Contract response:', response);
        // 서버로부터 성공적인 응답을 받았을 때
        if (response.status === 200) {
          console.log('Master Contract 정보:', response.data);
          setMasterContractData(response.data);
        } else {
          console.error('Master Contract 정보 가져오기 실패:', response.data.message);
        }
      })
      .catch(error => {
        // 요청이 실패했을 때 실행할 코드
        console.error('Master Contract 정보 가져오기 실패:', error);
        // 오류 메시지를 표시하거나 기타 작업을 수행합니다.
      });
  }


  // 현재사용중임 상세 계약 정보 불러오기
  const getDetailInfo = () => {
    axios.post(`${clientConfig.URL}/getDetailMasterContract`, {
      sortByOneMonth: sortByMonth,
    })
      .then(response => {
        // 요청이 성공했을 때 실행할 코드
        console.log('getDetailMasterContract response:', response);
        // 서버로부터 성공적인 응답을 받았을 때
        if (response.status === 200) {
          console.log('getDetailMasterContract 정보:', response.data);
          setDetailMasterContractData(response.data);
        } else {
          console.error('getDetailMasterContract 정보 가져오기 실패:', response.data.message);
        }
      })
      .catch(error => {
        // 요청이 실패했을 때 실행할 코드
        console.error('getDetailMasterContract 정보 가져오기 실패:', error);
        // 오류 메시지를 표시하거나 기타 작업을 수행합니다.
      });
  }

  // 계약정보 검색어 입력란 변경이벤트 처리
  const handleSearchChange = (event: InputChangeEvent) => {
    setSearchKeyword(event.target.value as string);
  };

  // 상세 계약정보 검색어 입력란 변경이벤트 처리
  const handleSearchChangeDetail = (event: InputChangeEvent) => {
    setSearchKeywordDetail(event.target.value as string);
  };


  // 변경 감지시 실행
  useEffect(() => {
    getInfo()
    getDetailInfo()
  }, [onSendMasterContract, handleMenu3])

  // 30 60 90 달 변경 감지시 실행
  useEffect(() => {
    getDetailInfo()
    console.log('sortByMonth 체인지')
  }, [sortByMonth])

  // 변경 감지시 실행
  // useEffect(() => {
  //   onSendMasterContract(detailMasterContractData)
  //   // onSendMasterContract(masterContractData)
  // }, [onSendMasterContract])
  // console.log('masterContractData : ', masterContractData);

  // 그리드선택시 이벤트 상세정보창 보여주기
  const openDetailContract = (event: GridRowClickEvent) => {
    // 상세정보창
    // setShowDetail(true)
    console.log('openDetailContract : ', event);
    onSendMasterContract(event.dataItem)
    handleMenu3()
  }

  // 수정버튼 클릭시
  const openEditContractInfo = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem; // 클릭된 행의 데이터 가져오기
    console.log(dataItem); // 클릭된 행의 데이터 출력
    // 위젯으로 데이터 넘김
    onSendMasterContract(props.dataItem)
    handleMenu3()
  }

  // 상세정보펼침 데이터설정 이벤트
  const expandChange = (event: GridExpandChangeEvent) => {
    let newData = detailMasterContractData.map((item: DetailMasterContractDataProps) => {
      // let newData = masterContractData.map((item: MasterContractDataProps) => {
      if (item.ctId === event.dataItem.ctId) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    console.log('expandChange 동작 !')

    // setMasterContractData(newData);
    // 상세정보로 업데이트
    setDetailMasterContractData(newData);

    // ctId가 같은 정보만 필터링하여 새로운 배열로 만듭니다.
    const filteredData = newData.filter((item: DetailMasterContractDataProps) => {
      return item.ctId === event.dataItem.ctId;
    });

    // 필터링된 데이터로 상세 회사 정보를 설정합니다.
    setDetailMasterCompanyInfo(filteredData);
    console.log('filteredData : ', filteredData);
  };

  // 등록하기선택시 위젯메뉴보여줌 (데이터는 초기화하여 등록목록만보여줌)
  const handleAddMenu = () => {
    // 해당 계약 정보 보내기
    onSendMasterContract()
    // 위젯열기
    handleMenu3()
  }

  // 첨부파일 다운로드
  const downloadAttachedContractFile = async (target: string, fileName: string) => {
    console.log({ target, fileName });
    try {
      const response = await axios.post(`${clientConfig.URL}/downloadAttachedContractFile`, {
        ctCode: target
      }, {
        responseType: 'blob' // 파일 다운로드를 위해 responseType을 'blob'으로 설정
      });

      // 응답으로부터 파일 다운로드
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // 다운로드 후 URL 객체 및 링크 제거
      window.URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('파일 다운로드 오류:', error);
      // 오류 처리
    }
  };

  // 클라이언트에서 파일 다운로드를 요청하는 함수
  const downloadAttachedFile = async () => {
    console.log('detailMasterCompanyInfo[0]?.cCode : ', detailMasterCompanyInfo[0]?.cCode)
    try {
      const response = await axios.post(`${clientConfig.URL}/downloadAttachedFile`, {
        cCode: detailMasterCompanyInfo[0]?.cCode
      }, {
        responseType: 'blob' // 파일 다운로드를 위해 responseType을 'blob'으로 설정
      });

      // 응답으로부터 파일 다운로드
      console.log('downloadAttachedFile : ', response)
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', detailMasterCompanyInfo[0]?.cFileName); // 빈 문자열로 설정하여 파일명을 서버에서 지정한 이름 그대로 사용
      document.body.appendChild(link);
      link.click();

      // 다운로드 후 URL 객체 및 링크 제거
      window.URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('파일 다운로드 오류:', error);
      // 오류 처리
    }
  };

  // 선택시 출력되는 상세 정보 페이지
  const DetailComponent = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem;
    console.log(dataItem);
    return (
      <div className="superadmin_contract_main_contents border_pack">
        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">


          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">사업자</div>
            <div className="contract_card_subcontents jfc_ct">{dataItem.cNumber}</div>
          </div>

          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">담당자</div>
            <div className="contract_card_subcontents jfc_ct">{dataItem.uName}</div>
          </div>

          <div className="width_point50_sp xdblock">
            <div className="width_point20_sp xdblock_sp">
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct">연락처</div>
            </div>

            <div className="width_point80_sp xdblock_sp">
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.uPhone}</div>
            </div>
          </div>


          {/* <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">연락처</div>
            <div className="contract_card_subcontents jfc_ct">{dataItem.uPhone}</div>
          </div> */}


        </div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen jfc_ct">상품명</div>
            <div className="contract_card_subcontents jfc_ct">{dataItem.pdName}</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen jfc_ct">수량</div>
            <div className="contract_card_subcontents jfc_ct_right">{dataItem.ctLicense.toLocaleString()}</div>
          </div>

          {/* <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >E-mail</div>
            </div>
            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.uEmail}</div>
            </div>
          </div> */}

          <div className="width_point50 xdblock">
            <div className="width_point20_sp xdblock_sp">
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct">E-mail</div>
            </div>

            <div className="width_point80_sp xdblock_sp">
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.uEmail}</div>
            </div>
          </div>


        </div>
        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">매입단가</div>
            <div className="contract_card_subcontents_vl jfc_ct_right ">{dataItem.ctPurchasePrice.toLocaleString()}원</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">매입원가</div>
            <div className="contract_card_subcontents_vl jfc_ct_right ">{dataItem.Purchase.toLocaleString()}원</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">매출단가</div>
            <div className="contract_card_subcontents_vl jfc_ct_right ">{dataItem.ctSalesPrice.toLocaleString()}원</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">매출액</div>
            <div className="contract_card_subcontents_vl jfc_ct_right ">{dataItem.Sales.toLocaleString()}원</div>
          </div>
        </div>
        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">마진금액</div>
            <div className="contract_card_subcontents_vl jfc_ct_right">{dataItem.Margin.toLocaleString()}원</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">마진율</div>
            <div className="contract_card_subcontents_vl jfc_ct_right">{dataItem.MarginRate.toLocaleString()}%</div>
          </div>
          <div className="width_point25 xdblock"></div>
          <div className="width_point25 xdblock"></div>
        </div>


        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">

          {/* <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >유형</div>
            </div>
            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.LicenseType}</div>
            </div>
          </div>

          <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >매입처</div>
            </div>

            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.ctVendor}</div>
            </div>
          </div> */}
          <div className="width_point50_sp xdblock">
            <div className="width_point20_sp xdblock">
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct">유형</div>
            </div>

            <div className="width_point80_sp xdblock">
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.LicenseType}</div>
            </div>
          </div>


          <div className="width_point50_sp xdblock">
            <div className="width_point20_sp xdblock_sp">
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct">매입처</div>
            </div>

            <div className="width_point80_sp xdblock_sp">
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.ctVendor}</div>
            </div>
          </div>

        </div>


        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">기간</div>
            <div className="contract_card_subcontents width_point100 jfc_ct_right">{dataItem.ctTerm}</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">계약일</div>
            <div className="contract_card_subcontents jfc_ct_left">{dataItem.ctDate}</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">시작일</div>
            <div className="contract_card_subcontents jfc_ct_left">{dataItem.ctPeriodFrom}</div>
          </div>
          <div className="width_point25 xdblock">
            <div className="contract_card_title jfc_ct_right xen">만료일</div>
            <div className="contract_card_subcontents jfc_ct_left">{dataItem.ctPeriodTo}</div>
          </div>
        </div>
        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="width_point50_sp xdblock" >
            {/* <div className="contract_card_title jfc_ct_right xen jfc_ct" style={{width: '20%'}}>유형</div> */}
            <div className="width_point20_sp xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >첨부파일</div>
            </div>
            <div className="width_point80_sp xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{dataItem.ctFileName}</div>
            </div>
          </div>
        </div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line_btn">
          <div className="superadmin_flex width_point100 jfc_ct_sb">
            {dataItem.ctFileName ? (
              <div className="btn btn-theme btn-smc btnhe36 width_point100_sp margin_bt_sp" onClick={() => downloadAttachedContractFile(dataItem.ctCode, dataItem.ctFileName)} >첨부파일 다운로드</div>
            ) : (
              <div></div>
            )}
            <div>
              {/* <div className="btn btn-theme btn-smc btnhe36">고객사 상세정보</div> */}
              <div className="btn btn-theme btn-smc btnhe36 width_point100_sp margin_right_bt_sp" onClick={() => setShowDetail(true)}>고객사 상세정보</div>
              {/* &nbsp; */}
              <div className="btn btn-theme btn-smc btnhe36 width_point100_sp margin_bt_sp" onClick={() => openEditContractInfo(props)} >수정</div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  // 고객사 상세정보 창
  const DetailCompanyInfoComponent = () => {
    return (
      <div className="superadmin_contract_main_contents border_pack">

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >회사코드</div>
            </div>
            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{detailMasterCompanyInfo[0]?.cCode}</div>
            </div>
          </div>
          <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >회사명</div>
            </div>
            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{detailMasterCompanyInfo[0]?.cName}</div>
            </div>
          </div>
        </div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line_esc"></div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="superadmin_contract_main_contents_line">
            <div className="width_point50 xdblock" >
              <div className="width_point20 xdblock" >
                <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >사업번호</div>
              </div>
              <div className="width_point80 xdblock" >
                <div className="contract_card_subcontents_sp jfc_ct">{detailMasterCompanyInfo[0]?.cNumber}</div>
              </div>
            </div>
            <div className="width_point50 xdblock" >
              <div className="width_point20 xdblock" >
                <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >연락처</div>
              </div>
              <div className="width_point80 xdblock" >
                <div className="contract_card_subcontents_sp jfc_ct">{detailMasterCompanyInfo[0]?.cPhone}</div>
              </div>
            </div>
          </div>
        </div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line_esc"></div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line">
          <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >우편번호</div>
            </div>
            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{detailMasterCompanyInfo[0]?.cPost}</div>
            </div>
          </div>
          <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >회사주소</div>
            </div>
            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{detailMasterCompanyInfo[0]?.cAddress}</div>
            </div>
          </div>
        </div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents_line_esc"></div>

        <div className="superadmin_contract_main_contents_line">
          <div className="width_point50 xdblock" >
            <div className="width_point20 xdblock" >
              <div className="contract_card_title_sp jfc_ct_right xen jfc_ct" >첨부파일</div>
            </div>
            <div className="width_point80 xdblock" >
              <div className="contract_card_subcontents_sp jfc_ct">{detailMasterCompanyInfo[0]?.cFileName}</div>
            </div>
          </div>
          {/* 고객사의 첨부파일이 있으면 보여줌 */}
          {detailMasterCompanyInfo[0]?.cFileName && (
            <div className="width_point50 xdblock" >
              <div className="width_point100 xdblock" >
                <div className="btn btn-theme btn-smc btnhe36 width_point100_sp margin_bt_sp" onClick={downloadAttachedFile}>첨부파일 다운로드</div>
                {/* <div className="btn btn-theme btn-smc btnhe36 width_point100_sp margin_bt_sp" >첨부파일 다운로드</div> */}
              </div>
            </div>
          )}
        </div>

        {/* 한줄 라인 */}
        <div className="superadmin_contract_main_contents">
          <div className="width_point100 xdblock_column" >

            <div className="width_point100 xdblock" >
              <div className="contract_card_title_sp jfc_ct xen jfc_ct" >비고</div>
            </div>
            <div className="etc_detail">{detailMasterCompanyInfo[0]?.cDescription}</div>
          </div>
        </div>
      </div>
    )
  }




  return (
    <div>
      {/* //#region  상세정보 */}
      {showDetail && (
        <div className="superadmin_contract_form" style={{ top: '25.4%', left: '0' }}>
          <div className="superadmin_contract_area">
            <div className="superadmin_contract_main">
              <div className="superadmin_contract_close_area">
                <div className="superadmin_contract_close" onClick={() => setShowDetail(false)} style={{ cursor: 'pointer' }} >X</div>
              </div>


              <div className="superadmin_contract_main_contents_detail border_pack">
                {/* 한줄 라인 */}
                <div className="superadmin_flex">
                  <div className="contract_card_head_title width_point100 xdblock">
                    고객사 상세 정보
                  </div>
                </div>
                <div className='superadmin_contract_main_contents_line_esc'></div>
                {/* 고객사 상세정보 창 */}
                <DetailCompanyInfoComponent />

                {/* <Grid data={masterContractData}
                  style={{ height: '-webkit-fill-available' }}>
                  <GridNoRecords>
                    <div style={{ height: '100vh' }}>
                      일치하는 내용이 없습니다.
                    </div>
                  </GridNoRecords>
                  <GridColumn title='계약번호' field='ctCode' />
                  <GridColumn title='회사명' field='cName' />
                  <GridColumn title='제품명' field='pdName' />
                  <GridColumn title='계약일' field='ctDate' />
                  <GridColumn title='만료일' field='ctPeriodTo' />
                </Grid> */}
              </div>
            </div>
            {/* <div className="superadmin_contract_main_list">
              <div className="superadmin_contract_main_list_contents">
                 <Grid data={filteredDataDetail} style={{ height: '-webkit-fill-available' }}>
                  <GridToolbar>
                    <Input
                      placeholder='검색'
                      onChange={handleSearchChangeDetail}
                      style={{ height: '50px', width: '100%' }} />
                  </GridToolbar>
                  데이터없을때 나오는 문구
                  <GridNoRecords>
                    <div style={{ height: '100vh' }}>
                      일치하는 내용이 없습니다.
                    </div>
                  </GridNoRecords>
                  <GridColumn title='계약번호' field='ctCode' />
                  <GridColumn title='회사명' field='cName' />
                  <GridColumn title='제품명' field='pdName' />
                </Grid> 
              </div>
            </div> */}
          </div>
        </div>
      )}
      {/* //#endregion  */}

      <div className="single-item item">
        <div className="info">
          <div className="contact-form">
            <div className="superadmin_product_list">
              <div className="superadmin_product_list_title">계약 관리 </div>
            </div>
            <div className="superadmin_product_list_bt_area">
              <div className="superadmin_product_list_bt_pack">
                <div className="superadmin_product_list_bt_margin">
                  <a
                    style={{ cursor: 'pointer' }}
                    className="btn btn-theme border btn-sm mobile_fit"
                    data-animation="animated slideInUp"
                    onClick={handleAddMenu}
                  >
                    계약 등록
                  </a>
                </div>
                {/* <div className="superadmin_product_list_bt_margin">
                  <a
                    style={{ cursor: 'pointer' }}
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                  >
                    계약 양식 다운로드
                  </a>
                </div>
                <div className="superadmin_product_list_bt_margin">
                  <a
                    style={{ cursor: 'pointer' }}
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                  >
                    계약건 대량 업로드
                  </a>
                </div> */}
              </div>
            </div>
            {/* 여기에서 엑셀데이터 컬럼 구조잡고 내보냄 */}
            <ExcelExport data={filteredData} ref={_export} >
              <ExcelExportColumn field="ctCode" title="계약번호" />
              <ExcelExportColumn field="cName" title="회사명" />
              <ExcelExportColumn field="pdName" title="제품명" />
              <ExcelExportColumn field="ctDate" title="시작일" />
              <ExcelExportColumn field="ctPeriodTo" title="만료일" />
              <ExcelExportColumn field="cNumber" title="사업자번호" />
              <ExcelExportColumn field="uName" title="담당자" />
              <ExcelExportColumn field="uPhone" title="연락처" />
              <ExcelExportColumn field="pdName" title="상품명" />
              <ExcelExportColumn field="ctLicense" title="수량" />
              <ExcelExportColumn field="uEmail" title="E-mail" />
              <ExcelExportColumn field="ctPurchasePrice" title="매입단가" />
              <ExcelExportColumn field="Purchase" title="매입원가" />
              <ExcelExportColumn field="Sales" title="매출단가" />
              <ExcelExportColumn field="Margin" title="마진금액" />
              <ExcelExportColumn field="MarginRate" title="마진율" />
              <ExcelExportColumn field="LicenseType" title="유형" />
              <ExcelExportColumn field="ctVendor" title="매입처" />
              <ExcelExportColumn field="ctTerm" title="기간" />
              <ExcelExportColumn field="ctDate" title="계약일" />
              <ExcelExportColumn field="ctPeriodFrom" title="시작일" />
              <ExcelExportColumn field="ctPeriodTo" title="만료일" />
              <ExcelExportColumn field="ctFileName" title="첨부파일" />
            </ExcelExport>

            <Grid data={filteredData}
              // resizable={true}
              // onRowClick={(event) => openDetailContract(event)}
              // onRowClick={openDetailContract}
              detail={DetailComponent}
              expandField="expanded"
              onExpandChange={expandChange}
              className='contractGrid'
            >


              <GridToolbar className='contractGrid_top_filter_toolbar'>

                <ComboBox className='contractGrid_top_filter_toolbar_cname'
                  // data={[...new Set(detailMasterContractData?.map(cIdItem => cIdItem.cName))]}
                  // filter사용으로 중복제거
                  data={detailMasterContractData
                    ?.filter((value, index, self) => self.findIndex(item => item.cId === value.cId) === index)}
                  // data={detailMasterContractData
                    // ?.map(cIdItem => cIdItem.cName)
                    // ?.filter((value, index, self) => self.indexOf(value) === index)
                  // }
                  textField='cName'
                  dataItemKey='cId'
                  suggest={true}
                  placeholder='회사명'
                  // onChange={(event) => console.log('event : ', event)}
                  onChange={(event) => {setSelectedCompanyName(event.value?.cName); setSelectedCompanyCid(event.value?.cId)}}
                  // value={selectedCompanyName}
                />

                <ComboBox className='contractGrid_top_filter_toolbar_pdName'
                  // Set사용하여 중복제거하고 정렬까지 적용
                  // data={[...new Set(detailMasterContractData?.map(cIdItem => cIdItem.pdName))].sort()}
                  data={[...new Set(detailMasterContractData?.filter(cIdItem => cIdItem.cId === selectedCompanyCid).map(cIdItem => cIdItem.pdName))].sort()}
                  // data={detailMasterContractData
                  //   ?.map(cIdItem => cIdItem.pdName)
                  //   ?.filter((value, index, self) => self.indexOf(value) === index)}
                  suggest={true}
                  placeholder='제품명'
                  onChange={(event) => setSelectedProductName(event.value)}
                  value={selectedProductName}
                />

                {/* <DateRangePicker
                  className='contractGrid_top_filter_toolbar_DateRangePicker'
                  // startDateInputSettings={{label:'시작일'}}
                  // endDateInputSettings={{label:'종료일'}}
                  // 모바일버전
                  adaptive={true}
                  // 모바일버전 안내문
                  adaptiveTitle="날짜 범위를 선택하세요."
                  // 날짜바꾸기
                  // allowReverse={true}
                  // 날짜버튼
                  // swapButton={true}
                  format={'yyyy.MM.dd'}
                  // 오늘과 내일
                  // defaultValue={{ start: new Date(), end: new Date(new Date().setDate(new Date().getDate() + 1)) }}
                  // defaultValue={{ start: new Date(), end: new Date }}
                  // onChange={(event) => console.log('event', event)}
                  onChange={(event) => setDateRange({ start: event.value.start, end: event.value.end })}
                  value={{ start: dateRange.start, end: dateRange.end }}
                /> */}
                <div className='contractGrid_top_filter_toolbar_DateRangePicker'>
                  <DatePicker className='contractGrid_top_filter_toolbar_DateRangePicker'
                    value={dateRange.start}
                    format={'yyyy.MM.dd'}
                    onChange={handleStartChange}
                    placeholder={'시작일'}
                  />
                  <DatePicker className='contractGrid_top_filter_toolbar_DateRangePicker'
                    value={dateRange.end}
                    format={'yyyy.MM.dd'}
                    onChange={handleEndChange}
                    placeholder={'종료일'}
                  />

                </div>


                <Button className='contractGrid_top_toolbar_bt' onClick={handleSearch}>검색</Button>
                <Button className='contractGrid_top_toolbar_bt' onClick={() => { getDetailInfo(); setDateRange({ start: null, end: null }); }}>취소</Button>

              </GridToolbar>




              <GridToolbar className='contractGrid_top_toolbar'>
                <div className='contractGrid_top_toolbar_search'>

                  <Button
                    title="Export Excel"
                    className="contractGrid_top_toolbar_bt"
                    onClick={excelExport}
                  >
                    Export to Excel
                  </Button>

                  <Input className='contractGrid_top_toolbar_search_input'
                    placeholder='검색할 내용을 입력해 주세요.'
                    // style={{ width: '50%' }}
                    onChange={handleSearchChange}
                  />

                </div>

                <div className='contractGrid_top_toolbar_count'>
                  <Button className={`contractGrid_top_toolbar_bt ${sortByMonth === '30' ? 'active' : ''} `} onClick={() => activeSort('30')} >30일</Button>
                  <Button className={`contractGrid_top_toolbar_bt ${sortByMonth === '60' ? 'active' : ''} `} onClick={() => activeSort('60')} >60일</Button>
                  <Button className={`contractGrid_top_toolbar_bt ${sortByMonth === '90' ? 'active' : ''} `} onClick={() => activeSort('90')} >90일</Button>

                  {/* 
                  <Button className={`contractGrid_top_toolbar_bt ${sortByMonth === '30' ? 'active' : ''} `} onClick={() => {setDateRange({ start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() }); handleSearch();  }} >30일</Button>
                  <Button className={`contractGrid_top_toolbar_bt ${sortByMonth === '30' ? 'active' : ''} `} onClick={() => {setDateRange({ start: new Date(new Date().setDate(new Date().getDate() - 60)), end: new Date() }); handleSearch();  }} >60일</Button>
                  <Button className={`contractGrid_top_toolbar_bt ${sortByMonth === '30' ? 'active' : ''} `} onClick={() => {setDateRange({ start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }); handleSearch();  }} >90일</Button> 
                  */}

                  {/* <Button className='contractGrid_top_toolbar_bt' onClick={()=>setSortByMonth('30')} >30일</Button>
                  <Button className='contractGrid_top_toolbar_bt' onClick={()=>setSortByMonth('60')} >60일</Button>
                  <Button className='contractGrid_top_toolbar_bt' onClick={()=>setSortByMonth('90')} >90일</Button> */}
                </div>
              </GridToolbar>


              {/* 데이터없을때 나오는 문구 */}
              <GridNoRecords>
                <div style={{ height: '100vh' }}>
                  일치하는 내용이 없습니다.
                </div>
              </GridNoRecords>

              {/* <GridColumn title='계약번호' field='ctCode' />
              <GridColumn title='회사명' field='cName' />
              <GridColumn title='제품명' field='pdName' />
              <GridColumn title='계약일' field='ctDate' />
              <GridColumn title='만료일' field='ctPeriodTo' /> */}

              <GridColumn title="계약 리스트"
                cell={(props) => {
                  const ctCode = props.dataItem.ctCode;
                  const cName = props.dataItem.cName;
                  const ctDate = props.dataItem.ctDate;
                  const pdName = props.dataItem.pdName;
                  const ctPeriodTo = props.dataItem.ctPeriodTo;
                  return (
                    // <td style={{ height: '30px', color: '#7d92a7', fontWeight: '700', fontSize: '14px', lineHeight: '18px'}}>
                    //     계약번호 : {ctCode} <br/> 회사명 : {cName} <br/> 제품명 : {pdName} <br/> 시작일 : {ctDate} |  만료일 : {ctPeriodTo} 
                    // </td>
                    <div className="superadmin_contract_main_contents border_pack sp_margin_bot">
                      {/* 한줄 라인 시작 */}
                      <div className="superadmin_contract_main_contents_line">
                        <div className="width_point50_sp xdblock">
                          <div className="width_point20_sp xdblock">
                            <div className="contract_card_title_sp jfc_ct_right xen jfc_ct">
                              계약번호
                            </div>
                          </div>
                          <div className="width_point80_sp xdblock">
                            {/* <div className="contract_card_subcontents_sp jfc_ct">{ctCode}</div> */}
                            <div className="contract_card_subcontents_sp jfc_ct">{ctCode}</div>
                          </div>
                        </div>
                        <div className="width_point50_sp xdblock">
                          <div className="width_point20_sp xdblock_sp">
                            <div className="contract_card_title_sp jfc_ct_right xen jfc_ct">
                              회사명
                            </div>
                          </div>
                          <div className="width_point80_sp xdblock_sp">
                            <div className="contract_card_subcontents_sp jfc_ct">
                              {cName}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* 한줄 라인 끝 */}
                      {/* 한줄 라인 시작 */}
                      <div className="superadmin_contract_main_contents_line_nomg">
                        <div className="width_point25 xdblock">
                          <div className="contract_card_title jfc_ct_right xen">제품명</div>
                          <div className="contract_card_subcontents jfc_ct">{pdName}</div>
                        </div>
                        <div className="width_point25 xdblock">
                          <div className="contract_card_title jfc_ct_right xen">시작일</div>
                          <div className="contract_card_subcontents jfc_ct">{ctDate}</div>
                        </div>
                        <div className="width_point50_sp xdblock">
                          <div className="width_point20_sp xdblock_sp">
                            <div className="contract_card_title_sp jfc_ct_right xen jfc_ct">
                              만료일
                            </div>
                          </div>
                          <div className="width_point80_sp xdblock_sp">
                            <div className="contract_card_subcontents_sp jfc_ct">{ctPeriodTo}</div>
                          </div>
                        </div>
                      </div>
                      {/* 한줄 라인 끝 */}
                    </div>


                  );
                }}
              />
            </Grid>
          </div>
          {/* 경계선 */}
          <div className="meta"></div>
          <div className="superadmin_product_list_resister">
            <div className="superadmin_product_list_bt_pack">
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// 엑셀 내보내기 헤더랑 셀 글자정렬설정 headerCellOptions={{textAlign: 'center'}} cellOptions={{textAlign: 'center'}}