import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface DialogFormProps {
    onClose: () => void;
    onConform: () => void;
    message: string;
    message2: string;
    message3: string;
    setTitle: string;
}

const DialogForm: React.FC<DialogFormProps> = ({ setTitle, message, message2, message3, onClose, onConform }) => {

    return (
        <div>
            <Dialog title={setTitle} onClose={onClose}>
                {message && (
                    <p style={{
                        fontFamily: 'NanumBarunGothic',
                        margin: "25px",
                        textAlign: "center",
                        overflowY: 'auto'
                    }}>
                        {message}
                    </p>
                )}
                {message2 && (
                    <p style={{
                        fontFamily: 'NanumBarunGothic',
                        margin: "25px",
                        textAlign: "center",
                        overflowY: 'auto'
                    }}>
                        {message2}
                    </p>
                )}
                {message3 && (
                    <p style={{
                        fontFamily: 'NanumBarunGothic',
                        margin: "25px",
                        textAlign: "center",
                    }}>
                        {message3}
                    </p>
                )}
                <DialogActionsBar>
                    <button className="k-button"
                        style={{ backgroundColor: '#7d92a7', color: 'white', height: '35px' }}
                        onClick={onConform}>확인</button>
                    {/* <button className="k-button"
                        style={{ backgroundColor: '#7d92a7', color: 'white', height: '35px' }}
                        onClick={onClose}>취소</button> */}
                </DialogActionsBar>
            </Dialog>
        </div>
    );
};

export default DialogForm;

// 사용법
// import DialogForm from '../../Components/Dialog/DialogForm';

// const [isDialogOpen, setDialogOpen] = useState(false);

// // 창열기
//   const handleOpenDialog = () => {
//     setDialogOpen(true);
//   };
// // 창닫기
//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };
// // 창 확인클릭시
//   const handleConformDialog = () => {
//     // 원하는 동작 수행
//     handleCloseDialog();
//   };


//  영역
//       버튼클릭 = handleOpenDialog()으로 열기
//       {isDialogOpen && (
//         <DialogForm
//           setTitle="이곳에 타이틀을 입력하세오"
//           message="이곳에 메시지를 입력하세요."
//           onClose={handleCloseDialog}
//           onConform={handleConformDialog}
//         />
//       )}

